import { DEPLOY_ENVIRONMENT, SITE_TYPE } from '~/managers/ConfigManager'
import { ConfigManager } from '~/managers/ConfigManager'
import { BlockchainNetwork, CURRENCY } from '~/types'

export const SESSION_STORAGE_VALUES = {
  hasNewAddressModalAcknowledged: 'newAddressModalAcknowledged',
  dismissXusdTooltip: 'dismissXusdTooltip',
  activationChecklistShown: 'activationChecklistShownThisSession',
} as const

export const LOCAL_STORAGE_VALUES = {
  myInfoState: 'myInfoState',
} as const

export const ETH_WITHDRAW_FEES_CAP = {
  biz: {
    fee: 30,
  },
  personal: {
    fee: 30,
  },
} as const

export const POLYGON_WITHDRAW_FEES_CAP = {
  personal: {
    fee: 1,
  },
  biz: {
    fee: 5,
  },
} as const

export const AVALANCHE_WITHDRAW_FEES_CAP = {
  personal: {
    fee: 1,
  },
  biz: {
    fee: 1,
  },
} as const

export const BNBSMART_WITHDRAW_FEES_CAP = {
  personal: {
    fee: 1,
  },
  biz: {
    fee: 1,
  },
} as const

export const TRON_WITHDRAW_FEES_CAP = {
  personal: {
    fee: 2,
  },
  biz: {
    fee: 2,
  },
} as const

export const coolingDownThresholdInMinutes = 720

export const SUPPORT_LINKS = {
  NEW_REQUEST: 'https://support.straitsx.com/hc/en-us/requests/new',
  KILLSWITCH_SUPPORT:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40530704848537-Kill-Switch',
  TRANSFER_IN_STABLECOIN: "https://support.straitsx.com/hc/en-us/articles/44522784048921-Transfer-In-XSGD-and-XUSD-Stablecoins",
  TRANSFERRING_XSGD:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297218075929-Transferring-XSGD-In',
  TRANSFER_IN_FIAT:
    'https://support.straitsx.com/hc/en-us/articles/44512738449049-Transfer-In-Fiat-SGD-and-USD',
  LEARN_MORE_XSGD: 'https://www.straitsx.com/xsgd',
  LEARN_MORE_XUSD: 'https://www.straitsx.com/xusd',
  LEARN_MORE_INTERMEDIARY_SWIFT_CODE:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297192126233-Adding-a-Routing-Code-or-Intermediary-SWIFT-BIC-Code-for-International-USD-Withdrawals',
  LEARN_MORE_ROUTING_CODE:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297192126233-Adding-a-Routing-Code-or-Intermediary-SWIFT-BIC-Code-for-International-USD-Withdrawals',
  TRANSFER_OUT_STABLECOIN: "https://support.straitsx.com/hc/en-us/articles/44523263035545-Transfer-Out-XSGD-and-XUSD-Stablecoins",
  TRANSFER_OUT_XSGD:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297255127961-Transferring-XSGD-Out',
  TRANSFER_OUT_FIAT:
    'https://support.straitsx.com/hc/en-us/articles/44514171071001-Transfer-Out-Fiat-SGD-and-USD',
  TRANSFERRING_USDT:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297175025561-Transferring-In-USDT',
  TRANSFERRING_USDC:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297139089817-Transferring-USDC-In',
  WHITELISTING_BANK_ACCOUNT:
    'https://support.straitsx.com/hc/en-us/articles/44601609029785-SGD-and-USD-Bank-Account-Verification',
  WHITELISTING_REQUEST:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297221659417-How-do-I-submit-a-whitelisting-request-for-my-personal-account',
  SETUP_2FA_LINK:
    'https://support.straitsx.com/hc/en-us/articles/44510803501721-How-to-set-up-Two-Factor-Authentication-2FA',  
  UPDATE_2FA_DEVICE:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297218450457-How-to-update-2-Factor-Authentication-2FA-device-or-app',
  WHAT_IS_XSGD: 'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297194348441-What-is-XSGD',
  WHAT_IS_XIDR: 'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297111549721-What-is-XIDR',
  TERMS_OF_SERVICE: 'https://www.straitsx.com/sg/terms-of-service-new',
  STRAITSX_WHAT_CAN_I_DO:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297184166297-What-can-I-do-with-my-StraitsX-Personal-Account',
  HOW_TO_VERIFY_MY_ACCOUNT:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297111426585-Address-verification-with-browser-wallets',
  DIFFERENCE_CUSTODIAL_NON_CUSTODIAL:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297306135705-What-is-the-difference-between-custodial-and-non-custodial-private-addresses',
  VERIFY_INTERNATIONAL_BASED_ACCOUNT:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297154834585-How-to-Verify-an-International-Based-Account',
  WHAT_IS_RATE:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297133231769-What-are-the-rates-and-how-is-it-paid-out',
  HOW_MUCH_CAN_I_TRANSFER_EARN:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297118382361-How-much-funds-can-I-transfer-into-Earn',
  EARN: 'https://straitsx-ops.zendesk.com/hc/en-us/sections/40047962933913-Earn',
  HOW_MANY_VERIFIED_BANK_ACCOUNT:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297254847257-How-many-verified-bank-accounts-can-I-have',
  SUBMIT_WHITELIST_REQUEST:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297221659417-How-do-I-submit-a-whitelisting-request-for-my-personal-account',
  ADD_XSGD:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297199134745-How-do-I-add-the-XSGD-token-to-my-ETH-Polygon-Avalanche-Zilliqa-or-Avalanche-C-Chain-wallet',
  PERSONAL_TRANSACTION_FEE:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297271965977-StraitsX-Personal-Account-Transaction-Fee-Schedule',
  MINT_FEE_AND_LIMITS:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297263026969-StraitsX-Mint-fees-and-limits',
  WHAT_IS_MINT: 'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297304812185-What-is-Mint',
  INTERNAL_RISK_HELP:
    'https://straitsx-ops.zendesk.com/hc/en-us/articles/40297307461273-Why-am-I-getting-an-internal-risk-procedure-error-when-I-try-to-do-a-transaction',
} as const

export const SUPPORT_LINKS_ID = {
  HOME: 'https://support.id.straitsx.com/hc/en-us',
  NEW_REQUEST: 'https://support.id.straitsx.com/hc/en-us/requests/new',
  ACCOUNT_HOW_TO_VERIFY:
    'https://support.id.straitsx.com/hc/en-us/articles/40296908719257-How-do-I-verify-my-account',
  BANK_ACCOUNT_HOW_TO_VERIFY:
    'https://support.id.straitsx.com/hc/en-us/articles/40296944133017-How-do-I-verify-my-bank-account',
  BIZ_ACCOUNT_HOW_TO_VERIFY:
    'https://support.straitsx.com/hc/en-us/articles/40297151143961-How-to-Verify-My-Indonesian-Business-Account',
  BLOCKCHAIN_ADDRESS_TYPES:
    'https://support.id.straitsx.com/hc/en-us/articles/40296921392281-Which-one-to-choose-custodial-or-non-custodial-address',
  BLOCKCHAIN_HOW_TO_WHITELIST_ADDRESS:
    'https://support.id.straitsx.com/hc/en-us/articles/40296897111705-How-do-I-submit-a-whitelisting-request-for-my-personal-address',
  NETWORK_FEE_LEARN_MORE: 'https://support.id.straitsx.com/hc/en-us',
  IDR_TRANSFER_OUT:
    'https://support.id.straitsx.com/hc/en-us/articles/40296883737369-Transfer-Out-Indonesian-Rupiah',
  XIDR_TRANSFER_IN:
    'https://support.id.straitsx.com/hc/en-us/articles/40296895637273-Transferring-XIDR-In',
  XIDR_ADD_TO_WALLET:
    'https://support.id.straitsx.com/hc/en-us/articles/40296867098265-How-do-I-add-the-XIDR-token-to-my-ETH-or-ZIL-wallet',
} as const

export const usdBasedCurrency = [CURRENCY.USDC, CURRENCY.XUSD, CURRENCY.USDT, CURRENCY.USD]

export const BLOCKCHAIN_NETWORKS = {
  ETHEREUM: 'ethereum',
  POLYGON: 'polygon',
  ZILLIQA: 'zilliqa',
  AVALANCHE: 'avalanche',
  ARBITRUM: 'arbitrum',
  HEDERA: 'hedera',
  BNBSMART: 'bnbsmart',
  TRON: 'tron',
  RIPPLE: 'ripple',
} as const

export const BLOCKCHAIN_NETWORK_ARRAY = Object.values(BLOCKCHAIN_NETWORKS)
export const EVM_COMPATIBLE_NETWORK = 'evm-compatible-network'
export const EVM_COMPATIBLE_BLOCKCHAIN_NETWORKS: BlockchainNetwork[] = [
  'ethereum',
  'polygon',
  'avalanche',
  'arbitrum',
  'bnbsmart',
]

export const BLOCKCHAIN_NETWORKS_WITH_FEES: BlockchainNetwork[] = [
  ...EVM_COMPATIBLE_BLOCKCHAIN_NETWORKS,
  'zilliqa',
  'tron',
]

export const BLOCKCHAIN_NETWORKS_WITHOUT_FEES_ESTIMATION: BlockchainNetwork[] = ['tron']

export const posthogConfig = {
  key: 'phc_9LJpFClEPPQxTDpLSVJaVOZJknV4IDHxpzpvGEiYNLz',
  apiHost: 'https://us.i.posthog.com',
}

export const USDC_POLYGON_CONTRACT_ADDRESS = '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359'

export const XSGD_ARBITRUM_CONTRACT_ADDRESS = '0xE333e7754a2DC1E020a162Ecab019254b9DaB653'

const SSO_HOST =
  ConfigManager.getEnvironmentMode() === DEPLOY_ENVIRONMENT.PRODUCTION
    ? 'https://sso.straitsx.com/auth'
    : 'https://sso.dev.straitsx.com/auth'

const CLIENT_ID = ConfigManager.getSiteType() === SITE_TYPE.APP ? 'xfers-app' : 'xfers-biz'

export const SSO_2FA_REMOVAL_URL = `${SSO_HOST}/realms/xfers-sg/login-actions/reset-credentials?client_id=${CLIENT_ID}&reset_cred_type=remove_totp`
