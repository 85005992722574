export enum PATHS {
  TRANSACTIONS = '/transactions',
  SWAP_TRANSACTIONS = '/transactions?type=swap',
  OTC_TRANSACTIONS = '/transactions?type=otc',
  ACTION_NEEDED_TRANSACTIONS = '/transactions?type=funding&subType=action_needed',
  TRANSACTION_DETAILS = '/transactions/:transactionId',
  TRANSFER_IN = '/transfer_in',
  TRANSFER_OUT = '/transfer_out',
  MANAGE_ACCOUNT = '/manage_account',
  VERIFICATION = '/verification',
  TWO_FA_SETTING = '/two_fa_setting',
  VERIFICATION_SINGPASS = '/verification/singpass',
  VERIFICATION_INTERNATIONAL = '/verification/international',
  CLAIM_KYC = '/claim_kyc',
  VERIFICATION_MY_INFO_CALLBACK = '/callback/:myInfoParams',
  INTERNAL_TOOLS = '/internal_tools',
  ERROR = '/error/:code',
  ERROR_403 = '/error/403',
  LANDING_PAGE = '/',
  MINT = '/mint',
  MINT_SETUP = '/mint/setup',
  EARN = '/earn',
  EARN_TRANSFER_REQUEST_SUBMITTED = '/earn/transfer_request_submitted',
  EARN_TRANSACTION_DETAILS = '/earn/transactions/:transactionId',
  LOCKED = '/locked',
  PERIODIC_REVIEW = '/periodic-review',
}
