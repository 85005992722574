import React from 'react'
import styled from '@emotion/styled'

import { Button, straitsXTheme } from '@xfers/design-system'

import { SUPPORT_LINKS } from '~/common/constants'

interface ErrorPageProps {
  subtitle: string
  errorMessage?: string
}

export default function ErrorPage({ subtitle, errorMessage }: ErrorPageProps) {
  const defaultErrorMessage = () => {
    return (
      <Body>
        Don’t worry, it’s on us! Please try again later or refresh the page. <br /> If you need
        immediate help, please{' '}
        <LinkText href={SUPPORT_LINKS.NEW_REQUEST} target="_blank" rel="noopener noreferrer">
          let us know
        </LinkText>
        .{' '}
      </Body>
    )
  }

  return (
    <Content>
      <Title>OOPS!</Title>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20 }}>
        <div>
          <Header>{subtitle}</Header>
          <Body>{errorMessage || defaultErrorMessage()}</Body>
        </div>
        <Button type="primary" onClick={() => window.location.reload()}>
          Refresh Page
        </Button>
      </div>
    </Content>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`

const Title = styled.p`
  font-weight: 800;
  color: ${straitsXTheme.neutral.bluish};
  font-size: 100px;
`

const LinkText = styled.a`
  color: ${straitsXTheme.brand.primary.default};
`

const Header = styled.p`
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  color: ${straitsXTheme.text.default};
  text-align: center;
`

const Body = styled.div`
  font-family: 'Hind Siliguri';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: ${straitsXTheme.text.default};
`
