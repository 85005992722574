import React, { useState, useContext } from 'react'
import styled from '@emotion/styled'
import { useModal, BadgeIcon, Button, Modal, Select, H3, P1, Input } from '@xfers/design-system'
import { BankFilled } from '@xfers/design-system/es/icons'
import { AddBankFlowContext } from '~/components/AddUSDCBankFlow'
import { BankAccount, BillingAddress } from '~/components/AddUSDCBankFlow/types'
import {
  setBillingAddress,
  addBankAccountHandler,
  editBankAccountHandler,
} from '~/components/AddUSDCBankFlow/actions'
import { SelectTitle, countries } from './shared'
import { CountryOptions } from '~/helpers/countryList'

type Props = {
  goPrev: () => void
  goNext: (success: boolean, bankAccount?: BankAccount) => void
}

export default function AddBillingAddress({ goPrev, goNext }: Props) {
  const { resetModal } = useModal()
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AddBankFlowContext)
  const { bankAccount, billingAddress, accountHolderName, editMode } = state

  const formatkey = (key: string) =>
    key[0].toUpperCase() + key.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')

  const columnErrorArray = Object.entries(billingAddress)
    .map(([key, value]) => {
      if (key === 'line1') {
        if (!value) return [key, 'Address Line 1 is required']
        if (value.length > 35) return [key, `${formatkey(key)} must less than 35 letters`]
        if (!value.isSwiftCharSet())
          return [
            key,
            `${formatkey(
              key
            )} must consist of alpha-numeric with space characters or the characters in ["/", "-", "?", ":", "(", ")", ".", ",", "+"]`,
          ]
      }
      if (key === 'line2') {
        if (!value) return []
        if (value.length > 35) return [key, `${formatkey(key)} must less than 35 letters`]
        if (!value.isSwiftCharSet())
          return [
            key,
            `${formatkey(
              key
            )} must consist of alpha-numeric with space characters or the characters in ["/", "-", "?", ":", "(", ")", ".", ",", "+"]`,
          ]
      }
      if (key === 'line3') {
        if (!value) return []
        if (value.length > 35) return [key, `${formatkey(key)} must less than 35 letters`]
        if (!value.isSwiftCharSet())
          return [
            key,
            `${formatkey(
              key
            )} must consist of alpha-numeric with space characters or the characters in ["/", "-", "?", ":", "(", ")", ".", ",", "+"]`,
          ]
      }
      if (key === 'country') {
        if (!value) return [key, 'Country is required']
      }
      return []
    })
    .filter(item => item.length > 0)

  const columnErrorMap: BillingAddress = Object.fromEntries(columnErrorArray)

  const onFieldChange = (fieldValue: string, fieldName: string) => {
    dispatch(setBillingAddress({ ...billingAddress, [fieldName]: fieldValue }))
  }

  const onSelectCountryChange = (value: string | string[]) => onFieldChange(value.toString(), 'country')

  const title = editMode ? 'Edit Bank Account' : 'Add Bank Account'

  return (
    <Modal
      onClose={() => resetModal()}
      title={title}
      icon={<BadgeIcon type="primary" icon={<BankFilled />} />}
      footer={
        <>
          <Button
            type="secondary"
            onClick={() => {
              if (goPrev) goPrev()
            }}
          >
            Back
          </Button>
          <Button
            id="Sdet-UsdcSubmitAccountDetails"
            type="primary"
            disabled={columnErrorArray.length > 0}
            loading={loading}
            style={{
              marginLeft: '10px',
            }}
            onClick={() => {
              if (columnErrorArray.length > 0) return
              setLoading(true)
              if (editMode) {
                editBankAccountHandler({ bankAccount, billingAddress, goNext })
              } else {
                addBankAccountHandler({ bankAccount, billingAddress, accountHolderName, goNext })
              }
            }}
          >
            Submit
          </Button>
        </>
      }
    >
      <H3 style={{ marginBottom: '20px' }}>Address Details</H3>

      <SelectTitle>Full Address Line 1</SelectTitle>
      <Input
        id="Sdet-UsdcBillingStreetAddress1"
        placeholder="Enter Address"
        value={billingAddress.line1}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'line1')
        }
      />
      <ErrorMessage>{columnErrorMap.line1}</ErrorMessage>

      <SelectTitle>Full Address Line 2 (Optional)</SelectTitle>
      <Input
        id="Sdet-UsdcBillingStreetAddress2"
        placeholder="Enter Address"
        value={billingAddress.line2}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'line2')
        }
      />
      <ErrorMessage>{columnErrorMap.line2}</ErrorMessage>

      <SelectTitle>Full Address Line 3 (Optional)</SelectTitle>
      <Input
        id="Sdet-UsdcBillingStreetAddress3"
        placeholder="Enter Address"
        value={billingAddress.line3}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'line3')
        }
      />
      <ErrorMessage>{columnErrorMap.line3}</ErrorMessage>

      <SelectTitle>Country</SelectTitle>
      <CountryOptions selectedCountry={billingAddress.country} onSelectCountryChange={onSelectCountryChange} />
      <ErrorMessage>{columnErrorMap.country}</ErrorMessage>
    </Modal>
  )
}

const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 20px;
`
