import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Tick } from '@xfers/design-system/es/icons'
import { useModal, BadgeIcon, Button, Modal } from '@xfers/design-system'
import { AddBankFlowContext } from '~/components/AddUSDCBankFlow'

export default function() {
  const { resetModal } = useModal()
  const { state } = useContext(AddBankFlowContext)
  const { bankAccount, billingAddress, editMode } = state

  const title = editMode ? 'Successfully Updated' : 'Successfully Added'
  const subtitle = editMode
    ? 'Your bank account has been updated.'
    : 'Your bank account has been added. You can proceed to verify your bank account.'

  return (
    <Modal
      onClose={() => resetModal()}
      title={title}
      icon={<BadgeIcon type="success" icon={<Tick />} />}
      footer={
        <>
          <Button type="secondary" onClick={() => resetModal()}>
            Close
          </Button>
        </>
      }
    >
      <Text>{subtitle}</Text>
      <InfoRow>
        <InfoLeft>Currency</InfoLeft>
        <InfoRight>USD</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Account Holder Name</InfoLeft>
        <InfoRight>{bankAccount.accountHolderName}</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Bank Name</InfoLeft>
        <InfoRight>{bankAccount.bankName}</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>SWIFT/BIC Code</InfoLeft>
        <InfoRight>{bankAccount.swiftCode}</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>IBAN / Account Number</InfoLeft>
        <InfoRight>{bankAccount.accountNumber}</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Routing Code</InfoLeft>
        <InfoRight>{bankAccount.routingCode}</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Intermediary SWIFT/BIC Code</InfoLeft>
        <InfoRight>{bankAccount.intermediarySwiftCode}</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Address Details</InfoLeft>
        <InfoRight>
          {[billingAddress.line1, billingAddress.line2, billingAddress.line3, billingAddress.country]
            .filter(Boolean)
            .map((line, index) => (
              <div key={'edit-bank-address-line' + index}>{line}</div>
            ))}
        </InfoRight>
      </InfoRow>
    </Modal>
  )
}

// TODO reuse style with other currency add bank flow
const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1b2736;
  margin-bottom: 20px;
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: strecth;
  margin-bottom: 10px;
`

const InfoLeft = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1b2736;
`

const InfoRight = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #1b2736;
`

export const BankIcon = styled.img`
  margin-right: 5px;
`
