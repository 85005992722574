export function suppressResizeObserverError() {
  const suppressError = error => {
    if (error.message.includes('ResizeObserver loop completed with undelivered notifications')) {
      const resizeObserverError = error

      // Prevent the error from appearing in the console
      error.preventDefault()

      // Optional: Log a custom message
      console.warn('Suppressed ResizeObserver error:', resizeObserverError.message)
    }
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('error', suppressError)
    window.addEventListener('unhandledrejection', suppressError)
  }

  return () => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('error', suppressError)
      window.removeEventListener('unhandledrejection', suppressError)
    }
  }
}
