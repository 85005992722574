/* eslint-disable max-lines */
import { lazy } from 'react'
import { PATHS } from './paths'
import { Route } from '~/types'
import withStraitsXBreadcrumbBar from '~/common/withStraitsXBreadcrumbBar'
import withStraitsXBreadcrumbBarAndLink from '~/common/withStraitsXBreadcrumbBarAndLink'

const LandingPage = lazy(() =>
  import(/* webpackChunkName: "LandingPage" */ '~/app/pages/LandingPage')
)

const TwoFaSetting = lazy(() =>
  import(/* webpackChunkName: "TwoFaSetting" */ '~/app/pages/TwoFaSetting')
)

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '~/app/pages/NotFound'))

const Error = lazy(() => import(/* webpackChunkName: "Error" */ '~/app/pages/Error'))

const Transactions = lazy(() =>
  import(/* webpackChunkName: "Transactions" */ '~/app/pages/Transactions')
)

const TransactionDetails = lazy(() =>
  import(/* webpackChunkName: "TransactionDetails" */ '~/app/pages/TransactionDetails')
)

const PeriodicReview = lazy(() =>
  import(/* webpackChunkName: "PeriodicReview" */ '~/app/pages/PeriodicReview')
)

const Deposit = lazy(() => import(/* webpackChunkName: "Deposit" */ '~/app/pages/Deposit'))

const MyAccount = lazy(() => import(/* webpackChunkName: "MyAccount" */ '~/app/pages/MyAccount'))

const Withdraw = lazy(() => import(/* webpackChunkName: "Withdraw" */ '~/app/pages/Withdraw'))

const Verification = lazy(() =>
  import(/* webpackChunkName: "Verification" */ '~/app/pages/NewVerification')
)

const VerificationSgFlow = lazy(() =>
  import(/* webpackChunkName: "VerificationSgFlow" */ '~/app/pages/NewVerification/SgFlow')
)

const VerificationInternationalFlow = lazy(() =>
  import(
    /* webpackChunkName: "VerificationInternationalFlow" */ '~/app/pages/NewVerification/InternationalFlow'
  )
)

const VerificationClaimKyc = lazy(() =>
  import(
    /* webpackChunkName: "VerificationClaimKyc" */ '~/app/pages/NewVerification/SgFlow/ClaimKyc'
  )
)

const VerificationMyInfoCallback = lazy(() =>
  import(/* webpackChunkName: "Verification" */ '~/app/pages/NewVerification/SgFlow/MyInfoDetails')
)

const InternalTools = lazy(() =>
  import(/* webpackChunkName: "InternalTools" */ '~/app/pages/InternalTools')
)

const Mint = lazy(() => import(/* webpackChunkName: "Mint" */ '~/app/pages/Mint'))

const MintSetup = lazy(() => import(/* webpackChunkName: "MintSetup" */ '~/app/pages/Mint/Setup'))

const LockedPage = lazy(() => import(/* webpackChunkName: "Locked" */ '~/app/pages/Locked'))

const Earn = lazy(() => import(/* webpackChunkName: "Earn" */ '~/app/pages/Earn'))

const EarnFaqsLink = lazy(() => import(/* webpackChunkName: "Earn" */ '~/app/pages/Earn/FaqsLink'))

const EarnTransferRequestSubmission = lazy(() =>
  import(/* webpackChunkName: "Earn" */ '~/app/pages/Earn/TransferRequestSubmission')
)

const EarnTransactionDetails = lazy(() =>
  import(/* webpackChunkName: "EarnTransactionDetails" */ '~/app/pages/Earn/TransactionDetails')
)

export const straitsXRoutes: Route[] = [
  {
    path: PATHS.LANDING_PAGE,
    component: LandingPage,
    exact: true,
    sideBar: 'Home',
    breadcrumbName: 'Home',
    documentTitle: { name: 'Dashboard', withSiteTypePrefix: true, withSiteTypeSuffix: false },
  },
  {
    path: PATHS.MINT,
    component: withStraitsXBreadcrumbBar(Mint),
    exact: true,
    sideBar: 'Mint',
    breadcrumbName: 'Mint',
    documentTitle: { name: 'Mint' },
  },
  {
    path: PATHS.EARN,
    component: withStraitsXBreadcrumbBarAndLink(Earn, EarnFaqsLink),
    exact: true,
    sideBar: 'Earn',
    breadcrumbName: 'Earn',
    documentTitle: { name: 'Earn' },
  },
  {
    path: PATHS.EARN_TRANSFER_REQUEST_SUBMITTED,
    component: withStraitsXBreadcrumbBar(EarnTransferRequestSubmission),
    exact: true,
    documentTitle: { name: 'Earn Transfer Submitted' },
  },
  {
    path: PATHS.EARN_TRANSACTION_DETAILS,
    component: EarnTransactionDetails,
    exact: true,
    documentTitle: { name: 'Earn Transaction' },
  },
  {
    path: PATHS.TRANSACTIONS,
    component: withStraitsXBreadcrumbBar(Transactions),
    exact: true,
    sideBar: 'Transaction History',
    breadcrumbName: 'Transaction History',
    documentTitle: { name: 'Transactions' },
  },
  {
    path: PATHS.MINT_SETUP,
    component: withStraitsXBreadcrumbBar(MintSetup),
    exact: true,
    breadcrumbName: 'Setup',
    documentTitle: { name: 'Mint Setup' },
  },
  {
    path: PATHS.TRANSACTION_DETAILS,
    component: TransactionDetails,
    exact: true,
    documentTitle: { name: 'Transactions' },
  },
  {
    path: PATHS.TWO_FA_SETTING,
    component: withStraitsXBreadcrumbBar(TwoFaSetting),
    exact: true,
    breadcrumbName: '2FA setting',
    documentTitle: { name: 'Mobile Authenticator Setup' },
  },
  {
    path: PATHS.TRANSFER_IN,
    component: withStraitsXBreadcrumbBar(Deposit),
    exact: true,
    breadcrumbName: 'Transfer In',
    documentTitle: { name: 'Transfer In' },
  },
  {
    path: PATHS.TRANSFER_OUT,
    component: withStraitsXBreadcrumbBar(Withdraw),
    exact: true,
    breadcrumbName: 'Transfer Out',
    documentTitle: { name: 'Transfer Out' },
  },
  {
    path: PATHS.PERIODIC_REVIEW,
    component: withStraitsXBreadcrumbBar(PeriodicReview),
    exact: true,
    breadcrumbName: 'Confirm Your Detail',
    documentTitle: { name: 'Periodic Review' },
  },
  {
    path: PATHS.MANAGE_ACCOUNT,
    component: withStraitsXBreadcrumbBar(MyAccount),
    exact: true,
    breadcrumbName: 'My Account',
    documentTitle: { name: 'Manage Account' },
  },
  {
    path: PATHS.VERIFICATION,
    component: withStraitsXBreadcrumbBar(Verification),
    exact: true,
    breadcrumbName: 'Verification',
    documentTitle: { name: 'Complete Verification' },
  },
  {
    path: PATHS.VERIFICATION_SINGPASS,
    component: withStraitsXBreadcrumbBar(VerificationSgFlow),
    exact: true,
    breadcrumbName: 'Singpass',
    documentTitle: { name: 'Complete Verification' },
  },
  {
    path: PATHS.VERIFICATION_INTERNATIONAL,
    component: withStraitsXBreadcrumbBar(VerificationInternationalFlow),
    exact: true,
    breadcrumbName: 'International',
    documentTitle: { name: 'Complete Verification' },
  },
  {
    path: PATHS.CLAIM_KYC,
    component: withStraitsXBreadcrumbBar(VerificationClaimKyc),
    exact: true,
    breadcrumbName: 'Verification',
    documentTitle: { name: 'Verification' },
  },
  {
    path: PATHS.VERIFICATION_MY_INFO_CALLBACK,
    component: withStraitsXBreadcrumbBar(VerificationMyInfoCallback),
    breadcrumbName: 'Verification',
    exact: true,
    documentTitle: { name: 'Singpass Callback' },
  },
  {
    path: PATHS.INTERNAL_TOOLS,
    component: withStraitsXBreadcrumbBar(InternalTools),
    breadcrumbName: 'Internal Tools',
    documentTitle: { name: 'Internal Tools' },
  },
  {
    path: PATHS.LOCKED,
    component: LockedPage,
    exact: true,
    documentTitle: { name: 'Locked' },
  },
  {
    path: PATHS.ERROR,
    component: Error,
    documentTitle: { name: 'Error' },
  },
  {
    component: NotFound,
    documentTitle: { name: 'Error' },
  },
]
