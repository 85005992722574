export enum SCREENS {
  STATUS = 'STATUS',
  ABOUT = 'ABOUT',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  DECLARATIONS = 'DECLARATIONS',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  PASSPORT = 'PASSPORT',
  ID_FRONT_AND_BACK = 'ID_FRONT_AND_BACK',
  SELFIE = 'SELFIE',
  MY_INFO = 'MY_INFO',
  MY_INFO_DETAILS = 'MY_INFO_DETAILS',
  NRIC_CHECK = 'NRIC_CHECK',
  CLAIM_KYC_ERROR = 'CLAIM_KYC_ERROR',
}

export enum VERIFICATION_ACTIONS {
  SET_IMMIGRATION_STATUS = 'VERIFICATION_ACTIONS.SET_IMMIGRATION_STATUS',
  SET_RESIDENCY_STATUS = 'VERIFICATION_ACTIONS.SET_RESIDENCY_STATUS',
  SET_FLOW = 'VERIFICATION_ACTIONS.SET_FLOW',
  SET_USER_DETAILS = 'VERIFICATION_ACTIONS.SET_USER_DETAILS',
  UPDATE_USER = 'VERIFICATION_ACTIONS.UPDATE_USER',
  SET_IS_ID_TYPE_SG_PR = 'VERIFICATION_ACTIONS.SET_IS_ID_TYPE_SG_PR',
  SET_SCREEN = 'VERIFICATION_ACTIONS.SET_SCREEN',
  SET_MULTIPLE_USER_DETAILS = 'VERIFICATION_ACTIONS.SET_MULTIPLE_USER_DETAILS',
  SET_KYC_REJECTED_REASON = 'VERIFICATION_ACTIONS.SET_KYC_REJECTED_REASON',
  SET_RETRIEVED_DETAILS = 'VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS',
  SET_ERROR = 'VERIFICATION_ACTIONS.SET_ERROR',
  SET_MY_INFO_ONLY_DETAILS = 'VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS',
  SET_FORM_OPTIONS = 'SET_FORM_OPTIONS',
}

export enum RESIDENCY_STATUS {
  LIVING_IN_SG = 'LIVING_IN_SG',
  NOT_LIVING_IN_SG = 'NOT_LIVING_IN_SG',
}

export type RetrievedDetails = {
  userId: string
  verificationStatus:
    | 'permanently_rejected'
    | 'verified'
    | 'pending'
    | 'rejected'
    | 'initial'
    | 'inactive'
  kycRejectedReason: string | { [key: string]: string }
  isAccountLocked: boolean

  isMyInfoFlow: boolean

  fullName: string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfBirth: any
  countryOfBirth: string | undefined
  nationality: string | undefined
  gender: string | undefined
  addressLine1: string | undefined
  addressLine2: string | undefined
  country: string | undefined
  city: string | undefined
  postalCode: string | undefined
  idNumber: string | undefined
  nricType: 'nric' | 'fin' | 'passport' | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfIssue: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expiryDate: any

  proofOfAddressUrl: string
  proofOfAddressUploaded: boolean

  selfieUrl: string
  selfieUploaded: boolean

  idFrontUrl: string
  idFrontUploaded: boolean

  idBackUrl: string
  idBackUploaded: boolean

  occupation: string
  employmentSector: string
  employer: string
  annualIncomeRange: string
  expectedTransactionAmount: string
  expectedTotalTransaction: string
  expectedTransactionFrequency: string
  sourceOfFunds: string
}

export type ImmigrationStatus = 'sg_pr' | 'not_sg_pr' | undefined
export type ResidencyStatus = RESIDENCY_STATUS | undefined
export type VerificationFlows = 'my_info' | 'manual' | undefined
export type UserDetails = {
  fullName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfBirth?: any
  countryOfBirth?: string
  nationality?: string
  gender?: string
  addressLine1?: string
  addressLine2?: string
  country?: string
  city?: string
  postalCode?: string
  idNumber?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfIssue?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expiryDate?: any
  occupation?: string
  employmentSector?: string
  employer?: string
  passType?: string
  passStatus?: string
  passExpiryDate?: string
  annualIncomeRange?: string
  expectedTransactionAmount?: string
  expectedTotalTransaction?: string
  expectedTransactionFrequency?: string
  sourceOfFunds?: string
}

export type MyInfoOnlyDetails = {
  race?: string
  residentialStatus?: string
  aliasName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  passStatusLastUpdated?: any
  error?: string
}

export type FormOptions = {
  countries: Array<{ label: string; value: string }>
  nationalities: Array<string>
  races: Array<{ label: string; value: string }>
  genders: Array<{ label: string; value: string }>
  occupations: Array<{ label: string; value: string }>
  employmentSectors: Array<string>
  annualIncomes: Array<string>
  expectedTransactionAmounts: Array<string>
  expectedTotalTransactions: Array<string>
  expectedTransactionFrequencies: Array<string>
  sourcesOfFunds: Array<string>
}

type SetImmigrationStatus = {
  type: typeof VERIFICATION_ACTIONS.SET_IMMIGRATION_STATUS
  status: ImmigrationStatus
}

type SetResidencyStatus = {
  type: typeof VERIFICATION_ACTIONS.SET_RESIDENCY_STATUS
  status: ResidencyStatus
}

type SetFlow = {
  type: typeof VERIFICATION_ACTIONS.SET_FLOW
  flow: VerificationFlows
}

type SetUserDetails = {
  type: typeof VERIFICATION_ACTIONS.SET_USER_DETAILS
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type UpdateUser = {
  type: typeof VERIFICATION_ACTIONS.UPDATE_USER
  error: string
}

type SetIsIdTypeSgPr = {
  type: typeof VERIFICATION_ACTIONS.SET_IS_ID_TYPE_SG_PR
  isIdTypeSgPr: boolean
}

type SetScreen = {
  type: typeof VERIFICATION_ACTIONS.SET_SCREEN
  screen: SCREENS
}

type SetMultipleUserDetails = {
  type: typeof VERIFICATION_ACTIONS.SET_MULTIPLE_USER_DETAILS
  userDetails: UserDetails
}

type SetKycRejectedReason = {
  type: typeof VERIFICATION_ACTIONS.SET_KYC_REJECTED_REASON
  kycRejectedReason: string
}

type SetRetrievedDetails = {
  type: typeof VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS
  retrievedDetails: RetrievedDetails
}

type SetError = {
  type: typeof VERIFICATION_ACTIONS.SET_ERROR
  error: string
}

type SetMyInfoOnlyDetails = {
  type: typeof VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS
  myInfoOnlyDetails: MyInfoOnlyDetails
}

type SetFormOptions = {
  type: typeof VERIFICATION_ACTIONS.SET_FORM_OPTIONS
  formOptions: FormOptions
}

export type ActionTypes =
  | SetImmigrationStatus
  | SetResidencyStatus
  | SetFlow
  | SetUserDetails
  | UpdateUser
  | SetIsIdTypeSgPr
  | SetScreen
  | SetMultipleUserDetails
  | SetKycRejectedReason
  | SetRetrievedDetails
  | SetError
  | SetMyInfoOnlyDetails
  | SetFormOptions
