import { BANK_ACCOUNT_ACTIONS } from './const'
import { ActionTypes, Bank } from './types'
import { Dispatch } from '~/biz/store/types'
import { del, ENDPOINTS, get } from '~/api'
import { keysToCamel } from '~/helpers/helpers'
import { STABLECOIN, BankAccount } from '~/types'
import { ACCEPTED_CURRENCY } from '~/biz/types'

export const setBankAccountList = (
  bankAccountList: BankAccount[],
  stablecoin: ACCEPTED_CURRENCY
) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: BANK_ACCOUNT_ACTIONS.SET_BANK_ACCOUNT_LIST,
    stablecoin,
    bankAccountList,
  })
}

export const deleteBankAccount = (bankAccId: number, onSuccess?: () => void) => async (
  dispatch: Dispatch<ActionTypes>
) => {
  // TODO: handle resp?
  await del(ENDPOINTS.API_V3_USER_BANK_ACCOUNT, {}, `/${bankAccId}`)

  dispatch({
    type: BANK_ACCOUNT_ACTIONS.DELETE_BANK_ACCOUNTS,
    bankAccountId: bankAccId,
  })
  if (onSuccess) {
    onSuccess()
  }
}

export const setBankList = (bankList: Bank[], stablecoin: STABLECOIN) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: BANK_ACCOUNT_ACTIONS.SET_BANK_LIST,
    stablecoin,
    bankList,
  })
}

export const fetchBankList = () => async (
  dispatch: Dispatch<ActionTypes>,
  stablecoin: STABLECOIN,
  onSuccess?: () => void
) => {
  const bankList = await get(ENDPOINTS.API_V3_BANKS)
  if (bankList) {
    dispatch(setBankList(keysToCamel(bankList), stablecoin))
  }

  if (onSuccess) {
    onSuccess()
  }
}
