/* eslint-disable max-lines */
import {
  FinancialInstitutionName,
  TransactionStatus,
  TransactionType,
  Moment,
  WALLET_TYPES,
  BusinessVerificationStatus,
  PersonalVerificationStatus,
} from '~/types'
import { BankAccount } from '~/xidrBiz/store/userInfo/types'

export type BusinessAccount = {
  generalWallet: {
    availableBalance: string
  } | null
  digitalGoodsWallet: {
    availableBalance: string
  } | null
  annualSpendingLimit: string | null
  remainingAnnualSpending: string | null
  withdrawDailyRemaining: string | null
  limitationIncreaseAmount: number | null
  limitationIncreaseDate: Moment | null
}

export type TemporaryHoldingBalance = {
  totalBalance: string
}

export type Transaction = {
  amount: {
    prefix: string
    postfix: string
  }
  net_amount: {
    prefix: string
    postfix: string
  }
  created_at: string
  transaction_status: TransactionStatus
  transaction_type: TransactionType
  transaction_id: string
  description: {
    data: string
    direction: 'from' | 'to'
    type: TransactionType
  }
}

export type DepositRequest = {
  amount: string
  bank_account: {
    account_no: string
    bank_name: string
    bank_abbrev: string
  }
  created_at: string
  expired_at: string
}

export type DashboardBanner = {
  title: string
  tag: string
  content: string
}

export type BankIconDetails = {
  name: string
  abbreviation: string
  bank_code: string
  img_src: string
  swift_code: string
}

export type BlockchainAddress = {
  address: string
  approved: boolean | null
  blockchain: Blockchain
  financialInstitutionId: number
  nickname: string
  remainingOfWithdrawalLimit: number
}

export type FinancialInstitution = {
  id: number
  name: FinancialInstitutionName
  enabled: boolean
}

export type StablecoinFee = {
  amount: number | undefined
  identifier: string | undefined
  timestamp: string | undefined
}

export type TransferMethod = 'stablecoin' | 'bank' | 'retail'
export type RetailStore = 'indomaret' | 'alfamart' | null
export type BankTransferNetwork = string | null
export type Blockchain = 'zilliqa' | 'ethereum' | 'hedera' | 'polygon'

export type WithdrawWalletIdType =
  | WALLET_TYPES.DIGITAL_GOODS
  | WALLET_TYPES.TEMPORARY_HOLDING_BALANCE
  | undefined

export enum XIDR_LANDING_PAGE_ACTIONS {
  SET_USER_INFO = 'XIDR_LANDING_PAGE_ACTIONS.SET_USER_INFO',
  SET_BUSINESS_ACCOUNT = 'XIDR_LANDING_PAGE_ACTIONS.SET_BUSINESS_ACCOUNT',
  SET_TEMP_HOLDING_BALANCE = 'XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE',
  SET_LATEST_BUSINESS_TRANSACTIONS = 'XIDR_LANDING_PAGE_ACTIONS.SET_LATEST_BUSINESS_TRANSACTIONS',
  SET_LATEST_BUSINESS_DEPOSIT_REQUESTS = 'XIDR_LANDING_PAGE_ACTIONS.SET_LATEST_BUSINESS_DEPOSIT_REQUESTS',
  SET_VERIFICATION_STATUSES = 'XIDR_LANDING_PAGE_ACTIONS.SET_VERIFICATION_STATUSES',
  SET_IS_ZRC2_DEPOSIT_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED',
  SET_IS_ERC20_DEPOSIT_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED',
  SET_IS_MATIC_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_ENABLED',
  SET_IS_MATIC_DEPOSIT_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED',
  SET_IS_MATIC_WITHDRAWAL_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED',
  SET_IS_ZRC2_WITHDRAWAL_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED',
  SET_IS_ERC20_WITHDRAWAL_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED',
  SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED',
  SET_IS_ZRC2_NOTICE_BANNER_VISIBLE = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_NOTICE_BANNER_VISIBLE',
  SET_IS_PAYMENT_METHOD_QRIS_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_QRIS_ENABLED',
  SET_IS_DEVELOPER_TOOL_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_DEVELOPER_TOOL_ENABLED',
  SET_DASHBOARD_BANNERS = 'ROUTE_ACTIONS.SET_DASHBOARD_BANNERS',
  SET_BANK_ACCOUNTS = 'XIDR_LANDING_PAGE_ACTIONS.SET_BANK_ACCOUNTS',
  GET_BANK_ICONS = 'XIDR_LANDING_PAGE_ACTIONS.GET_BANK_ICONS',
  SET_TAB = 'XIDR_LANDING_PAGE_ACTIONS.SET_TAB',
  SET_TRANSFER_METHOD = 'XIDR_LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD',
  SET_BANK_TRANSFER_NETWORK = 'XIDR_LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK',
  SET_SELECTED_BLOCKCHAIN = 'XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN',
  SET_SELECTED_BANK_ACCOUNT = 'XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT',
  SET_SELECTED_BLOCKCHAIN_ADDRESS = 'XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS',
  SET_BLOCKCHAIN_ADDRESSES = 'XIDR_LANDING_PAGE_ACTIONS.SET_BLOCKCHAIN_ADDRESSES',
  SET_WITHDRAW_AMOUNT = 'XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT',
  SET_IS_STABLECOIN_FEE_LOADING = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING',
  SET_STABLECOIN_FEE = 'XIDR_LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE',
  SET_CONFIRM_MODAL_OPEN = 'XIDR_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN',
  SET_2_FA_MODAL_OPEN = 'XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN',
  CREATE_WITHDRAWAL_REQUEST = 'XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST',
  SET_TEMP_HOLDING_BALANCE_MODAL_OPEN = 'XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE_MODAL_OPEN',
  SET_WITHDRAW_WALLET_ID = 'XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID',
  SET_VISITED_GETTING_STARTED = 'XIDR_LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED',
  SET_IS_XIDR_WITHDRAWAL_FEE_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_XIDR_WITHDRAWAL_FEE_ENABLED',
  SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS = 'XIDR_LANDING_PAGE_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS',
  SET_HAS_FETCHING_FEE_ERROR = 'XIDR_LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR',
  SET_IS_VIRTUAL_ACCOUNT_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_VIRTUAL_ACCOUNT_ENABLED',
  SET_IS_INDOMARET_DEPOSIT_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_INDOMARET_DEPOSIT_ENABLED',
  SET_IS_ALFAMART_DEPOSIT_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_ALFAMART_DEPOSIT_ENABLED',
  SET_IS_PAYMENT_METHOD_RETAIL_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_RETAIL_ENABLED',
  SET_RETAIL_STORE = 'XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_STORE',
  SET_RETAIL_DEPOSIT_AMOUNT = 'XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_DEPOSIT_AMOUNT',
  SET_IS_RENEW_ADDRESS_ACKNOWLEDGED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED',
  SET_IS_CLEANUP_FEATURES_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_CLEANUP_FEATURES_ENABLED',
  SET_IS_ID_TRANSFER_OUT_ENABLED = 'XIDR_LANDING_PAGE_ACTIONS.SET_IS_ID_TRANSFER_OUT_ENABLED',
}

type SetUserInfo = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_USER_INFO
  name: string
  kycVerified: boolean
  kycVerifiedDate: Moment | null
  hasSuccessfulFiatDeposit: boolean
}

type SetIsZrc2DepositEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED
  isZrc2DepositEnabled: boolean
}

type SetIsErc20DepositEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED
  isErc20DepositEnabled: boolean
}

type SetIsMaticEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_ENABLED
  isMaticEnabled: boolean
}

type SetIsMaticDepositEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED
  isMaticDepositEnabled: boolean
}

type SetIsMaticWithdrawalEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED
  isMaticWithdrawalEnabled: boolean
}

type SetIsZrc2WithdrawalEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED
  isZrc2WithdrawalEnabled: boolean
}
type SetIsErc20WithdrawalEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED
  isErc20WithdrawalEnabled: boolean
}

type SetIsPaymentMethodQrisEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_QRIS_ENABLED
  isPaymentMethodQrisEnabled: boolean
}

type SetIsVirtualAccountEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_VIRTUAL_ACCOUNT_ENABLED
  isVirtualAccountEnabled: boolean
}

type SetBusinessAccount = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_BUSINESS_ACCOUNT
  businessAccount: BusinessAccount
}

type SetTempHoldingBalance = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE
  temporaryHoldingBalance: TemporaryHoldingBalance | null
}

type SetLatestBusinessTransactions = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_LATEST_BUSINESS_TRANSACTIONS
  latestBusinessTransactions: Transaction[]
}

type SetLatestBusinessDepositRequests = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_LATEST_BUSINESS_DEPOSIT_REQUESTS
  latestBusinessDepositRequests: DepositRequest[]
}

type SetVerificationStatuses = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_VERIFICATION_STATUSES
  personalVerificationStatus: PersonalVerificationStatus
  businessVerificationStatus: BusinessVerificationStatus
  has2FaEnabled: boolean
}

type SetIsZrc2NoticeBannerEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_NOTICE_BANNER_VISIBLE
  isZrc2NoticeBannerVisible: boolean
}

type SetIsDashboardBannerManagementEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED
  isDashboardBannerManagementEnabled: boolean
}

type SetDashboardBanners = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_DASHBOARD_BANNERS
  dashboardBanners: DashboardBanner[]
}

type SetBankAccounts = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_BANK_ACCOUNTS
  bankAccounts: BankAccount[]
}

type GetBankIcons = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.GET_BANK_ICONS
  bankIcons: BankIconDetails[]
}

type SetTab = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_TAB
  tab: string
}

type SetTransferMethod = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD
  transferMethod: TransferMethod | null
}

type SetBankTransferNetwork = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK
  bankTransferNetwork: BankTransferNetwork | null
}

type SetSelectedBlockchain = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN
  selectedBlockchain: Blockchain | null
}

type SetSelectedBankAccount = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT
  selectedBankAccount: BankAccount | null
}

type SetSelectedBlockchainAddress = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS
  selectedBlockchainAddress: BlockchainAddress | null
}

type SetBlockchainAddresses = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_BLOCKCHAIN_ADDRESSES
  blockchainAddresses: BlockchainAddress[]
  fiList: Array<FinancialInstitution>
  addressQuota: number
}

type SetWithdrawAmount = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT
  amount: string
  withdrawAmountError: string
}

type SetWithdrawWalletId = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID
  withdrawWalletId: WithdrawWalletIdType
}

type SetIsStablecoinFeeLoading = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING
  isStablecoinFeeLoading: boolean
}

type SetStablecoinFee = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE
  amount: number
  identifier: string
  timestamp: string
}

type SetConfirmModalOpen = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN
  open: boolean
}

type Set2FaModalOpen = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN
  open: boolean
}

type CreateWithdrawalRequest = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST
  error: string
}

type SetTempHoldingBalanceModalOpen = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE_MODAL_OPEN
  open: boolean
}

type SetVisitedGettingStarted = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED
  value: boolean
}

type SetIsXIDRWithdrawalFeeEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_XIDR_WITHDRAWAL_FEE_ENABLED
  value: boolean
}

type SetNewlyCreatedBlockchainAddress = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS
  blockchainAddress: BlockchainAddress
}

type SetHasFetchingFeeError = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR
  hasError: boolean
}

type SetIsIndomaretDepositEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_INDOMARET_DEPOSIT_ENABLED
  isIndomaretDepositEnabled: boolean
}

type SetIsAlfamartDepositEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_ALFAMART_DEPOSIT_ENABLED
  isAlfamartDepositEnabled: boolean
}

type SetIsPaymentMethodRetailEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_RETAIL_ENABLED
  isPaymentMethodRetailEnabled: boolean
}

type SetRetailStore = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_STORE
  selectedRetailStore: RetailStore | null
}

type SetRetailDepositAmount = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_DEPOSIT_AMOUNT
  amount: string
  retailDepositAmountError: string
}

type SetIsRenewAddressAcknowledged = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED
  isRenewAddressAcknowledged: boolean
}

type SetIsXidrCleanupFeaturesEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_CLEANUP_FEATURES_ENABLED
  isXidrCleanupFeaturesEnabled: boolean
}

type SetIsIdTransferOutEnabled = {
  type: typeof XIDR_LANDING_PAGE_ACTIONS.SET_IS_ID_TRANSFER_OUT_ENABLED
  isIdTransferOutEnabled: boolean
}

export type ActionTypes =
  | SetUserInfo
  | SetBusinessAccount
  | SetTempHoldingBalance
  | SetLatestBusinessTransactions
  | SetLatestBusinessDepositRequests
  | SetVerificationStatuses
  | SetIsZrc2DepositEnabled
  | SetIsErc20DepositEnabled
  | SetIsMaticEnabled
  | SetIsMaticDepositEnabled
  | SetIsMaticWithdrawalEnabled
  | SetIsZrc2WithdrawalEnabled
  | SetIsErc20WithdrawalEnabled
  | SetIsZrc2NoticeBannerEnabled
  | SetIsDashboardBannerManagementEnabled
  | SetIsPaymentMethodQrisEnabled
  | SetIsVirtualAccountEnabled
  | SetDashboardBanners
  | SetBankAccounts
  | GetBankIcons
  | SetTab
  | SetTransferMethod
  | SetBankTransferNetwork
  | SetSelectedBlockchain
  | SetSelectedBankAccount
  | SetSelectedBlockchainAddress
  | SetBlockchainAddresses
  | SetWithdrawAmount
  | SetWithdrawWalletId
  | SetIsStablecoinFeeLoading
  | SetStablecoinFee
  | SetConfirmModalOpen
  | Set2FaModalOpen
  | CreateWithdrawalRequest
  | SetTempHoldingBalanceModalOpen
  | SetVisitedGettingStarted
  | SetIsXIDRWithdrawalFeeEnabled
  | SetNewlyCreatedBlockchainAddress
  | SetHasFetchingFeeError
  | SetIsIndomaretDepositEnabled
  | SetIsAlfamartDepositEnabled
  | SetIsPaymentMethodRetailEnabled
  | SetRetailStore
  | SetRetailDepositAmount
  | SetIsRenewAddressAcknowledged
  | SetIsXidrCleanupFeaturesEnabled
  | SetIsIdTransferOutEnabled
