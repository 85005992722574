import React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { DESKTOP_BREAKPOINT } from '@xfers/design-system'
import StraitsXNavigation from '~/components/StraitsXNavigation'

const bodyElement = document.body

type Props = {
  isOpen: boolean
  onClose: () => void
  displayGetStartedTab: boolean
  displayMintTab: boolean
  displayDevToolTab: boolean
  isSandbox: boolean
  isBusinessAccount: boolean
  displayEarnTab: boolean
  displayAccountStatementTab: boolean
  displayUserCardsTab: boolean
  onClickCallbacks?: { [k: string]: Function } | undefined
}

export default class MobileNavigation extends React.Component<Props> {
  mainElement = document.createElement('div')

  /* eslint-disable   react/static-property-placement */
  static defaultProps: { displayGetStartedTab: boolean }

  componentDidMount() {
    bodyElement.appendChild(this.mainElement)
  }

  componentWillUnmount() {
    bodyElement.removeChild(this.mainElement)
  }

  render() {
    if (!this.props.isOpen) return null

    return ReactDOM.createPortal(
      <Container
        onClick={() => {
          this.props.onClose()
        }}
      >
        <Global
          styles={css`
            body {
              overflow: hidden;
            }
          `}
        />
        <NavigationContainer
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <StraitsXNavigation
            displayGetStartedTab={this.props.displayGetStartedTab}
            displayMintTab={this.props.displayMintTab}
            displayEarnTab={this.props.displayEarnTab}
            displayAccountStatementTab={this.props.displayAccountStatementTab}
            displayDevToolTab={this.props.displayDevToolTab}
            displayUserCardsTab={this.props.displayUserCardsTab}
            isSandbox={this.props.isSandbox}
            isBusinessAccount={this.props.isBusinessAccount}
            onClickCallbacks={this.props.onClickCallbacks}
          />
        </NavigationContainer>
      </Container>,
      this.mainElement
    )
  }
}

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 9;

  ${DESKTOP_BREAKPOINT} {
    display: none;
  }
`
const NavigationContainer = styled.div`
  background: white;
  width: 256px;
`
