import { Dispatch } from '~/biz/store/types'
import { ActionTypes, Credentials, FeatureMatrix, Notification, ROUTE_ACTIONS } from './types'
import { createGaPageView, setGaAttribute } from '~/helpers/gaHelper'
import { PATHS } from '~/biz/routes/paths'
import { get, ENDPOINTS, post } from '~/api'
import { ConfigManager } from '~/managers/ConfigManager'
import { USER_ROLES } from '~/types'
import { coolingDownThresholdInMinutes } from '~/common/constants'

export const redirect = (to: PATHS) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_REDIRECT,
    to,
  })
}

export const resetRedirect = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_REDIRECT,
    to: null,
  })
}

export const init = () => (dispatch: Dispatch<ActionTypes>) => {
  Promise.all([
    get(ENDPOINTS.API_SSO_PROFILE),
    get(ENDPOINTS.API_XFERS_FEATURE_MATRIX_PROFILE),
    get(ENDPOINTS.API_V3_USER),
    get(ENDPOINTS.API_SSO_TWO_FA),
    get(ENDPOINTS.API_SSO_CREDENTIALS),
    get(ENDPOINTS.API_SSO_ACCOUNT_STATUS),
  ]).then(
    ([ssoProfileResp, fmProfileResp, userResp, twoFaResp, ssoCredentials, ssoAccountStatus]) => {
      setGaAttribute({ userId: ssoProfileResp.kc_id }) // unsure if i should set it here
      createGaPageView(window.location.pathname + window.location.search)

      const isWithin12Hours = (utcTimeStamp: number) => {
        const currentTime = new Date().getTime()

        const twelveHoursInMillis = coolingDownThresholdInMinutes * 60 * 1000
        const twelveHoursFromTimestamp = Number(utcTimeStamp) + twelveHoursInMillis

        const remainingTimeInMillis = twelveHoursFromTimestamp - currentTime

        const isWithin12Hours = remainingTimeInMillis > 0

        return {
          isWithin12Hours,
        }
      }

      const res = isWithin12Hours(ssoProfileResp.two_fa_setup_timestamp)

      const credentialsPassword = ssoCredentials.find(
        (credentials: Credentials) => credentials.type === 'password'
      )

      const { userCredentialMetadatas } = credentialsPassword

      if (userCredentialMetadatas) {
        dispatch({
          type: ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD,
          isUserHasPassword: userCredentialMetadatas.length > 0,
        })
      }

      if (ssoAccountStatus) {
        dispatch({
          type: ROUTE_ACTIONS.SET_IS_ACCOUNT_ENABLED,
          isAccountEnabled: ssoAccountStatus.enabled,
        })
      }

      dispatch({
        type: ROUTE_ACTIONS.SET_USER_PROFILE,
        user: {
          kcId: ssoProfileResp.kc_id,
          email: ssoProfileResp.email,
          mobileNumber: ssoProfileResp.mobile_number,
          countryCode: ssoProfileResp.country_code,
          companyId: ssoProfileResp.company_id,
          authServerUrl: ssoProfileResp.auth_server_url,
          fullName: ssoProfileResp.full_name,
          twoFa: {
            twoFaSetupTime: ssoProfileResp.two_fa_setup_timestamp,
            isOnCoolingDownPeriod: res.isWithin12Hours,
            twoFaList: twoFaResp[0]?.userCredentialMetadatas || [],
          },

          isBitgoAddressAcknowledged: userResp.bitgo_acknowledged,
          isFiatWalletOnly: userResp.fiat_wallet_only,
        },
      })

      const featureMatrix = parseFeatureMatrixValues(fmProfileResp, ssoProfileResp)
      dispatch({
        type: ROUTE_ACTIONS.SET_FEATURE_MATRIX,
        featureMatrix,
      })

      dispatch({
        type: ROUTE_ACTIONS.INITIATE_GLOBAL_STORE,
        isInitiated: true,
      })
    }
  )

  // fetching notifications in the init function, it only needs to be fetched once at the beginning of the session and stored in redux
  dispatch(fetchNotifications())
}

export const getTwoFaStatus = () => async (dispatch: Dispatch<ActionTypes>, getState) => {
  const twoFaResp = await get(ENDPOINTS.API_SSO_TWO_FA)
  const isWithin12Hours = (utcTimeStamp: number) => {
    const currentTime = new Date().getTime()

    const twelveHoursInMillis = coolingDownThresholdInMinutes * 60 * 1000
    const twelveHoursFromTimestamp = Number(utcTimeStamp) + twelveHoursInMillis

    const remainingTimeInMillis = twelveHoursFromTimestamp - currentTime

    const isWithin12Hours = remainingTimeInMillis > 0

    return {
      isWithin12Hours,
    }
  }

  const res = isWithin12Hours(getState().route.user.twoFa.twoFaSetupTime)

  return dispatch({
    type: ROUTE_ACTIONS.SET_USER_PROFILE,
    user: {
      ...getState().route.user,
      twoFa: {
        ...getState().route.user.twoFa,
        isOnCoolingDownPeriod: res.isWithin12Hours,
        twoFaList: twoFaResp[0]?.userCredentialMetadatas || [],
      },
    },
  })
}

export const invalidateGlobalStore = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.INVALIDATE_GLOBAL_STORE,
    isInitiated: false,
  })
}

export const fetchNotifications = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING,
    isLoading: true,
  })

  post(ENDPOINTS.API_NOTIFICATION, { site: 'biz' }).then(resp => {
    const unreadCount = resp.total_unread
    dispatch({
      type: ROUTE_ACTIONS.SET_NOTIFICATIONS,
      data: resp.data,
      unreadCount,
      isLoading: false,
    })
  })
}

export const setIsChangeToFiatCurrency = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_IS_CHANGE_TO_FIAT_CURRENCY_ENABLED,
    isUsingFiatCurrency: enabled,
  })
}

export const setIsWelcomeModalOpened = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED,
    enabled,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseFeatureMatrixValues = (fmProfileResp: any, ssoProfileResp: any) => {
  const walletIds = new Set()
  const fundPoolsIds = new Set()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fmProfileResp.accounts.data.forEach((account: any) => {
    walletIds.add(account.attributes.wallet_id)
    fundPoolsIds.add(account.attributes.fund_pool_id)
  })

  // Note: Setting this as 'invalid' because we need to be able to differentiate between
  // undefined that comes from API not being able to read the ssoProfileResp.userRole[0] value
  // or undefined because the API hasn't resolved yet. This is string not in the USER_ROLES enum
  // because USER_ROLES should only include values that is actually valid and can be handled
  // in rbacRules, as checked in rbacHoc checkAllowed function
  const ssoProfileUserRole =
    ssoProfileResp?.user_role && ssoProfileResp.user_role.length > 0
      ? // Find the element that match one of our USER_ROLES
        ssoProfileResp.user_role.find((role: USER_ROLES) =>
          Object.values(USER_ROLES).includes(role)
        )
      : 'invalid'

  return {
    userRole: ssoProfileUserRole,
    country: ConfigManager.getCountry(),
    availableWallets: [...walletIds],
    businessVerificationStatus: fmProfileResp.business_verification_status,
    personalVerificationStatus: fmProfileResp.personal_verification_status,
    availableFundPools: [...fundPoolsIds],
    companyTxnVolume: Number(fmProfileResp.total_transaction_volume),
    isIdentificationDone: fmProfileResp.personal_identification_status === 'identified',
  } as FeatureMatrix
}
