import { createSelector } from 'reselect'
import { RootState } from '~/app/store/types'
import { UserInfo } from './types'
import { PERSONAL_VERIFICATION_STATUS } from '~/types'

const selectUserInfo = (state: RootState) => ({
  ...state.userInfo.userInfo,
  isUserInfoInitiated: state.userInfo.isUserInfoInitiated,
})
const selectUserVerificationStatus = createSelector(
  [selectUserInfo],
  userInfo => userInfo.verificationStatus
)

const selectUserActivityStatus = createSelector(
  [selectUserInfo],
  userInfo => userInfo.activityStatus
)

const selectIsKillSwitchActive = createSelector(
  [selectUserInfo],
  userInfo => userInfo.killSwitchActivated
)

const selectIsRiskModalTncAccepted = createSelector(
  [selectUserInfo],
  userInfo => userInfo.riskModalTncAccepted
)

const selectIsDpt2TncAccepted = createSelector(
  [selectUserInfo],
  userInfo => userInfo.ownerAcceptedDpt2Tnc
)

const selectUserName = createSelector(
  [selectUserInfo],
  userInfo => userInfo.fullName || userInfo.firstName
)

const selectIsAccountLocked = createSelector(
  [selectUserInfo],
  userInfo => userInfo.accountLocked === true
)

const selectUserEmail = createSelector([selectUserInfo], userInfo => userInfo.email)

const selectUserProfile = (state: RootState) => state.userInfo.userProfile

const selectUserAvatarUrl = createSelector(
  [selectUserProfile],
  userProfile => userProfile?.avatarUrl
)

const selectUserUsdVirtualAccount = createSelector(
  [selectUserInfo],
  userInfo => userInfo?.usdcExtension?.virtualAccount
)

const selectIsUserFullyVerified = createSelector([selectUserInfo], userInfo => {
  return userInfo.verificationStatus === PERSONAL_VERIFICATION_STATUS.VERIFIED && !!userInfo.phoneNo
})

const getVerificationStatus = (userDetails: UserInfo) => userDetails.verificationStatus

export const verificationMapping = createSelector([getVerificationStatus], verificationStatus => {
  type verifiedStatus =
    | 'verified'
    | 'unverified'
    | 'pending'
    | 'rejected'
    | 'inactive'
    | 'permanently_rejected'
  let status: verifiedStatus = 'unverified'
  if (verificationStatus === PERSONAL_VERIFICATION_STATUS.VERIFIED) {
    status = 'verified'
  } else if (verificationStatus === PERSONAL_VERIFICATION_STATUS.INACTIVE) {
    status = 'inactive'
  } else if (verificationStatus === PERSONAL_VERIFICATION_STATUS.PENDING) {
    status = 'pending'
  } else if (verificationStatus === PERSONAL_VERIFICATION_STATUS.REJECTED) {
    status = 'rejected'
  } else if (verificationStatus === PERSONAL_VERIFICATION_STATUS.PERMANENTLY_REJECTED) {
    status = 'permanently_rejected'
  }
  return status
})

const selectUserFirstName = createSelector([selectUserInfo], userInfo => userInfo.firstName)
const selectUserLastName = createSelector([selectUserInfo], userInfo => userInfo.lastName)
const selectUserFullName = createSelector([selectUserInfo], userInfo => userInfo.fullName)

const selectUserFullNameChecking = createSelector(
  [selectUserFirstName, selectUserLastName, selectUserFullName],
  (userFirstName, userLastName, userFullName) => {
    if (!userFirstName || !userLastName) {
      return userFullName
    } else {
      return userFirstName + ' ' + userLastName
    }
  }
)

const selectUserRequiresManualPeriodicReview = createSelector(
  [selectUserInfo],
  userInfo => userInfo.periodicReviewRequired && !userInfo.automaticPeriodicReviewEnabled
)

const selectUserRequiresAutoPeriodicReview = createSelector(
  [selectUserInfo],
  userInfo => userInfo.periodicReviewRequired && userInfo.automaticPeriodicReviewEnabled
)

const selectIsUserPeriodicReviewOverdueAndAutoPeriodicReviewEnabled = createSelector(
  [selectUserInfo],
  userInfo => userInfo.automaticPeriodicReviewEnabled && userInfo.periodicReviewOverdue
)

export {
  selectIsUserPeriodicReviewOverdueAndAutoPeriodicReviewEnabled,
  selectUserInfo,
  selectUserVerificationStatus,
  selectUserActivityStatus,
  selectUserProfile,
  selectUserAvatarUrl,
  selectUserName,
  selectUserEmail,
  selectUserFirstName,
  selectUserLastName,
  selectUserFullName,
  selectUserFullNameChecking,
  selectUserRequiresManualPeriodicReview,
  selectUserRequiresAutoPeriodicReview,
  selectUserUsdVirtualAccount,
  selectIsKillSwitchActive,
  selectIsRiskModalTncAccepted,
  selectIsDpt2TncAccepted,
  selectIsAccountLocked,
  selectIsUserFullyVerified,
}
