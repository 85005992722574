/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Info } from '@xfers/design-system/es/icons'
import { Tooltip, straitsXTheme, Button, NEUTRAL_COLORS } from '@xfers/design-system'
import { CURRENCY, FIELD } from '~/types'
import { SUPPORT_LINKS } from '~/common/constants'

type ClickableTooltipType = {
  currency?: CURRENCY
  field?: FIELD
}

export default function ClickableTooltip({ currency, field }: ClickableTooltipType) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const tooltipTextHandler = (currency?: CURRENCY, field?: string) => {
    if (currency) {
      switch (currency.toLowerCase()) {
        case CURRENCY.XSGD:
          return 'XSGD is the StraitsX Singapore dollar. Each XSGD is pegged 1:1 to the Singapore dollar.'
        case CURRENCY.XUSD:
          return 'XUSD is the StraitsX U.S. dollar. Each XUSD is pegged 1:1 to the U.S. dollar.'
        default:
          return ''
      }
    } else {
      switch (field) {
        case FIELD.INTERMEDIARY_SWIFT_CODE:
          return 'An intermediary SWIFT/BIC code is used to direct your transaction through an intermediary bank. If you are unsure, please contact your bank for assistance.'
        case FIELD.ROUTING_CODE:
          return 'A routing code is a unique code that helps identify your bank. You can find it on your bank website or bank application.  If you are unsure, please contact your bank for assistance.'
        default:
          return ''
      }
    }
  }

  const learnMoreLinkHandler = (currency?: CURRENCY, field?: string) => {
    if (currency) {
      switch (currency.toLowerCase()) {
        case CURRENCY.XSGD:
          return SUPPORT_LINKS.LEARN_MORE_XSGD
        case CURRENCY.XUSD:
          return SUPPORT_LINKS.LEARN_MORE_XUSD
        default:
          return ''
      }
    } else {
      switch (field) {
        case FIELD.INTERMEDIARY_SWIFT_CODE:
          return SUPPORT_LINKS.LEARN_MORE_INTERMEDIARY_SWIFT_CODE
        case FIELD.ROUTING_CODE:
          return SUPPORT_LINKS.LEARN_MORE_ROUTING_CODE
        default:
          return ''
      }
    }
  }

  return (
    <Tooltip
      overlayInnerStyle={{
        width: '280px',
        fontSize: '15px!important',
        lineHeight: '22px!important',
      }}
      placement="top"
      trigger={'click'}
      visible={isTooltipOpen}
      onVisibleChange={setIsTooltipOpen}
      color={straitsXTheme.brand.primary.default}
      title={tooltipTextHandler(currency, field)}
      footer={
        <Row>
          <StyledButton type="tertiary">
            <StyledLink href={learnMoreLinkHandler(currency, field)} target="_blank">
              Learn More
            </StyledLink>
          </StyledButton>
          <StyledButton onClick={() => setIsTooltipOpen(false)} type="tertiary">
            Dismiss
          </StyledButton>
        </Row>
      }
    >
      <StyledInfoIcon
        color={field ? NEUTRAL_COLORS.BLACK : straitsXTheme.brand.primary.default}
        onClick={() => setIsTooltipOpen(true)}
        height={18}
        width={18}
      />
    </Tooltip>
  )
}

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  gap: 20px;
`

const StyledButton = styled(Button)`
  color: #fff !important;
`

const StyledInfoIcon = styled(Info)`
  margin-left: 6px;
  cursor: pointer;
`

const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`
