// Alphabetize Please
enum ENDPOINTS {
  // API V4
  API_XFERS_ACCOUNTS_INFO_FOR_SDK = 'api/v3/xfers/accounts/info_for_sdk',

  // API V3
  API_V3_BANKS = 'api/v3/banks',
  API_V3_DASHBOARD_INFO = 'api/v3/xfers/dashboard',
  API_V3_DIRECT_URL = 'api/v3/user/upload_documents',
  API_V3_ACKNOWLEDGE_NEW_BITGO_ADDRESS = 'api/v3/user/acknowledge_bitgo_address',
  API_V3_GOOGLE_AUTHENTICATOR_INFO = 'api/v3/google_authenticator',
  API_V3_GOOGLE_AUTHENTICATOR_VERIFY_CODE = 'api/v3/google_authenticator/verify_code',
  API_V3_USER_PROFILE = 'api/v3/user/user_profile',
  API_V3_UPDATE_USER_PROFILE = 'api/v3/user/update_user_profile',
  API_V3_INDO_KTP_OPTIONS_ENDPOINT = 'api/v3/user/indo_ktp_options',
  API_V3_INITIATE_MY_INFO_ENDPOINT = 'api/v3/user/kyc_initiate_myinfo',
  API_V3_MERCHANTS_ACTIVITIES = 'api/v3/merchant/update_business_activities',
  API_V3_MERCHANT_TAX_RESIDENCES = 'api/v3/merchant/update_business_tax_requirements',
  API_V3_MERCHANT_CORPORATE = 'api/v3/merchant/corporates',
  API_V3_MERCHANT_CORPORATE_DOCUMENTS = 'api/v3/merchant/corporates/update_documents',
  API_V3_MERCHANT_DETAILS = 'api/v3/merchant/get_merchant_details',
  API_V3_MERCHANT_INDIVIDUAL = 'api/v3/merchant/beneficial_owners',
  API_V3_MERCHANT_INDIVIDUAL_DOCUMENTS = 'api/v3/merchant/beneficial_owners/update_documents',
  API_V3_MERCHANT_INFORMATION = 'api/v3/merchant/update_business_information',
  API_V3_MERCHANT_USERS = 'api/v3/merchant_users',
  API_V3_MERCHANT_UPLOAD_DOCUMENTS = 'api/v3/merchant/update_business_documents',
  API_V3_MERCHANT_RELATIONS = 'api/v3/merchant/update_business_relations',
  API_V3_MERCHANT_SUBMIT_VERIFICATION = 'api/v3/merchant/submit_verification',
  API_V3_MERCHANT_WITHDRAW = 'api/v3/xfers/merchant/withdraw',
  API_V3_MY_INFO_DETAILS_ENDPOINT = 'api/v3/user/kyc_myinfo_details',
  API_V3_NAMECHECK = 'api/v3/disbursements/namecheck',
  API_V3_PAYMENTS_DEPOSIT_REQUEST = 'api/v3/payment_gateway/payments/deposits',
  API_V3_UNIQUE_AMT_PAYMENT = 'api/v3/payment_gateway/payment_methods/unique_amount_bank_transfers',
  API_V3_UPDATE_USER_ENDPOINT = 'api/v3/user/update_account_info_for_sdk',
  API_V3_USER = 'api/v3/user',
  API_V3_USER_BANK_ACCOUNT = 'api/v3/user/bank_account',
  API_V3_USER_CHECK_NRIC_EXISTS = 'api/v3/user/check_nric_exists',
  API_V3_USER_CLAIM_KYC = 'api/v3/user/claim_kyc',
  API_V3_USER_GET_EMAILS_FROM_NRIC = 'api/v3/user/get_emails_from_nric',
  API_V3_USER_TOPUP_REQUEST_FOR_SDK = 'api/v3/user/topup_request_for_sdk',
  API_V3_USER_TOPUP_REQUEST = 'api/v3/user/topup_request',
  API_V3_USER_TRANSFER_INFO = 'api/v3/user/transfer_info',
  API_V3_USER_WITHDRAWAL_LIMITS = 'api/v3/user/bank_account/withdrawal_limits',
  API_V3_PAYMENTS = 'api/v3/payments',
  API_V3_USER_RISK_MODAL_TNC_ACCEPTED = 'api/v3/user/accept_risk_modal_tnc',
  API_V3_USER_DPT2_MODAL_TNC_ACCEPTED = 'api/v3/user/accept_dpt2_tnc',
  API_V3_USER_ACKNOWLEDGEMENT = 'api/v3/user/acknowledgements',
  API_V3_UPDATE_USER_ACKNOWLEDGEMENT = 'api/v3/user/acknowledge_account_verified',
  API_V3_UPDATE_ACTIVATION_CHECKLIST_ACKNOWLEDGEMENT = 'api/v3/user/acknowledge_activation_checklist',
  API_V3_UPDATE_USER_ACKNOWLEDGEMENT_DORMANT = 'api/v3/user/acknowledge_account_dormant',
  API_V3_ADD_TEAM_MEMBER = 'api/v3/user/add_merchant_member',

  // API V3 STABLECOIN
  API_V3_STABLECOIN_DEPOSIT_ADDRESS = 'api/v3/stablecoin/deposit_addresses',
  API_V3_STABLECOIN_XIDR_DEPOSIT_ADDRESS = 'api/v3/stablecoin/deposit_addresses/xidr',
  API_V3_STABLECOIN_ADDRESSES = 'api/v3/stablecoin/addresses',
  API_V3_STABLECOIN_XIDR_ADDRESSES = 'api/v3/stablecoin/addresses/xidr',
  API_V3_STABLECOIN_DASHBOARD_BANNERS = 'api/v3/stablecoin/dashboard_banners',
  API_V3_STABLECOIN_WITHDRAWALS = 'api/v3/stablecoin/withdrawals',
  API_V3_STABLECOIN_FEES = 'api/v3/stablecoin/fees',
  API_V3_STABLECOIN_FEES_XIDR = 'api/v3/stablecoin/fees/xidr',
  API_V3_STABLECOIN_SUBMIT_USER_KYCS = 'api/v3/stablecoin/submit_user_kycs',
  API_V3_STABLECOIN_USER_KYCS = 'api/v3/stablecoin/user_kycs',
  API_V3_STABLECOIN_USER_KYCS_KTP_QUALITY_CHECK = 'api/v3/stablecoin/user_kycs/ktp_quality_check',
  API_V3_STABLECOIN_USER_KYCS_SELFIE_LIVENESS_CHECK = 'api/v3/stablecoin/user_kycs/selfie_liveness_check',
  API_V3_STABLECOIN_USER_KYBS_PERSONAL = 'api/v3/stablecoin/user_kybs/personal',
  API_V3_STABLECOIN_USER_KYBS_BUSINESS = 'api/v3/stablecoin/user_kybs/business',
  API_V3_STABLECOIN_USER_KYB_BUSINESS_CATEGORIES = 'api/v3/stablecoin/user_kybs/business_categories',
  API_V3_STABLECOIN_USER_KYCS_UPLOAD_URLS = 'api/v3/stablecoin/user_kycs/upload_urls',
  API_V3_STABLECOIN_USERS = 'api/v3/stablecoin/users',
  API_V3_STABLECOIN_USER_PROFILE = 'api/v3/stablecoin/users/user_profile',
  API_V3_STABLECOIN_UPDATE_USER_PROFILE = 'api/v3/stablecoin/users/update_user_profile',
  API_V3_STABLECOIN_CITIES = 'api/v3/stablecoin/cities',
  API_V3_STABLECOIN_ACCOUNTS_INFO_FOR_SDK = 'api/v3/stablecoin/accounts/info_for_sdk',
  API_V3_STABLECOIN_BANKS = 'api/v3/stablecoin/banks',
  API_V3_STABLECOIN_BANKS_VIRTUAL_NETWORKS = 'api/v3/stablecoin/banks/xidr/virtual_networks',
  API_V3_STABLECOIN_USER_BANK_ACCOUNTS = 'api/v3/stablecoin/users/bank_accounts',
  API_V3_STABLECOIN_TEMPORARY_HOLDING_BALANCE_WITHDRAWAL = 'api/v3/stablecoin/temporary_holding_balance_withdrawal',
  API_V3_STABLECOIN_ACCOUNTS = 'api/v3/stablecoin/accounts',
  API_V3_STABLECOIN_TRANSACTIONS = 'api/v3/stablecoin/transactions',
  API_V3_STABLECOIN_TRANSACTIONS_TOTAL_COUNT = 'api/v3/stablecoin/transactions/total_count',
  API_V3_STABLECOIN_TRANSACTIONS_FILTER_OPTIONS = 'api/v3/stablecoin/transactions/filter_options',
  API_V3_STABLECOIN_TRANSACTIONS_EXPORT_CSV = 'api/v3/stablecoin/transactions/export_csv',
  API_V3_STABLECOIN_OTC_TRANSACTIONS = 'api/v3/stablecoin/otc/transactions',
  API_V3_STABLECOIN_USER_TRANSFER_INFO = 'api/v3/stablecoin/payment_method',
  API_V3_STABLECOIN_DIRECT_MINT_INSTRUCTIONS = 'api/v3/stablecoin/direct_mint_instructions', // XSGD Mint
  API_V3_STABLECOIN_DIRECT_MINTS = 'api/v3/stablecoin/direct_mints', // XIDR Mint
  API_V3_STABLECOIN_WITHDRAWALS_IDR = 'api/v3/stablecoin/withdrawals/idr',
  API_V3_STABLECOIN_WITHDRAWALS_XIDR = 'api/v3/stablecoin/withdrawals/xidr',
  API_V3_STABLECOIN_EARN_ACCOUNT = 'api/v3/stablecoin/earn/accounts',
  API_V3_STABLECOIN_EARN_DEPOSIT = 'api/v3/stablecoin/earn/deposits',
  API_V3_STABLECOIN_EARN_WITHDRAWALS = 'api/v3/stablecoin/earn/withdrawals',
  API_V3_STABLECOIN_EARN_TRANSACTIONS = 'api/v3/stablecoin/earn/transactions',
  API_V3_STABLECOIN_EARN_INTERESTS = 'api/v3/stablecoin/earn/interests',
  API_V3_STABLECOIN_EARN_PLATFORM = 'api/v3/stablecoin/earn/platform',
  API_V3_STABLECOIN_EARN_REGISTER_INTEREST = 'api/v3/stablecoin/earn/interest_registrations',
  API_V3_STABLECOIN_EARN_INTEREST_REGISTERED = 'api/v3/stablecoin/earn/interest_registrations',
  API_V3_STABLECOIN_PERIODIC_REVIEW = 'api/v3/stablecoin/periodic_review',
  API_V3_STABLECOIN_API_TOKEN = 'api/v3/stablecoin/api_tokens',
  API_V3_STABLECOIN_CALLBACK_URLS = 'api/v3/stablecoin/callback_urls',
  API_V3_STABLECOIN_CHECK_FEATURE = 'api/v3/stablecoin/check_feature',
  API_V3_STABLECOIN_IKYC_VERIFICATION_OPTIONS = 'api/v3/stablecoin/international_user_kycs_verification_options',
  API_V3_STABLECOIN_INTERNATIONAL_USER_KYCS = 'api/v3/stablecoin/international_user_kycs',
  API_V3_STABLECOIN_ACCOUNT_STATEMENTS = 'api/v3/stablecoin/account_statements',
  API_V3_STABLECOIN_ACCOUNT_STATEMENTS_RETRIEVE_ACCOUNT_OPTIONS = 'api/v3/stablecoin/account_statements/retrieve_account_options',
  API_V3_STABLECOIN_VIRTUAL_ACCOUNT = 'api/v3/stablecoin/virtual_account',
  API_V3_STABLECOIN_FEES_FIAT = 'api/v3/stablecoin/fees/fiat',

  // SWAP APIs
  API_V3_STABLECOIN_SWAP_EXCHANGE_RATE = 'api/v3/stablecoin/swap/exchange_rate',
  API_V3_STABLECOIN_SWAP_QUOTES = 'api/v3/stablecoin/swap/quotes',
  API_V3_STABLECOIN_SWAP_SWAPS = 'api/v3/stablecoin/swap/swaps',
  API_V3_STABLECOIN_SWAP_EXCHANGE_LIMITS = 'api/v3/stablecoin/swap/exchange_limits',

  // API V3 Sandbox
  API_V3_SANDBOX_MOCK_USER = 'api/v3/sandbox/mock_user',
  API_V3_SANDBOX_REJECT_USER = 'api/v3/sandbox/reject_user',
  API_V3_SANDBOX_TRANSACTIONS_TOPUP = 'api/v3/sandbox/transactions/topup',
  API_V3_SANDBOX_TRANSACTIONS_TOPUP_XIDR = 'api/v3/sandbox/transactions/topup/xidr',
  API_V3_SANDBOX_USERS_CONNECTED_TOKENS = 'api/v3/sandbox/users/connected_tokens',
  API_V3_SANDBOX_USERS_TOKEN = 'api/v3/sandbox/users/token',
  API_V3_SANDBOX_VERIFY_USER = 'api/v3/sandbox/verify_user',

  // API V3 XFERS(Internal only/ Dashboard only)
  API_XFERS_ACCOUNTS = 'api/v3/xfers/accounts',
  API_XFERS_ACCOUNT_SETTINGS = 'api/v3/xfers/account_settings',
  API_XFERS_AUTHENTICATION = 'api/v3/xfers/authentication',
  API_XFERS_FEATURE_MATRIX_PROFILE = 'api/v3/xfers/authentication/feature_matrix_profile',
  API_XFERS_CHECK_FEATURE = 'api/v3/xfers/check_feature',
  API_V3_BULK_DISBURSEMENTS = 'api/v3/xfers/disbursements/bulk_create',
  API_V3_FEES = 'api/v3/xfers/disbursements/show_fees',
  API_V3_FEES_BY_DISBURSEMENT = 'api/v3/xfers/disbursements/show_fees_by_disbursement',
  API_XFERS_DISBURSEMENTS_APPROVE = 'api/v3/xfers/disbursements/approve',
  API_XFERS_DISBURSEMENTS_REJECT = 'api/v3/xfers/disbursements/reject',
  API_XFERS_EMAIL_NOTIFICATION_SETTINGS = 'api/v3/xfers/email_notification_settings',
  API_XFERS_GUEST_PHONE_TOKEN_CHECK = 'api/v3/xfers/guest_account_registration/phone_token_check',
  API_XFERS_INDO_DEPOSIT_BANKS = '/api/v3/xfers/identifier_assignments',
  API_XFERS_INTERNAL_TOOLS_ENDPOINT = 'api/v3/xfers/internal_tools',
  API_XFERS_MERCHANT_DEPOSIT_VA = 'api/v3/xfers/merchant/deposit_virtual_accounts',
  API_XFERS_RESEND_CALLBACK = 'api/v3/xfers/webhook_settings/resend_callback',
  API_XFERS_RESEND_CONFIRMATION_EMAIL = 'api/v3/xfers/account_settings/resend',
  API_XFERS_TRANSACTIONS = 'api/v3/xfers/transactions/transactions',
  API_XFERS_TRANSACTIONS_TOTAL_COUNT = 'api/v3/xfers/transactions/transactions/total_count',
  API_XFERS_TRANSACTIONS_TRAVEL_RULE_COMPLIANCE_BLOCKER_TOTAL_COUNT = 'api/v3/xfers/transactions/transactions/total_travel_rule_blocked_count',
  API_XFERS_TRANSACTIONS_EXPORT_CSV = 'api/v3/xfers/transactions/transactions/export_csv.csv',
  API_XFERS_TRANSACTIONS_DECLARE_DEPOSIT = 'api/v3/xfers/transactions/transactions/declare_deposit',
  API_XFERS_TRANSACTIONS_FILTER_OPTIONS = 'api/v3/xfers/transactions/transactions/filter_options',
  API_XFERS_TRANSACTIONS_MERCHANT_TRANSACTION_LIMITS = 'api/v3/xfers/transactions/merchant_transaction_limits',
  API_XFERS_TRANSACTIONS_XFERS_ACCEPT = 'api/v3/xfers/transactions/xfers_accept',
  API_XFERS_TRANSACTIONS_XFERS_ACCEPT_EXPORT_CSV = 'api/v3/xfers/transactions/xfers_accept/export_csv',
  API_XFERS_TRANSACTIONS_XFERS_ACCEPT_FILTER_OPTIONS = 'api/v3/xfers/transactions/xfers_accept/filter_options',
  API_XFERS_TRANSACTIONS_XFERS_SEND = 'api/v3/xfers/transactions/xfers_send',
  API_XFERS_TRANSACTIONS_XFERS_SEND_EXPORT_CSV = 'api/v3/xfers/transactions/xfers_send/export_csv',
  API_XFERS_TRANSACTIONS_XFERS_SEND_FILTER_OPTIONS = 'api/v3/xfers/transactions/xfers_send/filter_options',
  API_XFERS_TRANSACTIONS_XFERS_DASH = 'api/v3/xfers/transactions/xfers_dash',
  API_XFERS_TRANSACTIONS_XFERS_DASH_EXPORT_CSV = 'api/v3/xfers/transactions/xfers_dash/export_csv',
  API_XFERS_TRANSACTIONS_XFERS_DASH_FILTER_OPTIONS = 'api/v3/xfers/transactions/xfers_dash/filter_options',
  API_XFERS_TRANSACTIONS_XFERS_ACCOUNT_STATEMENT = 'api/v3/xfers/transactions/ledgers',
  API_XFERS_TRANSACTIONS_XFERS_ACCOUNT_STATEMENT_FILTER_OPTIONS = 'api/v3/xfers/transactions/ledgers/filter_options',
  API_XFERS_TRANSACTIONS_XFERS_ACCOUNT_STATEMENT_EXPORT_CSV = 'api/v3/xfers/transactions/ledgers/export_csv',
  API_XFERS_TRANSACTIONS_ACCOUNT_BALANCE = 'api/v3/xfers/transactions/ledgers',
  API_XFERS_USER_MESSAGES = 'api/v3/xfers/user_messages',
  API_XFERS_USER_MESSAGE_ACKNOWLEDGMENTS = 'api/v3/xfers/user_message_acknowledgements',
  API_XFERS_WEBHOOK_SETTINGS = 'api/v3/xfers/webhook_settings',
  API_XFERS_INVOICES = 'api/v3/xfers/invoices',
  API_XFERS_INVOICE = 'api/v3/xfers/invoice',
  API_XFERS_UPLOAD_INVOICE = 'api/v3/xfers/invoice_upload',
  API_XFERS_DOWNLOAD_INVOICE = 'api/v3/xfers/invoice_downloads',
  API_XFERS_CREDIT_CARDS_PAYMENTS = 'api/v3/xfers/payment_instructions',
  API_XFERS_CREDIT_CARDS_DISPUTES = 'api/v3/xfers/external_disputes',
  API_XFERS_EARN_CONSENT = 'api/v3/xfers/transactions/earn/consent_flow',
  API_XFERS_CREATE_EARN_ACCOUNT = 'api/v3/xfers/transactions/earn/create_account',
  API_XFERS_EARN_INTEREST_INFO = 'api/v3/xfers/transactions/earn/interest',
  API_XFERS_CREATE_EARN_ACCOUNT_BALANCE = 'api/v3/xfers/transactions/earn/balance',
  API_XFERS_EARN_TRANSACTION = 'api/v3/xfers/transactions/earn/transactions',

  API_XFERS_MINT = 'api/v3/mint',
  API_XFERS_MINT_SETUP = 'api/v3/mint_setup',
  API_V3_FINANCIAL_INSTITUTIONS = 'api/v3/financial_institutions',

  // Other
  API_SSO_OTP_LOGIN = 'sso/device-login-flow',
  API_SSO_PROFILE = 'sso/profile',
  API_SSO_CLAIM_KYC = 'sso/claim-kyc',
  API_SSO_TWO_FA = 'sso/two_fa',
  API_SSO_TWO_FA_DELETE = 'sso/two_fa_delete',
  API_SSO_REFRESH_TOKEN = 'sso/refresh_token',
  API_SSO_CREDENTIALS = 'sso/credentials',
  API_SSO_ACCOUNT_STATUS = 'sso/account_status',

  // Team
  API_SSO_TEAM = 'sso/merchant/members',

  // Killswitch
  API_KILLSWITCH_ACTIVATE = 'api/v3/user/activate_kill_switch',

  // NOTIFICATION
  API_NOTIFICATION = 'api/v3/stablecoin/notifications',
  API_NOTIFICATION_FETCH = 'api/v3/user/acknowledgements',
  API_NOTIFICATION_READ = 'api/v3/stablecoin/notifications',
}

export default ENDPOINTS
