/* eslint-disable max-lines */
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT, Hidden, Divider } from '@xfers/design-system'
import { AvatarFilled } from '@xfers/design-system/es/icons'
import cookie from 'js-cookie'
import settingIcon from './setting.svg'
import switchModeIcon from './switchMode.svg'
import logoutIcon from './logout.svg'
import { PATHS } from '~/biz/routes/paths'
import { createGaEvent } from '~/helpers/gaHelper'
import SSOManager from '~/managers/SSOManager'
import { ConfigManager } from '~/managers/ConfigManager'
import { ModeManager, MODE } from '~/managers/ModeManager'
import { ELEVATIONS } from '~/common/elevation'
import { selectFlipperWithId } from '~/store/flippers/selectors'
import { FLIPPER_KEYS } from '~/store/flippers/const'
import { RootState } from '~/biz/store/types'

type UserProfileType = {
  isSandbox: boolean
  userInfo: {
    fullName: string
    avatarUrl: string
    displayname: string
  }
  displayModeSwitcher?: boolean
  onChangeModeCallback?: () => void
}

export default function({
  userInfo,
  isSandbox,
  displayModeSwitcher,
  onChangeModeCallback,
}: UserProfileType) {
  const history = useHistory()
  const { fullName, avatarUrl, displayname: companyName } = userInfo
  const dispatch = useDispatch()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const { user } = useSelector((state: RootState) => state.route)

  const flipperDisableManageAccountsPage = useSelector(
    selectFlipperWithId(FLIPPER_KEYS.STRAITSX_SANDBOX_DISABLE_MANAGE_ACCOUNTS_PAGE, user?.email)
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClick)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClick)
    }
  }, [dispatch])

  const signOut = () => {
    cookie.remove('SESSIONID')
    SSOManager.ssoLogout()
  }

  const profileImage = ConfigManager.isDev() ? `http://localhost:3000${avatarUrl}` : avatarUrl

  const goToMyLandingPage = () => {
    ModeManager.toggleMode()
    if (onChangeModeCallback) onChangeModeCallback()
  }

  // MyAccounts Page only exists in Production
  const goToMyAccountsPage = () => {
    ModeManager.toggleMode(MODE.PRODUCTION)

    history.push(PATHS.MANAGE_ACCOUNT)
  }

  const fullNameEllipsis = fullName?.length > 20 ? fullName.slice(0, 20) + '...' : fullName

  const SwitchModeOptionsComponent = () => (
    <SwitchModeOptions
      onClick={() => {
        createGaEvent('General', 'clickToggleSandboxModeLink', 'Clicked Toggle Sandbox in Dropdown')
        goToMyLandingPage()
      }}
    >
      <MenuIcon data-cy="switch-sandbox" src={switchModeIcon} />
      Switch to {isSandbox ? <>Production</> : <>Sandbox</>} Mode
    </SwitchModeOptions>
  )

  /**
   * Different Menu for different Account type
   * Corporate Account (Everything except change mode )
   * Business Account (Everything)
   *
   * hiding companyname (ie user.displayname) for straitsX app users
   */
  return (
    <Container ref={containerRef}>
      <UserInfo
        data-cy="div-userInfo"
        onClick={() => setIsDropdownOpen(previousState => !previousState)}
      >
        <UserAvatar>
          {avatarUrl === 'cardProfileDefault.png' ? (
            <AvatarFilled color={'#FFFFFF'} />
          ) : (
            <ImgFrame src={profileImage} />
          )}
        </UserAvatar>
        <Hidden only="sm">
          {fullNameEllipsis}
          <br />
          {!ConfigManager.isAppSite() && <MenuSubText>{companyName}</MenuSubText>}
        </Hidden>
      </UserInfo>
      {isDropdownOpen && (
        <UserMenu>
          <UserInformation>
            <UserAvatarDropdown>
              {avatarUrl === 'cardProfileDefault.png' ? (
                <AvatarFilled color={'#FFFFFF'} width={16} height={16} />
              ) : (
                <ImgFrame src={profileImage} />
              )}
            </UserAvatarDropdown>
            <div>
              {fullNameEllipsis}
              <br />
              {!ConfigManager.isAppSite() && <MenuSubText>{companyName}</MenuSubText>}
            </div>
          </UserInformation>

          <Divider style={{ marginLeft: '15px', marginRight: '15px' }} />

          {displayModeSwitcher && (
            <>
              <SwitchModeOptionsComponent />
              <Divider style={{ marginLeft: '15px', marginRight: '15px' }} />
            </>
          )}

          {
            (ConfigManager.getEnvironmentMode() !== MODE.SANDBOX.toString() && !flipperDisableManageAccountsPage?.enabled) && (
              <ActionOptions
                onClick={() => {
                  createGaEvent('General', 'clickMyAccountLink', 'Clicked My Account in Dropdown')
                  goToMyAccountsPage()
                  setIsDropdownOpen(false)
                }}
              >
                <MenuIcon src={settingIcon} /> My Account
              </ActionOptions>
            )
          }

          <ActionOptions
            onClick={() => {
              createGaEvent('General', 'clickLogoutLink', 'Clicked Logout in Dropdown')
              signOut()
            }}
          >
            <MenuIcon src={logoutIcon} /> Logout
          </ActionOptions>
        </UserMenu>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const MenuIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 15px;
`

const UserInformation = styled.div`
  height: 76px;
  display: flex;
  align-items: center;
  margin: 0px 15px 0px 15px;
`
const SwitchModeOptions = styled.div`
  height: 58px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  &:hover {
    transition: 0.3s ease-out;
    background: #f6f7f9;
  }
`
const ActionOptions = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px 15px 10px 15px;
  &:hover {
    transition: 0.3s ease-out;
    background: #f6f7f9;
    border-radius: 5px;
  }
`

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 72px;
  padding: 0px 15px 0px 15px;
  &:hover {
    transition: 0.3s ease-out;
    background: rgba(0, 211, 126, 0.1);
  }
`

const UserAvatar = styled.div`
  width: 30px;
  height: 30px;
  background: rgba(0, 92, 55, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${DESKTOP_BREAKPOINT} {
    margin-right: 10px;
  }
`

const UserAvatarDropdown = styled.div`
  width: 30px;
  height: 30px;
  background: #959595;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 15px;
  ${DESKTOP_BREAKPOINT} {
    margin-left: 0px;
  }
`

const ImgFrame = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
`

const UserMenu = styled.div`
  position: absolute;
  top: 72px;
  right: -10px;
  z-index: ${ELEVATIONS.MENUS_AND_SUB_MENUS};
  width: 260px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(149, 149, 149, 0.25);
  border-radius: 5px;
  transition-timing-function: ease;
  padding-bottom: 5px;
`

const MenuSubText = styled.div`
  text-align: left;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #777777;
`
