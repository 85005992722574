import { createSelector } from 'reselect'
import { RootState } from '~/biz/store/types'

const selectMerchantInfoStore = (state: RootState) => state.merchantInfo

const selectUserInfo = createSelector([selectMerchantInfoStore], merchantInfoStore => ({
  isUserInfoInitiated: merchantInfoStore.isUserInfoInitiated,
  userInfo: merchantInfoStore.userInfo,
}))

const selectPersonalVerificationStatus = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.verificationStatus
)

const selectKYCVerificationDate = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.kycVerifiedDate
)

const selectUserName = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.fullName || userInfo?.firstName
)

const selectIsKillSwitchActive = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.killSwitchActivated
)

const selectIsRiskModalTncAccepted = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.riskModalTncAccepted
)

const selectIsDpt2TncAccepted = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.ownerAcceptedDpt2Tnc
)

const selectUserProfile = (state: RootState) => state.merchantInfo.userProfile
const selectIsUserProfileInitiated = (state: RootState) => state.merchantInfo.isUserProfileInitiated

const selectUserAvatarUrl = createSelector(
  [selectUserProfile],
  userProfile => userProfile?.avatarUrl
)

const selectUserUsdVirtualAccount = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.usdcExtension?.virtualAccount
)

const selectUserAccVerified = createSelector([selectUserInfo], ({ userInfo }) => {
  return userInfo?.accountFullyVerified || userInfo?.verificationStatus === 'verified'
})

const selectUserInactive = createSelector([selectUserInfo], ({ userInfo }) => {
  return userInfo?.verificationStatus === 'inactive'
})

const selectUserKycVerified = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.kycInformationVerifying
)

const selectUserKycRejected = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.kycRejectedReason
)

export const selectVerificationMapping = createSelector(
  [selectUserAccVerified, selectUserKycVerified, selectUserKycRejected, selectUserInactive],
  (accFullyVerified, kycInfoVerifying, kycRejectReason, userInactive) => {
    type verifiedStatus = 'verified' | 'unverified' | 'pending' | 'rejected' | 'inactive'
    let status: verifiedStatus = 'unverified'

    if (accFullyVerified) {
      status = 'verified'
    } else if (userInactive) {
      status = 'inactive'
    } else if (kycInfoVerifying) {
      status = 'pending'
    } else if (!accFullyVerified && !kycInfoVerifying && kycRejectReason) {
      status = 'rejected'
    } else if (!accFullyVerified && !kycInfoVerifying && !kycRejectReason) {
      status = 'unverified'
    }
    return status
  }
)

export const selectMerchantDetail = createSelector([selectMerchantInfoStore], store => ({
  isMerchantDetailInitiated: store.isMerchantDetailInitiated,
  merchantDetail: store.merchantDetail,
}))

export const selectBusinessVerificationStatus = createSelector(
  [selectMerchantDetail],
  ({ merchantDetail }) => merchantDetail?.verificationStatus
)

export const selectIsGAuthEnabled = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.gauthEnabled === true
)

 const selectIsAccountLocked = createSelector(
  [selectUserInfo],
  ({ userInfo }) => userInfo?.accountLocked === true
)

const selectBusinessName = createSelector(
  [selectMerchantDetail],
  ({ merchantDetail }) => merchantDetail?.businessName
)

export {
  selectUserInfo,
  selectPersonalVerificationStatus,
  selectUserProfile,
  selectIsUserProfileInitiated,
  selectUserAvatarUrl,
  selectUserName,
  selectBusinessName,
  selectUserUsdVirtualAccount,
  selectIsKillSwitchActive,
  selectIsRiskModalTncAccepted,
  selectIsDpt2TncAccepted,
  selectKYCVerificationDate,
  selectIsAccountLocked,
}
