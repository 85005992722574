import React, { useEffect, useMemo, useState } from 'react'
import { Select } from '@xfers/design-system'
import Skeleton from 'antd/es/skeleton'
import { getCountryList } from '~/api/Verification/Verification.api'
import { IkycVerificationOptions, SelectOption } from '~/app/store/verification/types'

type Props = {
  selectedCountry: string | string[] | undefined
  isMultiple?: boolean
  onSelectCountryChange: (country: string | string[]) => void
}

export const CountryOptions = ({selectedCountry, isMultiple, onSelectCountryChange}: Props) => {
  const [countries, setCountries] = useState<SelectOption[]>()

  const onChange = (value: string | string[]) => {
    const upperCaseValue = Array.isArray(value) ? value.map(v => v.toUpperCase()) : value.toUpperCase()
    onSelectCountryChange(upperCaseValue)
  }

  useEffect(() => {
    if (!countries) {
      getCountryList({
        successCallback: (response: IkycVerificationOptions) => {
          setCountries(response.countries)
        },
      })
    }
  }, [countries])

  const memoizedCountries = useMemo(() => {
    if (!countries || !Array.isArray(countries)) return []

    const compareFn = (optionA: { label: string }, optionB: { label: string }) =>
      optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())

    return countries
      .sort(compareFn)
      .map((option) => {
        const label = option.label.toLowerCase()
        const value = option.value.toLowerCase()
        return (
          <Select.Option key={label} value={value}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div
                style={{
                  width: '18px',
                  height: '18px',
                  borderRadius: '50%',
                  backgroundSize: 'cover',
                  filter: 'sepia(40%)',
                }}
                className={`fib fi-${value}`}
              />
              {label.charAt(0).toUpperCase() + label.slice(1)}
            </div>
          </Select.Option>
        )
      })
  }, [countries])

  return (
    <>
      <Select
        showSearch
        filterOption={(input, option) => {
          const optionLabel = option?.key?.toString().toLowerCase() ?? ''
          const optionValue = option?.value?.toString().toLowerCase() ?? ''
          const searchInput = input.toLowerCase()
          return optionLabel.includes(searchInput) || optionValue.includes(searchInput)
        }}
        placeholder="Select your country"
        type="dropdown"
        mode={isMultiple ? "multiple" : undefined }
        value={memoizedCountries.length > 0 ? selectedCountry?.toString().toLowerCase() : undefined}
        onChange={onChange}
        loading={!memoizedCountries.length}
      >
        {memoizedCountries.length > 0 ? memoizedCountries : <Skeleton active />}
      </Select>
    </>
  )
}
