import { State, STEPS, ActionTypes, ACTIONS } from './types'

export const initialState: State = {
  step: STEPS.REMINDER,
  accountHolderName: '',
  bankAccount: {
    id: undefined,
    accountHolderName: '',
    swiftCode: '',
    accountNumber: '',
    bankName: '',
  },
  billingAddress: {
    line1: '',
    line2: '',
    line3: '',
    city: '',
    country: '',
    postalCode: '',
  },
  successCallback: () => {},
  editMode: false,
}

export default (state: State, action: ActionTypes) => {
  switch (action.type) {
    case ACTIONS.SET_STEP:
      return {
        ...state,
        step: action.step,
      }
    case ACTIONS.SET_BANK_ACCOUNT:
      return {
        ...state,
        bankAccount: {
          ...action.bankAccount,
        },
      }
    case ACTIONS.SET_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: {
          ...action.billingAddress,
        },
      }
    default:
      return state
  }
}
