import React, { useState, useRef, useEffect, useCallback } from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import Spin from 'antd/es/spin'
import 'antd/es/spin/style/css'
import { BellFilled, CrossMini, UpArrow as ArrowLeft } from '@xfers/design-system/es/icons'
import { H3, H4, P1, P1Bold, SmallP, DESKTOP_BREAKPOINT, Label } from '@xfers/design-system'
import importantIcon from '~/assets/ic_status_caution_triangle.svg'
import maintenanceIcon from '~/assets/ic_status_maintenance.svg'
import productUpdateIcon from '~/assets/ic_toolbar_send.svg'
import otcIcon from '~/assets/ic_feature_globe.svg'

import { ELEVATIONS } from '~/common/elevation'
import StraitsXEmptyData from '~/components/StraitsXEmptyData'
import { useGetNotifications, useReadNotifications } from '~/api/Notification/Notification.hooks'
import { useResponsive } from '~/hooks/useResponsive'
import P0Component from './SvgComponent/P0Component'

type NotificationData = {
  bannerId: number
  title: string
  content: string
  created_at: string
  is_read: number
  external_link: string
  tag: TagOption
  priority: number
}

type TagOption = 'All' | 'Important' | 'System Maintenance' | 'Product Update' | 'OTC Services'

export default function() {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [isNotificationsCleared, setIsNotificationsCleared] = useState(false)
  const [activeTag, setActiveTag] = useState<TagOption>('All')
  const { isMobileView } = useResponsive()

  const { data: notificationData, isFetching, refetch } = useGetNotifications(
    activeTag === 'All' ? '' : activeTag
  )

  const isFirstLoad = useRef(true)

  const { mutate: readNotifications } = useReadNotifications()

  useEffect(() => {
    if (!isFirstLoad.current && activeTag) {
      refetch()
    } else {
      isFirstLoad.current = false
    }
  }, [activeTag, refetch])

  const handleReadNotifications = useCallback(() => {
    if (notificationData.data && notificationData.data.length > 0) {
      const notificationIds = notificationData.data.map(notification => notification.id.toString())
      readNotifications(notificationIds)
      setIsNotificationsCleared(true)
    }
  }, [notificationData, readNotifications, refetch])

  useEffect(() => {
    if (isNotificationsOpen && notificationData && notificationData.total_unread) {
      handleReadNotifications()
    }
  }, [isNotificationsOpen, notificationData, handleReadNotifications])

  const notificationMenu = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    if (notificationMenu.current && !notificationMenu.current.contains(event.target)) {
      setIsNotificationsOpen(false)
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClick)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const iconMapper = (tag: string) => {
    switch (tag) {
      case 'Important':
        return importantIcon
      case 'Product Update':
        return productUpdateIcon
      case 'Announcement':
        return maintenanceIcon
      case 'System Maintenance':
        return maintenanceIcon
      case 'OTC Services':
        return otcIcon
      default:
        return productUpdateIcon
    }
  }

  const tagOptions: TagOption[] = [
    'All',
    'Important',
    'System Maintenance',
    'Product Update',
    'OTC Services',
  ]

  const tagRender = () => {
    return (
      <>
        {tagOptions.map(option => {
          if (option === activeTag) {
            return <SelectedLabel>{option}</SelectedLabel>
          } else {
            return (
              <CustomLabel type="neutral" onClick={() => setActiveTag(option)}>
                {option}
              </CustomLabel>
            )
          }
        })}
      </>
    )
  }

  const isP0Important = (tag: TagOption, priority: number) => {
    if (tag === 'Important' && priority === 0) {
      return true
    }
    return false
  }

  const notificationCards = notificationData?.data?.map((notification: NotificationData) => {
    const momentDate = notification.created_at
      ? moment(notification.created_at).format('D MMM YYYY')
      : '-'

    return (
      <NotificationBorder key={notification.bannerId}>
        <NotificationBox>
          <div style={{ paddingTop: 6 }}>
            {notification.tag !== 'Important' ? (
              <img src={iconMapper(notification.tag)} />
            ) : (
              <P0Component color={notification.priority > 0 ? '#121212' : '#E5C412'} />
            )}
          </div>

          <div style={{ width: '100%', paddingLeft: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', margin: '8px 0px' }}>
              <NotificationTag
                style={{
                  color: isP0Important(notification.tag, notification.priority) && '#E22726',
                }}
              >
                {notification.tag || ''}
              </NotificationTag>
              <NotificationTime>{momentDate || ''}</NotificationTime>
            </div>
            <NotificationHeader>{notification.title || ''}</NotificationHeader>
            <NotificationBody>{notification.content || ''} </NotificationBody>
          </div>
        </NotificationBox>
      </NotificationBorder>
    )
  })

  return (
    <Container>
      {!isNotificationsOpen && (
        <NotificationInfo
          onClick={() => {
            setIsNotificationsOpen(true)
          }}
        >
          <GreyCircle style={{ background: 'rgba(0, 92, 55, 0.5)' }}>
            <BellFilled color={'#FFFFFF'} style={{ width: '16px', height: '16px' }} />
          </GreyCircle>
          {notificationData?.total_unread > 0 && !isNotificationsCleared && (
            <UnreadCount>
              {notificationData?.total_unread > 99 ? '99+' : notificationData?.total_unread}
            </UnreadCount>
          )}
        </NotificationInfo>
      )}
      {isNotificationsOpen && (
        <>
          <NotificationInfo onClick={() => setIsNotificationsOpen(false)} isNotificationsOpen>
            <GreyCircle style={{ background: 'rgba(0, 92, 55, 0.5)' }}>
              <BellFilled color={'#FFFFFF'} style={{ width: '16px', height: '16px' }} />
            </GreyCircle>
          </NotificationInfo>
          <NotificationMenu ref={notificationMenu}>
            {isMobileView && (
              <div style={{ padding: '16px 16px 0px 16px' }}>
                <ArrowLeft
                  style={{ transform: 'rotate(-90deg)' }}
                  onClick={() => setIsNotificationsOpen(false)}
                />
              </div>
            )}
            <NotificationHeaderContainer>
              <MenuHeader>Notifications</MenuHeader>
              {!isMobileView && (
                <div
                  onClick={() => setIsNotificationsOpen(false)}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setIsNotificationsOpen(false)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <CrossMini color={'#121212'} />
                </div>
              )}
            </NotificationHeaderContainer>

            <TagContainer>
              {tagRender()}
            </TagContainer>
            <MenuBody>
              {isFetching && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                >
                  <Spin />
                </div>
              )}
              {!isFetching && notificationData?.data?.length === 0 && (
                <NotificationContainer>
                  <StraitsXEmptyData title="No notifications found" subtitle='You’re all caught up!'/>
                </NotificationContainer>
              )}
              {!isFetching && notificationData?.data?.length > 0 && notificationCards}
            </MenuBody>
          </NotificationMenu>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const NotificationInfo = styled.div<{ isNotificationsOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 72px;
  padding-left: 15px;
  padding-right: 15px;
  background: ${props => (props.isNotificationsOpen ? 'rgba(0, 211, 126, 0.1)' : 'transparent')};

  &:hover {
    transition: 0.3s ease-out;
    background: rgba(0, 211, 126, 0.1);
  }
`

const GreyCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
`

const UnreadCount = styled(H4)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  min-width: 24px;
  background: #e22726;
  border: 0px solid #ffffff;
  border-radius: 50%;
  top: 12px;
  left: 32px;
  color: #ffffff;
`

const NotificationMenu = styled.div`
  display: flex;
  flex-direction: column;
  z-index: ${ELEVATIONS.MENUS_AND_SUB_MENUS};
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(149, 149, 149, 0.25);
  border-radius: 5px;
  transition-timing-function: ease;
  font-weight: 500;
  position: absolute;
  top: 0px;
  right: -65px;
  height: 100vh;
  width: 100vw;

  ${DESKTOP_BREAKPOINT} {
    width: 450px;
    right: 0px;
    top: 62px;
    max-height: 650px;
  }
`

const MenuHeader = styled(H3)`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;

  user-select: none;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 20px;
  }
`

const MenuBody = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

const MenuFooter = styled.div`
  width: 100%;
  height: 45px;
  border-top: 1px solid #e8e8e8;
  user-select: none;
`

const NotificationBorder = styled.div`
  width: 100%;
  border-bottom: 0.5px solid #e8e8e8;
  padding: 16px;
  &:hover {
    background: #f6f7f9;
  }

  &:first-of-type {
    border-top: 0.5px solid #e8e8e8;
  }
`

const NotificationBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

const NotificationHeader = styled(P1Bold)`
  margin-bottom: 8px;
`

const NotificationBody = styled(P1)`
  display: -webkit-box;
`

const NotificationTag = styled(SmallP)`
  color: #777777;
`

const NotificationTime = styled(SmallP)`
  color: #777777;
`

const CustomLabel = styled(Label)`
  cursor: pointer;
`

const SelectedLabel = styled(CustomLabel)`
  background-color: #00a965 !important;
  color: #ffffff;
`

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const NotificationHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  align-items: center;
`

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 12px 16px;
`