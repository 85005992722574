import { createSelector } from 'reselect'
import { RootState } from '~/biz/store/types'
import { selectIsFiatWalletOnly } from '~/biz/store/route/selectors'
import { BankAccount, CURRENCY, STABLECOIN } from '~/types'
import { ACCEPTED_CURRENCY } from '~/biz/types'

const defaultCurrency = CURRENCY.XSGD
const defaultFiatCurrency = CURRENCY.SGD

const selectBankAccountStore = (state: RootState) => state.bankAccounts

const selectBankAccountStoreByCurrency = createSelector(
  [selectBankAccountStore],
  bankAccountStore => (stablecoin: STABLECOIN = defaultCurrency) => bankAccountStore[stablecoin]
)

const selectBankAccountListByCurrency = createSelector(
  [selectBankAccountStore, selectIsFiatWalletOnly],
  (bankAccountStore, isFiatWalletOnly) => (
    stablecoin: ACCEPTED_CURRENCY = isFiatWalletOnly ? defaultFiatCurrency : defaultCurrency
  ) => ({
    isBankAccountListInitiated: bankAccountStore[stablecoin]?.isBankAccountListInitiated || false,
    bankAccountList: bankAccountStore[stablecoin]?.bankAccountList || [],
  })
)

const selectBankAccountListForWithdrawal = createSelector(
  [selectBankAccountStore, selectIsFiatWalletOnly],
  (bankAccountStore, isFiatWalletOnly) => (
    stablecoin: ACCEPTED_CURRENCY = isFiatWalletOnly ? defaultFiatCurrency : defaultCurrency
  ) => {
    const isXsgdBankAccountListInitiated =
      bankAccountStore[CURRENCY.XSGD]?.isBankAccountListInitiated || false
    const isUsdcBankAccountListInitiated =
      bankAccountStore[CURRENCY.USDC]?.isBankAccountListInitiated || false
    const isXusdBankAccountListInitiated =
      bankAccountStore[CURRENCY.XUSD]?.isBankAccountListInitiated || false
    const isSgdBankAccountListInitiated =
      bankAccountStore[CURRENCY.SGD]?.isBankAccountListInitiated || false
    const isUsdBankAccountListInitiated =
      bankAccountStore[CURRENCY.USD]?.isBankAccountListInitiated || false
    const XsgdBankAccountList = bankAccountStore[CURRENCY.XSGD]?.bankAccountList || []
    const UsdcBankAccountList = bankAccountStore[CURRENCY.USDC]?.bankAccountList || []
    const XusdBankAccountList = bankAccountStore[CURRENCY.XUSD]?.bankAccountList || []
    const SgdBankAccountList = bankAccountStore[CURRENCY.SGD]?.bankAccountList || []
    const UsdBankAccountList = bankAccountStore[CURRENCY.USD]?.bankAccountList || []
    const bankAccountList = isFiatWalletOnly
      ? [...SgdBankAccountList, ...UsdBankAccountList]
      : [...XsgdBankAccountList, ...UsdcBankAccountList, ...XusdBankAccountList]
    const isBankAccountListInitiated = isFiatWalletOnly
      ? isSgdBankAccountListInitiated && isUsdBankAccountListInitiated
      : isXsgdBankAccountListInitiated && isUsdcBankAccountListInitiated
    // isXusdBankAccountListInitiated
    let withdrawableBankAccountList: BankAccount[] = []

    switch (stablecoin) {
      case CURRENCY.XSGD:
      case CURRENCY.SGD:
        withdrawableBankAccountList = bankAccountList.filter(bankAccount => !!bankAccount.bankId)
        break
      case CURRENCY.USDC:
      case CURRENCY.XUSD:
      case CURRENCY.USD:
        withdrawableBankAccountList = bankAccountList.filter(bankAccount => !!bankAccount.swiftCode)
    }

    return { isBankAccountListInitiated, bankAccountList: withdrawableBankAccountList }
  }
)

const selectBankListByCurrency = createSelector(
  [selectBankAccountStore],
  bankAccountStore => (stablecoin: STABLECOIN = defaultCurrency) => ({
    isBankListInitiated: bankAccountStore[stablecoin]?.isBankListInitiated || false,
    bankList: bankAccountStore[stablecoin]?.bankList || [],
  })
)

const selectIsAllCurrenciesBankAccountListInitiated = createSelector(
  [selectBankAccountStoreByCurrency],
  bankAccountStore => {
    return (
      bankAccountStore(CURRENCY.XSGD).isBankAccountListInitiated &&
      bankAccountStore(CURRENCY.USDC).isBankAccountListInitiated &&
      bankAccountStore(CURRENCY.XUSD).isBankAccountListInitiated
    )
  }
)

const selectAllCurrenciesBankAccountList = createSelector(
  [selectBankAccountStoreByCurrency],
  bankAccountStore => {
    return [
      ...bankAccountStore(CURRENCY.XSGD).bankAccountList,
      ...bankAccountStore(CURRENCY.USDC).bankAccountList,
      ...bankAccountStore(CURRENCY.XUSD).bankAccountList,
    ]
  }
)

export {
  selectBankAccountStoreByCurrency,
  selectBankAccountListByCurrency,
  selectBankAccountListForWithdrawal,
  selectBankListByCurrency,
  selectIsAllCurrenciesBankAccountListInitiated,
  selectAllCurrenciesBankAccountList,
}
