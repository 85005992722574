import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ConfigManager, DEPLOY_ENVIRONMENT, SITE_TYPE } from '~/managers/ConfigManager'
import PageLoader from '~/components/PageLoader'
import appStore from '~/app/store/store'
import xidrAppStore from '~/xidrApp/store/store'
import bizStore from '~/biz/store/store'
import xidrBizStore from '~/xidrBiz/store/store'
import AppRoot from '~/app/Root'
import XIDRAppRoot from '~/xidrApp/Root'
import BizRoot from '~/biz/Root'
import XIDRBizRoot from '~/xidrBiz/Root'
import SSOManager from '~/managers/SSOManager'
import StraitsXTheme from './StraitsXTheme'
import { suppressResizeObserverError } from './common/suppresResizeObserverError'
import { posthogConfig } from './common/constants'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'

ConfigManager.setupGA()
ConfigManager.setupGTM()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

if (ConfigManager.getEnvironmentMode() === DEPLOY_ENVIRONMENT.PRODUCTION) {
  posthog.init(posthogConfig.key, {
    api_host: posthogConfig.apiHost,
  })
}

const App = () => {
  useEffect(() => {
    if (!SSOManager.isLogined()) {
      SSOManager.ssoLogin()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!SSOManager.isLogined()) {
    return <PageLoader />
  }

  useEffect(() => suppressResizeObserverError(), [])

  const rootComponent = (() => {
    switch (ConfigManager.getSiteType()) {
      case SITE_TYPE.APP:
        return ConfigManager.getEnvironmentMode() === DEPLOY_ENVIRONMENT.PRODUCTION ? (
          <PostHogProvider client={posthog}>
            <AppRoot />
          </PostHogProvider>
        ) : (
          <AppRoot />
        )

      case SITE_TYPE.XIDR_APP:
        return <XIDRAppRoot />

      case SITE_TYPE.BIZ:
        return ConfigManager.getEnvironmentMode() === DEPLOY_ENVIRONMENT.PRODUCTION ? (
          <PostHogProvider client={posthog}>
            <BizRoot />
          </PostHogProvider>
        ) : (
          <BizRoot />
        )

      case SITE_TYPE.XIDR_BIZ:
        return <XIDRBizRoot />
    }
    return null
  })()

  if (ConfigManager.isProd()) {
    datadogRum.init({
      applicationId: 'ecbedc13-46c6-4260-990b-3dc5da067af7',
      clientToken: 'pub9eac6d4c217ad300fbe6e154364e35e2',
      site: 'datadoghq.com',
      service: 'straitsx-dashboard',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
  }

  const reduxStore = (() => {
    switch (ConfigManager.getSiteType()) {
      case SITE_TYPE.APP:
        return appStore

      case SITE_TYPE.XIDR_APP:
        return xidrAppStore

      case SITE_TYPE.BIZ:
        return bizStore

      case SITE_TYPE.XIDR_BIZ:
        return xidrBizStore
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any // this for avoid TS to raise type warning
  })()

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={reduxStore}>
        <BrowserRouter>
          <StraitsXTheme>
            <ErrorBoundary>{rootComponent}</ErrorBoundary>
          </StraitsXTheme>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  )
}

export default App
