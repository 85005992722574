/* eslint max-lines: off */
import { Reducer } from 'redux'
import {
  ActionTypes,
  LANDING_PAGE_ACTIONS,
  DepositRequest,
  DashboardBanner,
  TRANSFER_METHOD,
  BankTransferNetwork,
  StablecoinFee,
  WithdrawWalletIdType,
  FiatFee,
} from './types'
import { BlockchainNetwork, Moment, STABLECOIN, BankAccount, CURRENCY } from '~/types'
import { BlockchainAddress } from '~/app/store/blockchainAddresses/types'

const initialState: State = {
  latestPersonalDepositRequests: null,
  limitationIncreaseAmount: null,
  limitationIncreaseDate: null,

  isXsgdErc20DepositEnabled: true,
  isXsgdErc20WithdrawalEnabled: true,
  isXsgdZrc2DepositEnabled: true,
  isXsgdZrc2WithdrawalEnabled: true,
  isXsgdHtsDepositEnabled: false,
  isXsgdHtsWithdrawalEnabled: false,
  isXsgdMaticDepositEnabled: false,
  isXsgdMaticWithdrawalEnabled: false,
  isXsgdAvaxDepositEnabled: false,
  isXsgdAvaxWithdrawalEnabled: false,
  isXsgdArbDepositEnabled: false,
  isXsgdArbWithdrawalEnabled: false,
  isXsgdXrpDepositEnabled: false,
  isXsgdXrpWithdrawalEnabled: false,
  isUsdtTrc20DepositEnabled: false,
  isUsdtTrc20WithdrawalEnabled: false,
  isXusdBep20DepositEnabled: false,
  isXusdBep20WithdrawalEnabled: false,
  isHederaUIEnabled: false,
  isPolygonUIEnabled: false,
  isAvalancheUIEnabled: false,
  isTronUIEnabled: false,
  isBnbsmartUIEnabled: false,
  isArbitrumUIEnabled: false,
  isRippleUIEnabled: false,
  isUsdcMaticDepositEnabled: false,
  isUsdcMaticWithdrawalEnabled: false,

  isDashboardBannerManagementInitiated: false,
  isDashboardBannerManagementEnabled: false,
  isDptApplied: false,
  isDptRiskModalEnabled: false,
  isFazzVaForDashboardEnabled: false,
  isFazzVaForDirectMintEnabled: false,
  isTravelRuleDepositDeclarationEnabled: false,
  isTravelRuleAddrWhitelistingEnabled: false,
  dashboardBanners: [],

  tab: 'deposit',
  transferMethod: null,
  bankTransferNetwork: null,
  selectedCurrency: null,
  selectedBlockchain: null,
  selectedBankAccount: null,
  selectedBlockchainAddress: null,

  withdrawAmount: '',
  withdrawAmountError: '',
  withdrawRequestError: '',
  withdrawWalletId: undefined,

  isStablecoinFeeLoading: false,
  stablecoinFee: {
    amount: undefined,
    identifier: undefined,
    timestamp: undefined,
  },
  fiatFee: {
    [CURRENCY.XSGD]: {
      amount: undefined,
      feeType: undefined,
      rate: undefined,
      maxAmount: undefined,
      minAmount: undefined,
    },
    [CURRENCY.XUSD]: {
      amount: undefined,
      feeType: undefined,
      rate: undefined,
      maxAmount: undefined,
      minAmount: undefined,
    },
    [CURRENCY.USDC]: {
      amount: undefined,
      feeType: undefined,
      rate: undefined,
      maxAmount: undefined,
      minAmount: undefined,
    },
    [CURRENCY.USDT]: {
      amount: undefined,
      feeType: undefined,
      rate: undefined,
      maxAmount: undefined,
      minAmount: undefined,
    },
  },
  hasFetchingFeeError: false,
  isHtsWithdrawalFeeCharging: false,
  isConfirmOpen: false,
  is2FaOpen: false,
  isTemporaryHoldingBalanceOpen: false,
  isXusdCoachmarkActive: false,
  isDormantBannerCoachmarkActive: false,
  visitedGettingStarted: false,
  isTemporaryHoldingBalanceWithdrawal: false,
  isPeriodicReviewRequired: false,
  isSsoTwoFaAuthenticatorEnabled: false,
  isRenewAddressAcknowledged: false,
  activationChecklistClickedElement: null,
}

type State = {
  latestPersonalDepositRequests: DepositRequest[] | null
  limitationIncreaseAmount: number | null
  limitationIncreaseDate: Moment | null
  isDormantBannerCoachmarkActive: boolean
  isXsgdErc20DepositEnabled: boolean
  isXsgdErc20WithdrawalEnabled: boolean
  isXsgdZrc2DepositEnabled: boolean
  isXsgdZrc2WithdrawalEnabled: boolean
  isXsgdHtsDepositEnabled: boolean
  isXsgdHtsWithdrawalEnabled: boolean
  isXsgdMaticDepositEnabled: boolean
  isXsgdMaticWithdrawalEnabled: boolean
  isXsgdAvaxDepositEnabled: boolean
  isXsgdAvaxWithdrawalEnabled: boolean
  isXsgdArbDepositEnabled: boolean
  isXsgdArbWithdrawalEnabled: boolean
  isXsgdXrpDepositEnabled: boolean
  isXsgdXrpWithdrawalEnabled: boolean
  isUsdtTrc20DepositEnabled: boolean
  isUsdtTrc20WithdrawalEnabled: boolean
  isXusdBep20DepositEnabled: boolean
  isXusdBep20WithdrawalEnabled: boolean
  isHederaUIEnabled: boolean
  isPolygonUIEnabled: boolean
  isAvalancheUIEnabled: boolean
  isTronUIEnabled: boolean
  isBnbsmartUIEnabled: boolean
  isArbitrumUIEnabled: boolean
  isRippleUIEnabled: boolean
  isUsdcMaticDepositEnabled: boolean
  isUsdcMaticWithdrawalEnabled: boolean

  isDashboardBannerManagementEnabled: boolean
  isDashboardBannerManagementInitiated: boolean
  isDptApplied: boolean
  isDptRiskModalEnabled: boolean
  isFazzVaForDashboardEnabled: boolean
  isFazzVaForDirectMintEnabled: boolean
  isTravelRuleDepositDeclarationEnabled: boolean
  isTravelRuleAddrWhitelistingEnabled: boolean
  dashboardBanners: DashboardBanner[]

  isXusdCoachmarkActive: boolean

  tab: string
  transferMethod: TRANSFER_METHOD | null
  bankTransferNetwork: BankTransferNetwork
  selectedCurrency: STABLECOIN | null
  selectedBlockchain: BlockchainNetwork | null
  selectedBankAccount: BankAccount | null
  selectedBlockchainAddress: BlockchainAddress | null

  withdrawAmount: string
  withdrawAmountError: string
  withdrawRequestError: string
  withdrawWalletId: WithdrawWalletIdType

  isStablecoinFeeLoading: boolean
  stablecoinFee: StablecoinFee
  fiatFee: {
    [key in STABLECOIN]: FiatFee
  }
  hasFetchingFeeError: boolean
  isHtsWithdrawalFeeCharging: boolean
  isConfirmOpen: boolean
  is2FaOpen: boolean
  isTemporaryHoldingBalanceOpen: boolean

  visitedGettingStarted: boolean
  isTemporaryHoldingBalanceWithdrawal: boolean
  isPeriodicReviewRequired: boolean
  isSsoTwoFaAuthenticatorEnabled: boolean
  isRenewAddressAcknowledged: boolean
  activationChecklistClickedElement?: string
}

export const landingPage: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED: {
      return {
        ...state,
        isXsgdZrc2DepositEnabled: action.isXsgdZrc2DepositEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED: {
      return {
        ...state,
        isXsgdErc20DepositEnabled: action.isXsgdErc20DepositEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_HTS_DEPOSIT_ENABLED: {
      return {
        ...state,
        isXsgdHtsDepositEnabled: action.isXsgdHtsDepositEnabled,
      }
    }
    case LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED: {
      return {
        ...state,
        isXsgdMaticDepositEnabled: action.isXsgdMaticDepositEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_AVAX_DEPOSIT_ENABLED: {
      return {
        ...state,
        isXsgdAvaxDepositEnabled: action.isXsgdAvaxDepositEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_ARB_DEPOSIT_ENABLED: {
      return {
        ...state,
        isXsgdArbDepositEnabled: action.isXsgdArbDepositEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_XRP_DEPOSIT_ENABLED: {
      return {
        ...state,
        isXsgdXrpDepositEnabled: action.isXsgdXrpDepositEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_TRC20_DEPOSIT_ENABLED: {
      return {
        ...state,
        isUsdtTrc20DepositEnabled: action.isUsdtTrc20DepositEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_BEP20_DEPOSIT_ENABLED: {
      return {
        ...state,
        isXusdBep20DepositEnabled: action.isXusdBep20DepositEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isXsgdZrc2WithdrawalEnabled: action.isXsgdZrc2WithdrawalEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isXsgdErc20WithdrawalEnabled: action.isXsgdErc20WithdrawalEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isXsgdHtsWithdrawalEnabled: action.isXsgdHtsWithdrawalEnabled,
      }
    }
    case LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isXsgdMaticWithdrawalEnabled: action.isXsgdMaticWithdrawalEnabled,
      }
    }
    case LANDING_PAGE_ACTIONS.SET_IS_AVAX_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isXsgdAvaxWithdrawalEnabled: action.isXsgdAvaxWithdrawalEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_ARB_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isXsgdArbWithdrawalEnabled: action.isXsgdArbWithdrawalEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_XRP_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isXsgdXrpWithdrawalEnabled: action.isXsgdXrpWithdrawalEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_TRC20_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isUsdtTrc20WithdrawalEnabled: action.isUsdtTrc20WithdrawalEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_BEP20_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isXusdBep20WithdrawalEnabled: action.isXusdBep20WithdrawalEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_USDC_MATIC_DEPOSIT_ENABLED: {
      return {
        ...state,
        isUsdcMaticDepositEnabled: action.isUsdcMaticDepositEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_USDC_MATIC_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isUsdcMaticWithdrawalEnabled: action.isUsdcMaticWithdrawalEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE: {
      return {
        ...state,
        temporaryHoldingBalance: action.temporaryHoldingBalance,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_LATEST_PERSONAL_DEPOSIT_REQUESTS: {
      return {
        ...state,
        latestPersonalDepositRequests: action.latestPersonalDepositRequests,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED: {
      return {
        ...state,
        isDashboardBannerManagementEnabled: action.isDashboardBannerManagementEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_DASHBOARD_BANNERS: {
      return {
        ...state,
        dashboardBanners: action.dashboardBanners,
        isDashboardBannerManagementInitiated: true,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_TAB: {
      return {
        ...state,
        tab: action.tab,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD: {
      return {
        ...state,
        transferMethod: action.transferMethod,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK: {
      return {
        ...state,
        bankTransferNetwork: action.bankTransferNetwork,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_SELECTED_CURRENCY: {
      return {
        ...state,
        selectedCurrency: action.selectedCurrency,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN: {
      return {
        ...state,
        selectedBlockchain: action.selectedBlockchain,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT: {
      return {
        ...state,
        selectedBankAccount: action.selectedBankAccount,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS: {
      return {
        ...state,
        selectedBlockchainAddress: action.selectedBlockchainAddress,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT: {
      return {
        ...state,
        withdrawAmount: action.amount,
        withdrawAmountError: action.withdrawAmountError,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID: {
      return {
        ...state,
        withdrawWalletId: action.withdrawWalletId,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING: {
      return {
        ...state,
        isStablecoinFeeLoading: action.isStablecoinFeeLoading,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE: {
      return {
        ...state,
        isStablecoinFeeLoading: false,
        stablecoinFee: {
          amount: action.amount,
          identifier: action.identifier,
          timestamp: action.timestamp,
        },
      }
    }

    case LANDING_PAGE_ACTIONS.SET_FIAT_FEE: {
      return {
        ...state,
        fiatFee: {
          ...state.fiatFee,
          [action.stablecoin]: {
            amount: action.amount,
            feeType: action.feeType,
            rate: action.rate,
            maxAmount: action.maxAmount,
            minAmount: action.minAmount,
          },
        },
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_FEE_CHARGING: {
      return {
        ...state,
        isHtsWithdrawalFeeCharging: action.isHtsWithdrawalFeeCharging,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN: {
      return {
        ...state,
        isConfirmOpen: action.open,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN: {
      return {
        ...state,
        is2FaOpen: action.open,
      }
    }

    case LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST: {
      return {
        ...state,
        withdrawRequestError: action.error,
        isLoading: false,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE_MODAL_OPEN: {
      return {
        ...state,
        isTemporaryHoldingBalanceOpen: action.open,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED: {
      return {
        ...state,
        visitedGettingStarted: action.value,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_HEDERA_UI_ENABLED: {
      return {
        ...state,
        isHederaUIEnabled: action.isHederaUIEnabled,
      }
    }
    case LANDING_PAGE_ACTIONS.SET_IS_POLYGON_UI_ENABLED: {
      return {
        ...state,
        isPolygonUIEnabled: action.isPolygonUIEnabled,
      }
    }
    case LANDING_PAGE_ACTIONS.SET_IS_AVALANCHE_UI_ENABLED: {
      return {
        ...state,
        isAvalancheUIEnabled: action.isAvalancheUIEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_TRON_UI_ENABLED: {
      return {
        ...state,
        isTronUIEnabled: action.isTronUIEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_BNBSMART_UI_ENABLED: {
      return {
        ...state,
        isBnbsmartUIEnabled: action.isBnbsmartUIEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_ARBITRUM_UI_ENABLED: {
      return {
        ...state,
        isArbitrumUIEnabled: action.isArbitrumUIEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_RIPPLE_UI_ENABLED: {
      return {
        ...state,
        isRippleUIEnabled: action.isRippleUIEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_TEMPORARY_HOLDING_BALANCE_WITHDRAWAL: {
      return {
        ...state,
        isTemporaryHoldingBalanceWithdrawal: action.isTemporaryHoldingBalanceWithdrawal,
      }
    }
    case LANDING_PAGE_ACTIONS.SET_IS_PERIODIC_REVIEW_REQUIRED: {
      return {
        ...state,
        isPeriodicReviewRequired: action.isPeriodicReviewRequired,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR: {
      return {
        ...state,
        hasFetchingFeeError: action.hasError,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_DPT_APPLIED: {
      return {
        ...state,
        isDptApplied: action.isDptApplied,
      }
    }
    case LANDING_PAGE_ACTIONS.SET_IS_DPT_RISK_MODAL_ENABLED: {
      return {
        ...state,
        isDptRiskModalEnabled: action.isDptRiskModalEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_FAZZ_VA_FOR_DASHBOARD_ENABLED: {
      return {
        ...state,
        isFazzVaForDashboardEnabled: action.isFazzVaForDashboardEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_FAZZ_VA_FOR_DIRECT_MINT_ENABLED: {
      return {
        ...state,
        isFazzVaForDirectMintEnabled: action.isFazzVaForDirectMintEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_SSO_TWO_FA_AUTHENTICATOR_ENABLED: {
      return {
        ...state,
        isSsoTwoFaAuthenticatorEnabled: action.isSsoTwoFaAuthenticatorEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_DEPOSIT_DECLARATION_ENABLED: {
      return {
        ...state,
        isTravelRuleDepositDeclarationEnabled: action.isTravelRuleDepositDeclarationEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_ADDR_WHITELISTING_ENABLED: {
      return {
        ...state,
        isTravelRuleAddrWhitelistingEnabled: action.isTravelRuleAddrWhitelistingEnabled,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED: {
      return {
        ...state,
        isRenewAddressAcknowledged: action.isRenewAddressAcknowledged,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_XUSD_COACHMARK_ACTIVE: {
      return {
        ...state,
        isXusdCoachmarkActive: action.isXusdCoachmarkActive,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_IS_DORMANT_BANNER_COACHMARK_ACTIVE: {
      return {
        ...state,
        isDormantBannerCoachmarkActive: action.isDormantBannerCoachmarkActive,
      }
    }

    case LANDING_PAGE_ACTIONS.SET_ACTIVATION_CHECKLIST_CLICKED_ELEMENT: {
      return {
        ...state,
        activationChecklistClickedElement: action.activationChecklistClickedElement,
      }
    }
    default:
      return state
  }
}
