// IMPORTANT: refer and update this before / after making any changes https://www.notion.so/fazzfinancialgroup/Automated-Clickable-Element-ID-19a5d9f672cf80cd8ebedf7fd590f0dc
// WARNING: changes here would impact our analytic dashboard on posthog, please consult with BI team before doing any changes

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { SITE_TYPE } from '~/managers/ConfigManager'

export const useAutoAddPosthogIds = (siteType: SITE_TYPE) => {
  const location = useLocation()

  useEffect(() => {
    const addIdToClickableElements = () => {
      const pathName = location.pathname.replace(/\W/g, '-').replace(/^-+|-+$/g, '')
      const seenLabels: Record<string, number> = {}

      document
        .querySelectorAll(
          "button, a, [role='button'], [onclick], [role='tab'], .ant-input,  .ant-tabs-tab, .ant-select, .ant-dropdown-menu-item"
        )
        .forEach(el => {
          if (el.id) {
            return
          }

          let type = el.tagName.toLowerCase() || 'unknown'
          if (type === 'a') type = 'link'
          let label =
            type === 'input'
              ? el.getAttribute('placeholder') || 'unnamed'
              : el.getAttribute('aria-label') ||
                el.getAttribute('data-dd-action-name') ||
                el.textContent
                  ?.trim()
                  .toLowerCase()
                  .replace(/\W+/g, '-') ||
                'unnamed'

          seenLabels[label] = (seenLabels[label] || 0) + 1
          const count = seenLabels[label]

          const stableHash = btoa(`${siteType}-${pathName}-${type}-${label}`).slice(-6)

          el.id = `${siteType}-${pathName || 'root'}-${type}-${label}-${count}-${stableHash}`
        })
    }

    addIdToClickableElements()

    const observer = new MutationObserver(() => {
      addIdToClickableElements()
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [location.pathname])
}
