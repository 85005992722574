export enum PATHS {
  ACCOUNT_BALANCE = '/account_balance',
  TRANSACTIONS = '/transactions',
  SWAP_TRANSACTIONS = '/transactions?type=swap',
  OTC_TRANSACTIONS = '/transactions?type=otc',
  ACTION_NEEDED_TRANSACTIONS = '/transactions?subType=action_needed',
  DISBURSEMENTS = '/disbursements',
  DISBURSEMENTS_RECIPIENTS_LIST = '/disbursements/recipients_list',
  DISBURSEMENTS_PAYMENT_METHOD = '/disbursements/payment_method',
  DISBURSEMENTS_COMPLETE = '/disbursements/complete',
  TRANSACTION_DETAILS = '/transactions/:transactionId',
  ACCOUNT_STATEMENT = '/account_statement',
  TRANSFER_IN = '/transfer_in',
  DEPOSIT_REQUESTS = '/deposit/requests',
  SIMULATE_DEPOSIT = '/simulate_deposit',
  TWO_FA_SETTING = '/two_fa_setting',
  WITHDRAW = '/withdraw',
  TRANSFER_OUT = '/transfer_out',
  MANAGE_ACCOUNT = '/manage_account',
  IDENTIFICATION = '/identification/:unlockFeature(withdraw|deposit|disbursements)/:nricVerified?',
  INVOICING = '/payment_link',
  INVOICING_DETAILS = '/payment_link/:uniqueId',
  CREDIT_CARDS = '/credit_cards',
  CREDIT_CARDS_PAYMENTS = '/credit_cards/payments',
  CREDIT_CARDS_DISPUTES = '/credit_cards/disputes',
  CREDIT_CARDS_PAYMENTS_DETAILS = '/credit_cards_details/payments/:paymentId',
  CREDIT_CARDS_DISPUTES_DETAILS = '/credit_cards_details/disputes/:paymentId',
  CREDIT_CARDS_SUBMIT_EVIDENCE = '/credit_cards_action/submit_evidence/:paymentId',
  VERIFICATION = '/verification',
  CLAIM_KYC = '/claim_kyc',
  VERIFICATION_MY_INFO_CALLBACK = '/callback/:myInfoParams',
  INTERNAL_TOOLS = '/internal_tools',
  BIZ_KYC_INFORMATION = '/verification/business/information',
  BIZ_KYC_INTENDED_RELATIONS = '/verification/business/intended_relations',
  BIZ_KYC_BUSINESS_ACTIVITIES = '/verification/business/business_activities',
  BIZ_KYC_TAX_RESIDENCY = '/verification/business/tax_residency',
  BIZ_KYC_UPLOAD_DOCUMENTS = '/verification/business/upload_documents',
  BIZ_KYC_ACCOUNT_MANAGER = '/verification/business/account_manager',
  BIZ_KYC_DIRECTORS_AND_BO = '/verification/business/directors_and_bo',
  BIZ_KYC_REVIEW = '/verification/business/review',
  BIZ_KYC_COMPLETE = '/verification/business/complete',
  USERS = '/users',
  USER_DETAILS = '/user_details/:nik',
  DEVELOPER_TOOLS = '/developer_tools',
  TEAM = '/team',
  DASHBOARD = '/dashboard',
  EARN = '/earn',
  EARN_INTEREST_SUBMITTED = '/earn/interest_submitted',
  EARN_TRANSFER_REQUEST_SUBMITTED = '/earn/transfer_request_submitted',
  EARN_TRANSACTION_DETAILS = '/earn/transactions/:transactionId',
  ERROR = '/error/:code',
  ERROR_403 = '/error/403',
  XFERS_ACCEPT = '/xfers_accept',
  XFERS_ACCEPT_DETAILS = '/xfers_accept/:transactionId',
  XFERS_SEND = '/xfers_send',
  XFERS_SEND_DETAILS = '/xfers_send/:transactionId',
  XFERS_DASH = '/xfers_dash',
  XFERS_DASH_DETAILS = '/xfers_dash/:transactionId',
  LANDING_PAGE = '/',
  GET_STARTED = '/get_started',
  USER_CARDS = '/user_cards',
}
