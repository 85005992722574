import { CURRENCY, FIAT, STABLECOIN } from '~/types'

export const fiatStablecoinConverter = {
  [CURRENCY.SGD]: CURRENCY.XSGD,
  [CURRENCY.XSGD]: CURRENCY.SGD,
  [CURRENCY.IDR]: CURRENCY.XIDR,
  [CURRENCY.XIDR]: CURRENCY.IDR,
  [CURRENCY.USD]: CURRENCY.USDC,
  [CURRENCY.USDC]: CURRENCY.USD,
  [CURRENCY.USDT]: CURRENCY.USD,
  [CURRENCY.XUSD]: CURRENCY.USD,
}

export const fiatToStablecoinMap: Record<FIAT, STABLECOIN> = {
  [CURRENCY.SGD]: CURRENCY.XSGD,
  [CURRENCY.USD]: CURRENCY.USDC,
}

/**
 * Checks whether a given currency is Fiat or not
 * @returns boolean
 */
export function isFiatCurrency(currency: CURRENCY): boolean {
  return [CURRENCY.SGD, CURRENCY.USD].includes(currency)
}
