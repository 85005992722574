/* eslint-disable max-lines, react/jsx-props-no-spreading */
import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT, useTheme, Card } from '@xfers/design-system'
import { datadogRum } from '@datadog/browser-rum'
import straitsXBackground from '~/assets/straitsXBackground.png'
import favicon from '~/assets/straitsXFavicon.png'
import { XIDRInit } from '~/xidrBiz/store/route/actions'
import RouteHooks from './RouteHooks'
import routes from '~/xidrBiz/routes/config'
import { PrivateRoute } from '~/components/Route'
import StraitsXFooter from '~/components/StraitsXFooter'
import StraitsXNavigation from '~/components/StraitsXNavigation'
import StraitsXUserInfoBar from '~/components/StraitsXUserInfoBar'
import { RootState } from '~/xidrBiz/store/types'
import { selectFeatureMatrixFactors } from '~/xidrBiz/store/route/selectors'
import {
  setIsFlipperDeveloperToolEnabled,
  setIsFlipperSandboxEnabled,
  resetApiToken,
} from '~/xidrBiz/store/developerTools/actions'
import FeatureGatingManager from '~/managers/FeatureGatingManager'
import { fetchUserInfo, fetchBankList } from '~/xidrBiz/store/userInfo/actions'
import { ModeManager } from '~/managers/ModeManager'
import SandboxBanner from '~/components/SandBoxBanner'
import {
  getUserInfo,
  getBusinessAccountInfo,
  setIsMaticEnabled,
  setIsXIDRWithdrawalFeeEnabled,
  setIsZrc2DepositEnabled,
  setIsErc20DepositEnabled,
  setIsErc20WithdrawalEnabled,
  setIsZrc2WithdrawalEnabled,
  setIsMaticDepositEnabled,
  setIsMaticWithdrawalEnabled,
  setIsVirtualAccountEnabled,
  setIsPaymentMethodRetailEnabled,
  setIsAlfamartDepositEnabled,
  setIsIndomaretDepositEnabled,
  setIsXidrCleanupFeaturesEnabled,
  setIsIdTransferOutEnabled,
} from '~/xidrBiz/store/landingPage/actions'
import { fetchAccountList } from '~/xidrBiz/store/account/actions'
import { useXidrBizFlippers } from '~/store/flippers/hooks'
import { ConfigManager } from '~/managers/ConfigManager'

export default () => {
  const dispatch = useDispatch()

  const isSandbox = ModeManager.isSandboxMode()

  const { user } = useSelector((state: RootState) => state.route)

  const { isTourRunning } = useSelector((state: RootState) => state.xidrTour)

  const { isFlipperDevToolsOn, isFlipperSandboxOn } = useSelector(
    (state: RootState) => state.xidrDeveloperTools
  )

  const {
    personalVerificationStatus,
    businessVerificationStatus,
    blockchainAddresses: xidrBlockchainAddresses,
    bankAccounts: xidrBankAccounts,
  } = useSelector((state: RootState) => state.xidrLandingPage)

  const { isDeveloperToolEnabled, isSandboxEnabled: displayModeSwitcher } = useSelector(
    selectFeatureMatrixFactors
  )

  const { isUserInfoInitiated, userInfo } = useSelector((state: RootState) => state.xidrUserInfo)

  const [displayGetStartedTab, setDisplayGetStarted] = useState(false)

  useEffect(() => {
    if (isSandbox) {
      setDisplayGetStarted(false)
      return
    }

    if (
      (xidrBlockchainAddresses && xidrBlockchainAddresses.length === 0) ||
      (xidrBankAccounts && xidrBankAccounts?.length === 0) ||
      (personalVerificationStatus && personalVerificationStatus !== 'verified') ||
      (businessVerificationStatus && businessVerificationStatus !== 'verified')
    ) {
      setDisplayGetStarted(true)
    }
  }, [
    isSandbox,
    personalVerificationStatus,
    businessVerificationStatus,
    xidrBankAccounts,
    xidrBlockchainAddresses,
  ])

  useEffect(() => {
    if (!isUserInfoInitiated) {
      dispatch(fetchUserInfo())
    }
  }, [dispatch, isUserInfoInitiated])

  const threeConsecutiveDigits = /\d{3}/
  const desktopBreakpointWidth = threeConsecutiveDigits.exec(DESKTOP_BREAKPOINT)?.[0] || 0
  // required for tour to work properly; prevents rendering antd Menu component twice
  const shouldRenderDesktopViewNavbar =
    document.documentElement.clientWidth >= desktopBreakpointWidth || !isTourRunning

  useEffect(() => {
    dispatch(getUserInfo())
    dispatch(fetchAccountList())
    dispatch(getBusinessAccountInfo())
    dispatch(resetApiToken())
    dispatch(fetchBankList({ isVASupported: false }))
  }, [isSandbox])
  useEffect(() => {
    dispatch(XIDRInit())

    const faviconEl: HTMLLinkElement | null = document.querySelector("link[rel='shortcut icon']")
    if (faviconEl) {
      faviconEl.href = favicon
    }

    FeatureGatingManager.isEnabled('xidr_developer_tools').then(resp => {
      dispatch(setIsFlipperDeveloperToolEnabled(resp))
    })
  }, []) // Run init just once

  useEffect(() => {
    if (userInfo?.email) {
      FeatureGatingManager.isEnabled(
        [
          'xidr_sandbox',
          'xidr_erc20_deposit',
          'xidr_erc20_withdrawal',
          'xidr_zrc2_deposit',
          'xidr_zrc2_withdrawal',
          'xidr_matic_deposit',
          'xidr_matic_withdrawal',
          'xidr_support_polygon',
          'xidr_withdrawal_fee',
          'xidr_virtual_account_ui',
          'xidr_payment_method_retail',
          'xidr_payment_method_retail_alfamart',
          'xidr_payment_method_retail_indomaret',
          'straitsx_xidr_cleanup_features_enabled',
          'straitsx_id_transfer_out_enabled',
        ],
        userInfo?.email
      ).then(resp => {
        dispatch(setIsFlipperSandboxEnabled(resp.xidr_sandbox))
        dispatch(setIsErc20DepositEnabled(resp.xidr_erc20_deposit))
        dispatch(setIsErc20WithdrawalEnabled(resp.xidr_erc20_withdrawal))
        dispatch(setIsZrc2DepositEnabled(resp.xidr_zrc2_deposit))
        dispatch(setIsZrc2WithdrawalEnabled(resp.xidr_zrc2_withdrawal))
        dispatch(setIsMaticDepositEnabled(resp.xidr_matic_deposit))
        dispatch(setIsMaticWithdrawalEnabled(resp.xidr_matic_withdrawal))
        dispatch(setIsMaticEnabled(resp.xidr_support_polygon))
        dispatch(setIsXIDRWithdrawalFeeEnabled(resp.xidr_withdrawal_fee))
        dispatch(setIsVirtualAccountEnabled(resp.xidr_virtual_account_ui))
        dispatch(setIsPaymentMethodRetailEnabled(resp.xidr_payment_method_retail))
        dispatch(setIsAlfamartDepositEnabled(resp.xidr_payment_method_retail_alfamart))
        dispatch(setIsIndomaretDepositEnabled(resp.xidr_payment_method_retail_indomaret))
        dispatch(setIsXidrCleanupFeaturesEnabled(resp.straitsx_xidr_cleanup_features_enabled))
        dispatch(setIsIdTransferOutEnabled(resp.straitsx_id_transfer_out_enabled))
      })
    }
  }, [isUserInfoInitiated])

  useXidrBizFlippers({ email: user?.email })

  const displayDevToolTab = isSandbox || (isDeveloperToolEnabled && !!isFlipperDevToolsOn)

  // on sandbox<->production mode switched
  const onModeSwitched = () => window.location.reload()

  useEffect(() => {
    if (ConfigManager.isProd() && user?.email && user?.kcId) {
      datadogRum.setUser({
        email: user.email,
        kcId: user.kcId,
      })
    }
  }, [user])

  return (
    <Root id="xidr-biz-root">
      <RouteHooks />
      <Container newLayout={true}>
        <NavigationWrapper newLayout={true}>
          {shouldRenderDesktopViewNavbar && (
            <StraitsXNavigation
              displayGetStartedTab={displayGetStartedTab}
              displayMintTab={false}
              displayDevToolTab={displayDevToolTab}
              isSandbox={isSandbox}
            />
          )}
        </NavigationWrapper>

        <MainContainer isSandbox={isSandbox}>
          {isSandbox && <SandboxBanner />}
          <StraitsXUserInfoBar
            userInfo={user}
            isSandbox={isSandbox}
            isBusinessAccount={true}
            displayModeSwitcher={displayModeSwitcher && isFlipperSandboxOn}
            displayGetStartedTab={displayGetStartedTab}
            displayMintTab={false}
            displayDevToolTab={displayDevToolTab}
            onChangeModeCallback={onModeSwitched}
          />

          <Suspense fallback={<LoadingPage />}>
            <Switch>
              {routes.map((route, i) => (
                <PrivateRoute key={i} {...route} />
              ))}
            </Switch>
          </Suspense>

          <StraitsXFooter />
        </MainContainer>
      </Container>
    </Root>
  )
}

function LoadingPage() {
  const { spacing } = useTheme()

  return (
    <Page>
      <Card style={{ padding: spacing.xl }}>
        <Skeleton style={{ width: '40%' }} />
        <Skeleton />
        <Skeleton />
      </Card>
    </Page>
  )
}

const Page = styled.div`
  padding: 16px;

  ${DESKTOP_BREAKPOINT} {
    padding: 24px;
  }
`

const Root = styled.div`
  z-index: 0;
`

const Container = styled.div`
  ${(props: { newLayout: boolean }) =>
    props.newLayout &&
    `
      background-image: url(${straitsXBackground});
      background-repeat: no-repeat;
      background-size: 100%;
    `}
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  display: flex;
`

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 73px;
  width: 100%;

  ${DESKTOP_BREAKPOINT} {
    padding-top: ${(props: { isSandbox: boolean }) => (props.isSandbox ? '73px' : 0)};
  }
`

const NavigationWrapper = styled.div`
  ${(props: { newLayout: boolean }) =>
    props.newLayout &&
    `
      z-index: 1;
    `}
  display: none;

  ${DESKTOP_BREAKPOINT} {
    display: flex;
    width: 257px;
    flex-shrink: 0;
  }
`

const Skeleton = styled.div`
  float: left;
  width: 100%;
  height: 16px;

  margin: 10px;
  border-radius: 5px;
  background-size: 100wh;
  background-image: linear-gradient(90deg, #e8e8e8 0%, #f6f7f9 50%, #e8e8e8 100%);
  animation: shine-lines 1s infinite linear;

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 140px;
    }
  }
`
