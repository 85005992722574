import { Dispatch } from '~/app/store/types'
import { ActionTypes, Credentials, Notification, ROUTE_ACTIONS } from './types'
import { PATHS } from '~/app/routes/paths'
import { get, post, put, ENDPOINTS } from '~/api'
import { coolingDownThresholdInMinutes } from '~/common/constants'

export const redirect = (to: PATHS) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_REDIRECT,
    to,
  })
}

export const resetRedirect = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_REDIRECT,
    to: null,
  })
}

export const init = () => (dispatch: Dispatch<ActionTypes>) => {
  Promise.all([
    get(ENDPOINTS.API_SSO_PROFILE),
    get(ENDPOINTS.API_V3_USER_PROFILE),
    get(ENDPOINTS.API_V3_USER),
    get(ENDPOINTS.API_SSO_TWO_FA),
    get(ENDPOINTS.API_SSO_CREDENTIALS),
    get(ENDPOINTS.API_V3_USER_ACKNOWLEDGEMENT),
    get(ENDPOINTS.API_SSO_ACCOUNT_STATUS),
  ]).then(
    ([
      ssoProfileResp,
      userProfileResp,
      userResp,
      twoFaResp,
      ssoCredentials,
      userAck,
      accountStatus,
    ]) => {
      const isWithin12Hours = (utcTimeStamp: number) => {
        const currentTime = new Date().getTime()

        const twelveHoursInMillis = coolingDownThresholdInMinutes * 60 * 1000
        const twelveHoursFromTimestamp = Number(utcTimeStamp) + twelveHoursInMillis

        const remainingTimeInMillis = twelveHoursFromTimestamp - currentTime

        const isWithin12Hours = remainingTimeInMillis > 0

        return {
          isWithin12Hours,
        }
      }
      const res = isWithin12Hours(ssoProfileResp.two_fa_setup_timestamp)

      const credentialsPassword = ssoCredentials.find(
        (credentials: Credentials) => credentials.type === 'password'
      )

      const bannerDismissalState = userAck.reduce((acc, ack) => {
        acc[ack.ack_type] = ack.is_dont_show_again
        return acc
      }, {})

      const { userCredentialMetadatas } = credentialsPassword

      if (userCredentialMetadatas) {
        dispatch({
          type: ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD,
          isUserHasPassword: userCredentialMetadatas.length > 0,
        })
      }

      if (accountStatus) {
        dispatch({
          type: ROUTE_ACTIONS.SET_IS_ACCOUNT_ENABLED,
          isAccountEnabled: accountStatus.enabled,
        })
      }

      dispatch({
        type: ROUTE_ACTIONS.SET_USER_PROFILE,
        user: {
          bannerDismissalState,
          kcId: ssoProfileResp.kc_id,
          fullName: userProfileResp.full_name,
          email: ssoProfileResp.email,
          mobileNumber: ssoProfileResp.mobile_number,
          countryCode: ssoProfileResp.country_code,
          avatarUrl: userProfileResp.avatar_url,
          displayname: userProfileResp.displayname,
          authServerUrl: ssoProfileResp.auth_server_url,
          twoFa: {
            twoFaSetupTime: ssoProfileResp.two_fa_setup_timestamp,
            isOnCoolingDownPeriod: res.isWithin12Hours,
            twoFaList: twoFaResp[0]?.userCredentialMetadatas || [],
          },
          isBitgoAddressAcknowledged: userResp.bitgo_acknowledged,
        },
      })
    }
  )
  // fetching notifications in the init function, it only needs to be fetched once at the beginning of the session and stored in redux
  dispatch(fetchNotifications())
}

export const getTwoFaStatus = () => async (dispatch: Dispatch<ActionTypes>, getState) => {
  const twoFaResp = await get(ENDPOINTS.API_SSO_TWO_FA)
  const isWithin12Hours = (utcTimeStamp: number) => {
    const currentTime = new Date().getTime()

    const twelveHoursInMillis = coolingDownThresholdInMinutes * 60 * 1000
    const twelveHoursFromTimestamp = Number(utcTimeStamp) + twelveHoursInMillis

    const remainingTimeInMillis = twelveHoursFromTimestamp - currentTime

    const isWithin12Hours = remainingTimeInMillis > 0

    return {
      isWithin12Hours,
    }
  }

  const res = isWithin12Hours(getState().route.user.twoFa.twoFaSetupTime)

  return dispatch({
    type: ROUTE_ACTIONS.SET_USER_PROFILE,
    user: {
      ...getState().route.user,
      twoFa: {
        ...getState().route.user.twoFa,
        isOnCoolingDownPeriod: res.isWithin12Hours,
        twoFaList: twoFaResp[0]?.userCredentialMetadatas || [],
      },
    },
  })
}

export const updateUserProfile = ({
  deleteAvatar,
  displayImage,
}: {
  deleteAvatar?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayImage?: { fileData?: any; fileName?: any }
}) => (dispatch: Dispatch<ActionTypes>) => {
  put(ENDPOINTS.API_V3_UPDATE_USER_PROFILE, {
    delete_avatar: deleteAvatar,
    display_image: displayImage
      ? {
          file_data: displayImage.fileData,
          file_name: displayImage.fileName,
        }
      : null,
  })
    .then(resp => {
      dispatch({
        type: ROUTE_ACTIONS.UPDATE_USER_PROFILE,
        avatarUrl: resp.display_image.file_data,
      })
    })
    .catch(() => {
      // alert('error')
    })
}

export const fetchNotifications = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING,
    isLoading: true,
  })

  post(ENDPOINTS.API_NOTIFICATION, { site: 'app' }).then(resp => {
    const unreadCount = resp.total_unread

    dispatch({
      type: ROUTE_ACTIONS.SET_NOTIFICATIONS,
      data: resp.data,
      unreadCount,
      isLoading: false,
    })
  })
}

export const fetchAccountStatus = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_SSO_ACCOUNT_STATUS).then(resp => {
    dispatch({
      type: ROUTE_ACTIONS.SET_IS_ACCOUNT_ENABLED,
      isAccountEnabled: resp.enabled,
    })
  })
}

export const setIsAccountEnabled = (isAccountEnabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_IS_ACCOUNT_ENABLED,
    isAccountEnabled: false,
  })
}

export const setIsWelcomeModalOpened = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED,
    enabled,
  })
}

export const setIsTypeformSurveyVisible = (isTypeformSurveyVisible: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_IS_TYPEFORM_SURVEY_VISIBLE,
    isTypeformSurveyVisible,
  })
}

export const setIsTypeformSurveyRequired = (isTypeformSurveyRequired: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_IS_TYPEFORM_SURVEY_REQUIRED,
    isTypeformSurveyRequired,
  })
}

export const setIsLockedFeatureOn = (isLockedFeatureOn: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_IS_LOCKED_FEATURE_ON,
    isLockedFeatureOn,
  })
}
