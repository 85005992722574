/* eslint-disable max-lines */
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import camelCase from 'camel-case'
import { DESKTOP_BREAKPOINT } from '@xfers/design-system'
import moment from 'moment'
import { ConfigManager, COUNTRY } from '~/managers/ConfigManager'
import {
  Map,
  LABEL_TYPES,
  LabelEntity,
  LabelTypes,
  ErrorContent,
  TransactionsTableColumnEntity,
  ManualAssignmentTableColumnEntity,
  RegionalAccountBalanceTableColumnEntity,
  CreditCardsDisputeReason,
  CURRENCY,
  FIAT,
  Moment,
  BankAccount,
} from '~/types'
import { fiatToStablecoinMap } from './currencyHelpers'
import { BlockchainAddress } from '~/app/store/blockchainAddresses/types'
import { EVM_COMPATIBLE_NETWORK, usdBasedCurrency } from '~/common/constants'

export function formatStraitsXTransactionType(transactionType: string) {
  let formattedType = transactionType
  switch (transactionType) {
    case 'Deposit':
      formattedType = 'Bank Transfer In'
      break
    case 'Withdrawal':
      formattedType = 'Bank Transfer Out'
      break
    case 'Stablecoin Deposit':
      formattedType = 'Blockchain Transfer In'
      break
    case 'Stablecoin Withdrawal':
      formattedType = 'Blockchain Transfer Out'
      break
    default:
      break
  }
  return formattedType
}

export const getCurrencySymbol = () => {
  return ConfigManager.getCountry() === COUNTRY.INDONESIA ? 'Rp' : 'S$'
}

type TrxType = { source: string; destination: string }

export function formatDgwAmount({
  amount,
  withCurrency = true,
  withApproximateSymbol = false,
  isUsingFiatCurrency = false,
  decimalPlaces = 2,
}: {
  amount: string | number | null | undefined
  withCurrency?: TrxType | boolean | string
  withApproximateSymbol?: boolean
  isUsingFiatCurrency?: boolean
  decimalPlaces?: number
}) {
  let postfix = ''
  let prefix = ''

  if (withApproximateSymbol) prefix = '≈'

  if (
    isUsingFiatCurrency &&
    (withCurrency === CURRENCY.SGD.toUpperCase() ||
      withCurrency === CURRENCY.USD.toUpperCase() ||
      withCurrency === true)
  ) {
    return formatFiatAmount({
      amount,
      fiat: withCurrency === CURRENCY.USD.toUpperCase() ? CURRENCY.USD : CURRENCY.SGD,
      decimalPlaces,
    })
  }

  if (withCurrency) {
    if (typeof withCurrency === 'string') {
      const currency = fiatToStablecoinMap[withCurrency.toLowerCase() as FIAT] || withCurrency
      postfix = currency.toUpperCase()
    } else if (typeof withCurrency === 'object') {
      const destination = withCurrency.destination?.toLowerCase() as CURRENCY
      const currency = usdBasedCurrency.includes(destination)
        ? withCurrency.destination || ''
        : withCurrency.source || ''

      postfix = currency.toUpperCase()
    }

    // add regional site for earn business feature
    else if (ConfigManager.isXSGDSite() || ConfigManager.isRegionalSite()) postfix = 'XSGD'
    else if (ConfigManager.isXIDRSite()) postfix = 'XIDR'
  }

  return formatMoney({ prefix, amount, postfix, decimalPlaces })
}

export function formatStablecoinAmount(
  amount: string | number | null | undefined,
  assetTicker?: string
) {
  return formatMoney({ amount, postfix: assetTicker || '' })
}

export function formatFiatAmount({
  amount,
  fiat,
  decimalPlaces,
}: {
  amount: string | number | null | undefined
  fiat: FIAT
  decimalPlaces?: number
}) {
  return formatMoney({ amount, prefix: fiat.toUpperCase(), decimalPlaces })
}

// TODO: migrate to use formatFiatAmount when integrate XIDR into unified dashboard
export function formatIDRAmount({
  amount,
  withCurrency = true,
}: {
  amount: string | number | null | undefined
  withCurrency?: boolean
}) {
  const prefix = withCurrency ? 'IDR' : ''
  return formatMoney({ amount, prefix })
}

export function formatMoney({
  amount,
  prefix = '',
  postfix = '',
  decimalPlaces = 2,
}: {
  amount: string | number | null | undefined
  prefix?: string
  postfix?: string
  decimalPlaces?: number
}) {
  if (!(typeof amount === 'string' || typeof amount === 'number')) {
    return ''
  }

  const amountNumber = typeof amount === 'string' ? parseFloat(amount.replace(/,|\$/g, '')) : amount

  // toLocaleString with en-US is used to format number into 1,000,000.00 format.
  const result = amountNumber.toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  })
  return `${prefix} ${result} ${postfix}`.trim()
}

export function formatAmountToLocalCurrency(amount?: string) {
  if (!amount) {
    return '-'
  }

  const amountFloat = parseFloat(amount)

  if (Number.isNaN(amountFloat) || amountFloat === 0) {
    return '-'
  }

  const idFormatter = new Intl.NumberFormat('id', {
    style: 'currency',
    currency: 'IDR',
  })
  const sgFormatter = new Intl.NumberFormat('sg', {
    style: 'currency',
    currency: 'SGD',
  })

  const result =
    ConfigManager.getCountry() === COUNTRY.INDONESIA
      ? idFormatter.format(amountFloat)
      : sgFormatter
          .format(amountFloat)
          .replace('SGD', 'S$')
          .replace(' ', '')

  return result
}

export function convertNetworkToLabel(network: string | undefined | null) {
  switch (network) {
    case 'ethereum':
      return 'Ethereum'
    case 'zilliqa':
      return 'Zilliqa'
    case 'hedera':
      return 'HTS'
    case 'polygon':
      return 'Polygon'
    case 'avalanche':
      return 'Avalanche C-Chain'
    case 'tron':
      return 'Tron'
    case 'bnbsmart':
      return 'BNB Smart Chain'
    case 'arbitrum':
      return 'Arbitrum'
    case 'ripple':
      return 'Ripple'
    case EVM_COMPATIBLE_NETWORK:
      return 'Ethereum, Polygon, Avalanche C-chain, BNB Smart Chain'
  }
  return network
}

export function getType(status: string, entity: LabelEntity) {
  const STATUS_MAP: Map<LabelEntity> = {
    transaction: {
      completed: LABEL_TYPES.SUCCESS,
      pending: LABEL_TYPES.WARNING,
      processing: LABEL_TYPES.WARNING,
      paid: LABEL_TYPES.SUCCESS,
      failed: LABEL_TYPES.ERROR,
      cancelled: LABEL_TYPES.ERROR,
      refunded: LABEL_TYPES.NEUTRAL,
      expired: LABEL_TYPES.ERROR,
      'needs approval': LABEL_TYPES.WARNING,
      'needs checking': LABEL_TYPES.INFO,
      'in progress': LABEL_TYPES.WARNING,
      rejected: LABEL_TYPES.ERROR,
      blocked: LABEL_TYPES.WARNING,
      processing_fail: LABEL_TYPES.ERROR,
      'paid out': LABEL_TYPES.INFO,
      'unknown status': LABEL_TYPES.ERROR,
    },
    verification: {
      initial: LABEL_TYPES.NEUTRAL,
      pending: LABEL_TYPES.WARNING,
      inactive: LABEL_TYPES.WARNING,
      rejected: LABEL_TYPES.ERROR,
      verified: LABEL_TYPES.SUCCESS,
      unverified: LABEL_TYPES.NEUTRAL,
      permanently_rejected: LABEL_TYPES.ERROR,
    },
    invoice: {
      draft: LABEL_TYPES.NEUTRAL,
      pending: LABEL_TYPES.WARNING,
      unpaid: LABEL_TYPES.WARNING,
      paid: LABEL_TYPES.SUCCESS,
      settled: LABEL_TYPES.SUCCESS,
      completed: LABEL_TYPES.SUCCESS,
      expired: LABEL_TYPES.ERROR,
      blocked: LABEL_TYPES.WARNING,
    },
    developerTools: {
      enabled: LABEL_TYPES.SUCCESS,
      disabled: LABEL_TYPES.ERROR,
    },
    blockchainAddress: {
      rejected: LABEL_TYPES.ERROR,
      verified: LABEL_TYPES.SUCCESS,
      custodialpending: LABEL_TYPES.WARNING,
      noncustodialpending: LABEL_TYPES.NEUTRAL,
    },
    bankAccount: {
      pending: LABEL_TYPES.WARNING,
      rejected: LABEL_TYPES.ERROR,
      verified: LABEL_TYPES.SUCCESS,
      unverified: LABEL_TYPES.NEUTRAL,
    },
    xfersDash: {
      success: LABEL_TYPES.SUCCESS,
      pending: LABEL_TYPES.WARNING,
      failed: LABEL_TYPES.ERROR,
    },
    creditCardsPayments: {
      pending: LABEL_TYPES.WARNING,
      failed: LABEL_TYPES.ERROR,
      processing: LABEL_TYPES.WARNING,
      paid: LABEL_TYPES.SUCCESS,
      cancelled: LABEL_TYPES.ERROR,
      completed: LABEL_TYPES.SUCCESS,
      expired: LABEL_TYPES.ERROR,
    },
    creditCardsDisputes: {
      'needs response': LABEL_TYPES.WARNING,
      'under review': LABEL_TYPES.INFO,
      'dispute lost': LABEL_TYPES.ERROR,
      'refund pending': LABEL_TYPES.WARNING,
      refunded: LABEL_TYPES.SUCCESS,
    },
    earn: {
      completed: LABEL_TYPES.SUCCESS,
      pending: LABEL_TYPES.WARNING,
      failed: LABEL_TYPES.ERROR,
    },
  }

  return STATUS_MAP[entity][status.toLowerCase()] as LabelTypes
}

export function getError(errorCode: string) {
  const ERROR_CONTENT: ErrorContent = {
    '400': {
      errorTitle: '400',
      errorSubtitle: 'Bad request',
      errorContent: 'Your request resulted in an error.',
    },
    '401': {
      errorTitle: '401',
      errorSubtitle: 'No authorization found',
      errorContent: 'You do not have permission to access this site.',
    },
    '403': {
      errorTitle: '403',
      errorSubtitle: 'Forbidden',
      errorContent: 'An error occurred when we tried to process your request.',
    },
    '404': {
      errorTitle: '404',
      errorSubtitle: 'Page Not Found',
    },
    '408': {
      errorTitle: '408',
      errorSubtitle: 'Request time-out',
      errorContent: "Your browser didn't send a complete request this time.",
    },
    '500': {
      errorTitle: '500',
      errorSubtitle: 'Internal server error',
      errorContent: "Your browser didn't send a complete request this time.",
    },
    'Access denied': {
      errorTitle: 'Oops!',
      errorSubtitle: 'Access denied',
      errorContent: 'You do not have the permission to view the page.',
    },
  }

  return ERROR_CONTENT[errorCode]
}

export function formatAccountNumberWithDashes(value: string) {
  // 10000100000010176 -> 1000-0100-0000-1017-6
  if (value.length === 17) {
    const splitAry = value.match(/.{1,4}/g) // split every 4 character into array ['1000', '0100', '0000', '1017', '6']
    if (splitAry === null) return value
    return splitAry.join('-') // join first 5 element with '-'
  } else return value
}

export function formatAmount(amount: string | number | undefined) {
  if (amount === '' || amount === undefined) {
    return '-'
  }
  const amountFloat = typeof amount === 'string' ? parseFloat(amount) : amount
  if (Number.isNaN(amountFloat) || amountFloat === 0) {
    return '-'
  }
  return amountFloat
}

export function formatSeconds(secondNum: number | undefined) {
  if (secondNum === undefined || Number.isNaN(secondNum)) {
    return '-'
  }
  const minutes = Math.floor(secondNum / 60)
  const seconds = secondNum - minutes * 60
  let minutesStr = minutes.toString()
  let secondsStr = seconds.toString()
  if (minutes < 10) {
    minutesStr = '0' + minutes
  }
  if (seconds < 10) {
    secondsStr = '0' + seconds
  }
  return minutesStr + ':' + secondsStr
}

export const formatAddressLabel = (string: string, stringLength: number) => {
  // Kgobp94xUnTL6FsmI3XInFB73e -> Kgobp94xUnTL6Fsm...

  if (string?.length > stringLength) {
    return string.substring(0, stringLength) + '...'
  }

  return string || '-'
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const isObject = (obj: any) => {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const keysToCamel = (obj: any): any => {
  if (isObject(obj)) {
    const n: any = {}

    Object.keys(obj).forEach(k => {
      n[camelCase(k)] = keysToCamel(obj[k])
    })

    return n
  } else if (Array.isArray(obj)) {
    return obj.map(i => {
      return keysToCamel(i)
    })
  }

  return obj
}

type Account = {
  bankAbbrev: string
  accountNo?: string
  virtualAccountNumber?: string
}

export const formatBankAccountName = (account: Account) => {
  const formattedAccountName = get(account, 'bankAbbrev', '-').toUpperCase()
  const isVirtualAccountExists = !isEmpty(account.virtualAccountNumber)
  const isCommonAccountExists = !isEmpty(account.accountNo)
  let accountNumber
  let accountName = formattedAccountName

  if (isVirtualAccountExists) {
    accountNumber = account.virtualAccountNumber
    accountName = `VA ${formattedAccountName}`
  } else if (isCommonAccountExists) {
    accountNumber = account.accountNo
  } else {
    accountNumber = '-'
  }

  return { accountName, accountNumber }
}

export const formatBankAccountNumber = (accountNumber: string) => {
  if (!accountNumber) return accountNumber
  return accountNumber.match(/.{1,4}/g)?.join('-')
}

export const formatPaymentMethod = (paymentMethod: string | undefined) => {
  let formattedPaymentMethod

  if (paymentMethod === 'va') {
    formattedPaymentMethod = 'Virtual Account'
  } else if (paymentMethod === 'paynow') {
    formattedPaymentMethod = 'PayNow'
  } else {
    formattedPaymentMethod = '-'
  }

  return formattedPaymentMethod
}

export const TwoFaHelpMessage = (message: string | undefined) => {
  let helpMessage
  if (message) {
    if (message.includes('Invalid OTP provided')) {
      helpMessage = 'Authentication code is incorrect. Please try again.'
    } else {
      helpMessage = message
    }
    return helpMessage
  }
  return helpMessage
}

export const getDisbursementFeatureName = () => {
  return ConfigManager.isRegionalSite() ? 'Payout Form' : 'Xfers Dash'
}

export const getColumnName = (columnName: TransactionsTableColumnEntity) => {
  const COLUMN_MAP: Map<TransactionsTableColumnEntity> = {
    settlementDate: {
      columnTitleName: 'Date',
      columnTooltipTitle: 'The settlement date & time.',
    },
    createdDate: {
      columnTitleName: 'Created Date',
      columnTooltipTitle: 'The date & time when the payment is created.',
    },
    amount: {
      columnTitleName: 'Amount',
      columnTooltipTitle: 'Amount received before deductions from tax and fee are made.',
    },
    netAmount: {
      columnTitleName: 'Net Amount',
      columnTooltipTitle: 'Final amount after deductions from tax and fee are made.',
    },
    completedDate: {
      columnTitleName: 'Completed Date',
      columnTooltipTitle: 'The date & time when the payment is completed.',
    },
    status: {
      columnTitleName: 'Status',
      columnTooltipTitle: 'Each status represents a process of transaction lifecycle.',
    },
  }

  return COLUMN_MAP[columnName]
}

export const formatIndoTransactionsStatus = (indoTransactionsStatus: string) => {
  if (!indoTransactionsStatus) return '-'
  let status = ''

  switch (indoTransactionsStatus) {
    case 'admin_blocked':
      status = 'Blocked'
      break
    case 'need_approval':
      status = 'Needs Approval'
      break
    case 'need_checking':
      status = 'Needs Checking'
      break
    case 'in_progress':
      status = 'In Progress'
      break
    default:
      status = indoTransactionsStatus
      break
  }

  return status
}

export const formatIndoTransactionsTransactionType = (type: string) => {
  const typeMaps: Record<string, string> = {
    transfer_in: 'Transfer-in',
    transfer_out: 'Transfer-out',
    fee: 'Fee',
    tax: 'Tax',
    admin: 'Admin',
  }
  return typeMaps[type]
}

export const getManualAssignmentColumnName = (columnName: ManualAssignmentTableColumnEntity) => {
  const COLUMN_MAP: Map<ManualAssignmentTableColumnEntity> = {
    assignedAmount: {
      columnTitleName: 'Assigned Amount',
      columnTooltipTitle: 'The amount that has been assigned manually by our admin.',
    },
    completedDate: {
      columnTitleName: 'Completed Date',
      columnTooltipTitle: 'The date & time manual assignment has completed.',
    },
  }

  return COLUMN_MAP[columnName]
}

export const getRegionalAccountBalanceColumnName = (
  columnName: RegionalAccountBalanceTableColumnEntity
) => {
  const COLUMN_MAP: Map<RegionalAccountBalanceTableColumnEntity> = {
    referenceId: {
      columnTitleName: 'Ref. ID',
      columnTooltipTitle: 'Unique ID provided by merchant to identify each transaction',
    },
    transactionId: {
      columnTitleName: 'Txn. ID',
      columnTooltipTitle: 'Xfers unique ID for each transaction',
    },
    creationDate: {
      columnTitleName: 'Created Date',
      columnTooltipTitle: 'Date on which transaction was created',
    },
    settlementDate: {
      columnTitleName: 'Settled Date',
      columnTooltipTitle: 'Date on which transaction was completed',
    },
  }

  return COLUMN_MAP[columnName]
}

export const mapDisputeReason = (disputeReason: CreditCardsDisputeReason) => {
  const DISPUTE_REASON_MAP: Map<CreditCardsDisputeReason> = {
    credit_not_processed: {
      reason: 'Credit not processed',
      tooltip:
        'The customer claims that the purchased product was returned or the transaction was otherwise cancelled, but you haven’t yet provided a refund or credit.',
    },
    duplicate: {
      reason: 'Payment duplicated',
      tooltip:
        'The customer claims they were charged multiple times for the same product or service.',
    },
    fraudulent: {
      reason: 'Fraudulent',
      tooltip: 'The cardholder claims that they didn’t authorise the payment.',
    },
    general: {
      reason: 'General',
      tooltip: 'Uncategorised dispute',
    },
    product_not_received: {
      reason: 'Product not received',
      tooltip: 'The customer claims they did not receive the products or services purchased.',
    },
    product_unacceptable: {
      reason: 'Product unacceptable',
      tooltip:
        'The product or service was received but was defective, damaged, or not as described.',
    },
    unrecognized: {
      reason: 'Payment unrecognized',
      tooltip: 'The customer doesn’t recognise the payment appearing on their card statement.',
    },
  }

  return DISPUTE_REASON_MAP[disputeReason]
}

export const formatCreditCardTypes = (cardType: string | undefined | null) => {
  if (!cardType) return '-'
  let type = ''

  switch (cardType) {
    case 'visa':
    case 'mastercard':
    case 'american_express':
    case 'diners_club':
    case 'discover':
    case 'union_pay':
      // capitalize first letter of each word
      type = cardType
        .split('_')
        .map((w: string) => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ')
      break

    case 'jcb':
      // capitalize all letters in a word
      type = cardType.toUpperCase()
      break

    default:
      type = 'Unknown'
      break
  }

  return type
}

export const isMobileView = (): boolean => {
  const threeConsecutiveDigits = /\d{3}/
  const desktopBreakpointWidth = DESKTOP_BREAKPOINT.match(threeConsecutiveDigits)[0]
  return document.documentElement.clientWidth <= desktopBreakpointWidth
}

export const sanitizeIndoCustomerPhoneNumber = (value: string, prefix: string = '+62') => {
  if (value.startsWith('0')) return `${prefix}${value.slice(1)}`
  return `${prefix}${value}`
}

export const getMomentDate = (date: string | undefined, format = 'D MMM YYYY, HH:mm') => {
  return date ? moment(date).format(format) : '-'
}

export const paginate = (arr: Array<any>, page: number = 1, pageSize: number = 10) => {
  return arr?.slice((page - 1) * pageSize, page * pageSize)
}

export const getSGCurrency = (isUsingFiatCurrency: boolean) => {
  return isUsingFiatCurrency ? 'SGD' : 'XSGD'
}

export const objectToQueryParams = (obj: any): string => {
  const params = Object.keys(obj).map(
    key => obj[key] && encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
  )
  return params.join('&')
}

export const truncateAtTwoDecimalPlace = (input: string, callback: (value: string) => void) => {
  const regex = RegExp('^[0-9]+(\\.[0-9]{0,2})?$')
  if (input === '' || regex.test(input)) callback(input)
}

export const formatComma = (amount: string | number) => {
  let result = amount
  if (!result || Number.isNaN(result)) return result

  // remove existing commas
  if (typeof result === 'string') {
    result = result.replace(/,/g, '')
    if (Number.isNaN(Number(result))) return result
  }

  const regex = /\B(?=(\d{3})+(?!\d))/g
  // only handle the integer part
  return Number.isInteger(Number(result))
    ? result.toString().replace(regex, ',')
    : `${result
        .toString()
        .split('.')[0]
        .replace(regex, ',')}.${result.toString().split('.')[1]}`
}

export const isNullOrUndefined = (item: unknown) => {
  return item === undefined || item === null
}

export const uuid = () => {
  let d = Date.now()
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now() // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    // eslint-disable-next-line
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const convertToSGTTime = (inputDate: Moment, type: 'from' | 'to') => {
  let timeString
  let milliseconds = 0
  if (type === 'from') {
    timeString = '00:00:00'
  } else {
    timeString = '23:59:59'
    milliseconds = 999
  }

  // NOTE: use SGT for all user timezones to avoid different CSV content
  const exportDateTime = new Date(`${inputDate.toDate().toDateString()} ${timeString} +08:00`)
  exportDateTime.setMilliseconds(milliseconds)
  return moment(exportDateTime).toISOString()
}

export const isSgSwiftCode = (swiftCode: string) => {
  return /^[A-Z]{4}SG[A-Z0-9]{5}$/.test(swiftCode)
}

export const isDbsSgSwiftCode = (swiftCode: string) => {
  return /^DBSSSGSG([A-Z0-9]{3})?$/.test(swiftCode)
}

export const isNbfiBankSwiftCode = (swiftCode: string) => {
  const nbfiBankSwiftCodes = [
    'GXSBSGSGXXX', // GrabPay
    'LIGPSGSGXXX', // LiquidPay
    'MAYPSGSGXXX', // Matchmove
    'SNPTSGSGXXX', // Singtel Dash
    'TRWISGSGXXX', // TransferWise
  ]

  return nbfiBankSwiftCodes.includes(swiftCode)
}

export const truncateBlockchainAddressString = (address: string) => {
  if (!address || address.length <= 8) return address

  return `${address.slice(0, 4)}...${address.slice(-3)}`
}

export const sortBanksByVerificationStatus = (banks: BankAccount[]) => {
  const verificationStatusOrder = ['verified', 'pending']

  const getIndex = (status: string) => {
    const index = verificationStatusOrder.indexOf(status)
    // if the status is not found, then it will placed in the last order
    return index !== -1 ? index : verificationStatusOrder.length
  }

  return [...banks].sort((a, b) => getIndex(a.verificationStatus) - getIndex(b.verificationStatus))
}

export const sortBlockchainAddresses = (addresses: BlockchainAddress[]) => {
  return addresses.sort((a, b) => {
    // If 'a' is approved and 'b' is not, 'a' comes first
    if (a.approved && !b.approved) {
      return -1
      // If 'b' is approved and 'a' is not, 'b' comes first
    } else if (!a.approved && b.approved) {
      return 1
      // If both are approved or not approved, maintain the original order based on id
    } else {
      return a.id - b.id
    }
  })
}

export const remapTransactionDescData = (descriptionData: string) => {
  if (descriptionData.toLowerCase() === 'digital goods') {
    return 'StraitsX Account'
  }
  return descriptionData
}

export const mapTransactionNetwork = (network: string) => {
  switch (network.toLowerCase()) {
    case 'avalanche':
      return 'Avalanche C-Chain'
    case 'bnbsmart':
      return 'BNB Smart Chain'
    default:
      return network.capitalize()
  }
}

export const BITGO_LAUNCH_DATE = '2024-03-27T10:00:00Z' // BitGo Launch Date - 27th March 10 am UTC
