/* eslint-disable max-lines */

import { TypedDataUtils } from 'eth-sig-util'
import { BLOCKCHAIN_ADDRESS_ACTIONS } from './const'
import { ActionTypes, BlockchainAddress, FinancialInstitution } from './types'
import { Dispatch } from '~/app/store/types'
import { del, ENDPOINTS, get, post } from '~/api'
import { keysToCamel, objectToQueryParams } from '~/helpers/helpers'
import { BLOCKCHAIN_NETWORK_ID } from '~/types'
import { Notification } from '~/components/Notification'
import { verifySignedMessageClientSide } from '~/helpers/verifySignedMessage'
import TronWeb from 'tronweb'

const tronWebInstance = new TronWeb({
  fullHost: 'https://api.trongrid.io',
})

export const setBlockchainAddressList = (blockchainAddressList: BlockchainAddress[]) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: BLOCKCHAIN_ADDRESS_ACTIONS.SET_BLOCKCHAIN_ADDRESS_LIST,
    blockchainAddressList,
  })
}

export const setMaxAddressAmount = (maxAddressAmount: number) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: BLOCKCHAIN_ADDRESS_ACTIONS.SET_MAX_ADDRESS_AMOUNT,
    maxAddressAmount,
  })
}

export const setAddressQuota = (addressQuota: number) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: BLOCKCHAIN_ADDRESS_ACTIONS.SET_ADDRESS_QUOTA,
    addressQuota,
  })
}

export const setFIList = (fiList: FinancialInstitution[]) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: BLOCKCHAIN_ADDRESS_ACTIONS.SET_FI_LIST,
    fiList,
  })
}

export const updateBlockchainAddress = (blockchainAddress: BlockchainAddress) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: BLOCKCHAIN_ADDRESS_ACTIONS.UPDATE_BLOCKCHAIN_ADDRESS,
    blockchainAddress,
  })
}

export const fetchBlockchainAddresses = (accountId: number, successCallback?: () => void) => async (
  dispatch: Dispatch<ActionTypes>
) => {
  const subRoute = `/${accountId}`

  const resp = await get(ENDPOINTS.API_V3_STABLECOIN_ADDRESSES, subRoute)
  if (resp.data.address_list) {
    const respList = resp.data.address_list as []
    const addressList: BlockchainAddress[] = respList.map(blockchainAddress =>
      keysToCamel(blockchainAddress)
    )
    dispatch(setBlockchainAddressList(addressList))
  }

  if (resp.data.fi_list) {
    dispatch(setFIList(resp.data.fi_list as FinancialInstitution[]))
  }

  if (resp.data.max_address_amount && !Number.isNaN(resp.data.max_address_amount)) {
    dispatch(setMaxAddressAmount(resp.data.max_address_amount))
  }

  if (
    (resp.data.address_quota === 0 || resp.data.address_quota) &&
    !Number.isNaN(resp.data.address_quota)
  ) {
    dispatch(setAddressQuota(resp.data.address_quota))
  }

  if (successCallback) {
    successCallback()
  }
}

export const deleteBlockchainAddress = (
  accountId: number,
  address: BlockchainAddress,
  onSuccess?: () => void
) => async (dispatch: Dispatch<ActionTypes>) => {
  const queryParams = objectToQueryParams({ blockchain: address.blockchain })
  const addressId = address.id
  const subRoute = `/${accountId}/${addressId}?${queryParams}`
  await del(ENDPOINTS.API_V3_STABLECOIN_ADDRESSES, {}, subRoute)

  dispatch(fetchBlockchainAddresses(accountId, onSuccess))
}

export const setBlockchainAddressDeletion = (blockchainAddressDeletion: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: BLOCKCHAIN_ADDRESS_ACTIONS.SET_BLOCKCHAIN_ADDRESS_DELETION,
    blockchainAddressDeletion,
  })
}

export const setNewlyCreatedBlockchainAddress = (address: BlockchainAddress) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: BLOCKCHAIN_ADDRESS_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS,
    blockchainAddress: address,
  })
}

export const updateVerifiedBlockchainAddress = (address: BlockchainAddress) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: BLOCKCHAIN_ADDRESS_ACTIONS.UPDATE_VERIFIED_BLOCKCHAIN_ADDRESS,
    blockchainAddress: address,
  })
}

export const createBlockchainAddress = (
  isWhitelistingImprovementEnabled: boolean,
  addressCustodialType: string,
  blockchain: string,
  nickname: string,
  provider: string,
  address: string,
  accountId?: number,
  successCallback?: (address: BlockchainAddress) => void,
  errorCallback?: (errorMessage: string) => void
) => (dispatch: Dispatch<ActionTypes>) => {
  post(
    ENDPOINTS.API_V3_STABLECOIN_ADDRESSES,
    {
      address_custodial_type: addressCustodialType,
      blockchain,
      nickname,
      provider,
      address,
    },
    {},
    `/${accountId}`
  )
    .then(resp => {
      if (successCallback) {
        if (isWhitelistingImprovementEnabled && accountId) {
          const fetchBlockchainAddressesSuccessCallback = () => {
            const blockchainAddresses: BlockchainAddress[] = resp.data.filter(
              (obj: BlockchainAddress) => obj.address === address && obj.blockchain === blockchain
            )
            if (blockchainAddresses.length > 0) {
              successCallback(keysToCamel(blockchainAddresses[0]))
            } else {
              const blockchainAddress: BlockchainAddress = keysToCamel(resp.data[0])
              successCallback(blockchainAddress)
            }
          }
          dispatch(fetchBlockchainAddresses(accountId, fetchBlockchainAddressesSuccessCallback))
        } else {
          const blockchainAddress: BlockchainAddress = keysToCamel(resp)
          successCallback(blockchainAddress)
        }
      }
    })
    .catch((error: { response: { data: { error: string } } }) => {
      if (errorCallback) {
        errorCallback(error?.response?.data?.error)
      }
    })
}

export const signThenVerifyMessage = (
  address: BlockchainAddress,
  accounts: string[],
  signTypedData: (param: string[]) => Promise<string>,
  successCallback: () => void,
  errorCallback: (errorMessage: string) => void,
  accountId?: number
) => (dispatch: Dispatch<ActionTypes>) => {
  const queryParams = objectToQueryParams({ blockchain: address.blockchain })
  const addressId = address.id
  const subRoute = `/${accountId}/${addressId}/signed_message?${queryParams}`
  let msg: { domain?: { chainId?: string } } = {}
  const network = address.blockchain

  get(ENDPOINTS.API_V3_STABLECOIN_ADDRESSES, subRoute)
    .then(async resp => {
      msg = JSON.parse(resp.data.Data)
      if (msg?.domain?.chainId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { blockchain }: any = address
        const blockchainName = blockchain.toString().toUpperCase()
        msg.domain.chainId = BLOCKCHAIN_NETWORK_ID[blockchainName]?.toString()
      }

      const signTypedDataParams = [
        accounts[0], // Required
        JSON.stringify(msg), // Required
      ]
      // Sign Typed Data
      const sign: { signature: string } | string = await signTypedData(signTypedDataParams)

      if (network === 'tron') {
        try {
          const messageHash = TypedDataUtils.sign(msg).toString('hex')
          const base58Address = await tronWebInstance.trx.verifyMessageV2(
            messageHash,
            sign.signature
          )
          if (base58Address) {
            dispatch(
              submitVerifiedAddress(
                address,
                JSON.stringify(msg),
                sign,
                successCallback,
                errorCallback,
                accountId
              )
            )
          } else {
            throw Error('Failed to verify address')
          }
        } catch (e) {
          console.error(e)
          errorCallback('Failed to verify tron address')
        }
      } else {
        try {
          const verified = await verifySignedMessageClientSide(address.address, msg, sign)

          if (verified) {
            dispatch(
              submitVerifiedAddress(
                address,
                JSON.stringify(msg),
                sign,
                successCallback,
                errorCallback,
                accountId
              )
            )
          } else {
            errorCallback('Signature verification failed')
          }
        } catch (e) {
          errorCallback('Failed to verify message')
        }
      }
    })
    .catch((error: { code: number; message: string; response: { data: { error: string } } }) => {
      let errorMessage = error?.response?.data?.error || error?.message || 'Some errors occurred'

      // transform error message to be more user friendly
      switch (address.provider) {
        case 'MetaMask':
          if (/Provided chainId "\d+" must match the active chainId "\d+"/.test(errorMessage)) {
            const networkCopywriting = address.blockchain?.capitalize() || 'same'
            errorMessage = `Your address cannot be verified as your wallet is on a different network. Please ensure your wallet is on the ${networkCopywriting} network before trying again.`
          }
          break
      }

      if (errorCallback) {
        errorCallback(errorMessage)
      } else {
        Notification.warn({
          message: 'Warning',
          description: errorMessage,
        })
      }
    })
}

const verifySignedMessage = (
  address: BlockchainAddress,
  msg: string,
  sign: string,
  successCallback: () => void,
  errorCallback: (errorMessage: string) => void,
  accountId?: number
) => async () => {
  // sign.slice(2) is because sign would be like 0x123456... but api only needs 123456...
  const body = { msg, sign: sign.slice(2) }
  const queryParams = objectToQueryParams({ blockchain: address.blockchain })
  const addressId = address.id
  const subRoute = `/${accountId}/${addressId}/verify_signed_message?${queryParams}`
  const resp: { data: { Data: boolean; Error: string } } = await post(
    ENDPOINTS.API_V3_STABLECOIN_ADDRESSES,
    body,
    {},
    subRoute
  )

  if (resp.data.Data === true) {
    if (successCallback) {
      successCallback()
    }
  } else {
    errorCallback(resp.data.Error)
  }
}

const submitVerifiedAddress = (
  address: BlockchainAddress,
  msg: string,
  sign: string | { signature: string },
  successCallback: () => void,
  errorCallback: (errorMessage: string) => void,
  accountId?: number
) => async () => {
  let body = {
    msg,
    sign: '',
  }
  if (typeof sign === 'string') {
    body.sign = sign.slice(2)
  } else if (sign && typeof sign === 'object' && 'signature' in sign) {
    body.sign = sign.signature.slice(2)
  } else {
    throw new Error('Invalid sign parameter')
  }
  const queryParams = objectToQueryParams({ blockchain: address.blockchain })
  const addressId = address.id
  const subRoute = `/${accountId}/${addressId}/approve_signed_token_address?${queryParams}`
  const resp: { data: Record<string, unknown> } = await post(
    ENDPOINTS.API_V3_STABLECOIN_ADDRESSES,
    body,
    {},
    subRoute
  )

  if (resp.data) {
    if (successCallback) {
      successCallback()
    }
  } else {
    errorCallback('Failed to verify address')
  }
}

export const setIsWhitelistingImprovementEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: BLOCKCHAIN_ADDRESS_ACTIONS.SET_IS_WHITELISTING_IMPROVEMENT_ENABLED,
    isWhitelistingImprovementEnabled: enabled,
  })
}
