import { ENDPOINTS, put, get } from '~/api'

const NotificationEndpoints = {
  Notifications: ENDPOINTS.API_NOTIFICATION,
}

const getNotifications = (selectedTag?: string) =>
  get(NotificationEndpoints.Notifications, '', { tag: selectedTag })

const putReadNotifications = (notification_ids: string[]) =>
  put(NotificationEndpoints.Notifications, { notification_ids })

export { getNotifications, putReadNotifications }
