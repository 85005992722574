import { Reducer } from 'redux'
import { ActionTypes, ROUTE_ACTIONS, User, Notification, FeatureMatrix } from './types'
import { PATHS } from '~/biz/routes/paths'
import { COUNTRY } from '~/managers/ConfigManager'

import { BUSINESS_VERIFICATION_STATUS, PERSONAL_VERIFICATION_STATUS } from '~/types'

const initialState = {
  currentRedirect: null,
  isInitiated: false,
  user: {
    kcId: undefined,
    email: '',
    mobileNumber: '',
    countryCode: '',
    companyId: '',
    userRole: undefined,
    authServerUrl: '',
    twoFa: {
      isOnCoolingDownPeriod: false,
      twoFaSetupTime: '',
      twoFaList: [
        {
          credential: {
            id: '',
            type: '',
            userLabel: '',
            createdDate: 0,
            credentialData: {},
          },
        },
      ],
    },
    fullName: '',
    isBitgoAddressAcknowledged: undefined,
    isFiatWalletOnly: false,
  },
  notifications: {
    data: [],
    unreadCount: 0,
    isLoading: false,
  },
  isWelcomeModalOpened: null,
  featureMatrix: {
    userRole: null,
    country: COUNTRY.SINGAPORE,
    availableWallets: [],
    businessVerificationStatus: BUSINESS_VERIFICATION_STATUS.INITIAL,
    personalVerificationStatus: PERSONAL_VERIFICATION_STATUS.INITIAL,
    availableFundPools: [],
    companyTxnVolume: -1,
    isIdentificationDone: false,
  },
  isUsingFiatCurrency: false,
  isUserInfoInitialised: false,
  isUserHasPassword: false,
  isAccountEnabled: true,
}

type State = {
  isInitiated: boolean
  currentRedirect: PATHS | null
  user: User
  notifications: {
    data: Notification[]
    unreadCount: number
    isLoading: boolean
  }
  isWelcomeModalOpened: boolean | null
  featureMatrix: FeatureMatrix
  isUsingFiatCurrency: boolean
  isUserInfoInitialised: boolean
  isUserHasPassword: boolean
  isAccountEnabled: boolean
}

export const route: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_ACTIONS.SET_REDIRECT: {
      return {
        ...state,
        currentRedirect: action.to,
      }
    }
    case ROUTE_ACTIONS.SET_USER_PROFILE: {
      return {
        ...state,
        user: {
          ...action.user,
        },
      }
    }
    case ROUTE_ACTIONS.SET_NOTIFICATIONS: {
      const { data, unreadCount, isLoading } = action
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data,
          unreadCount,
          isLoading,
        },
      }
    }
    case ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: action.isLoading,
        },
      }
    }
    case ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED: {
      return {
        ...state,
        isWelcomeModalOpened: action.enabled,
      }
    }
    case ROUTE_ACTIONS.SET_FEATURE_MATRIX: {
      return {
        ...state,
        featureMatrix: {
          ...action.featureMatrix,
        },
      }
    }
    case ROUTE_ACTIONS.INITIATE_GLOBAL_STORE: {
      return {
        ...state,
        isInitiated: true,
      }
    }
    case ROUTE_ACTIONS.INVALIDATE_GLOBAL_STORE: {
      return {
        ...state,
        isInitiated: false,
      }
    }
    case ROUTE_ACTIONS.SET_IS_CHANGE_TO_FIAT_CURRENCY_ENABLED: {
      return {
        ...state,
        isUsingFiatCurrency: action.isUsingFiatCurrency,
      }
    }
    case ROUTE_ACTIONS.SET_IS_USER_INFO_INITIALISED: {
      return {
        ...state,
        isUserInfoInitialised: action.isUserInfoInitialised,
      }
    }
    case ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD: {
      return {
        ...state,
        isUserHasPassword: action.isUserHasPassword,
      }
    }
    case ROUTE_ACTIONS.SET_IS_ACCOUNT_ENABLED: {
      return {
        ...state,
        isAccountEnabled: action.isAccountEnabled,
      }
    }
    default:
      return state
  }
}
