import * as React from 'react'

function P0Component(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M7.99998 1.16031C7.53492 1.16022 7.07817 1.28361 6.67641 1.51787C6.27466 1.75213 5.9423 2.08886 5.71331 2.49364L0.866646 10.8803C0.643525 11.2824 0.528183 11.7354 0.531822 12.1952C0.53546 12.6551 0.657956 13.1062 0.887412 13.5047C1.11687 13.9032 1.44548 14.2357 1.84135 14.4697C2.23721 14.7037 2.68687 14.8314 3.14665 14.8403H12.8533C13.3131 14.8314 13.7627 14.7037 14.1586 14.4697C14.5545 14.2357 14.8831 13.9032 15.1125 13.5047C15.342 13.1062 15.4645 12.6551 15.4681 12.1952C15.4718 11.7354 15.3564 11.2824 15.1333 10.8803L10.2866 2.48031C10.0577 2.07553 9.7253 1.7388 9.32355 1.50454C8.92179 1.27028 8.46504 1.14689 7.99998 1.14697V1.16031ZM7.45331 12.0003C7.37827 11.9282 7.31929 11.841 7.28023 11.7445C7.24117 11.648 7.22291 11.5443 7.22665 11.4403C7.22221 11.3382 7.24021 11.2363 7.27937 11.1418C7.31853 11.0474 7.3779 10.9627 7.45331 10.8936C7.59986 10.751 7.79548 10.6699 7.99998 10.667C8.10171 10.6644 8.20296 10.6819 8.29791 10.7185C8.39286 10.7551 8.47965 10.8101 8.55331 10.8803C8.62957 10.949 8.68995 11.0335 8.73025 11.1279C8.77055 11.2223 8.78979 11.3244 8.78665 11.427C8.78911 11.5314 8.76961 11.6353 8.72942 11.7317C8.68922 11.8282 8.62923 11.9152 8.55331 11.987C8.48087 12.0593 8.39444 12.1161 8.2993 12.154C8.20417 12.1918 8.10232 12.2098 7.99998 12.207C7.79786 12.2115 7.60187 12.1374 7.45331 12.0003ZM7.33331 7.56031V5.24697H8.69998V7.56031L8.57998 9.87364H7.44665L7.33331 7.56031Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default P0Component
