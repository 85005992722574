import { ethers } from 'ethers'
import { TypedDataUtils } from 'eth-sig-util'

export const verifySignedMessageClientSide = async (
  signerAddress: string,
  typedData: any,
  signature: string
): Promise<boolean> => {
  try {
    // Ensure signature has '0x' prefix
    const formattedSignature = signature.startsWith('0x') ? signature : `0x${signature}`

    // Ensure signerAddress has '0x' prefix
    const formattedSignerAddress = signerAddress.startsWith('0x')
      ? signerAddress
      : `0x${signerAddress}`

    // Verify the signature is valid
    if (!ethers.utils.isHexString(formattedSignature)) {
      console.error('Invalid signature format')
      return false
    }

    // Get the EIP-712 hash of the typed data
    const messageHash = TypedDataUtils.sign(typedData)
    const messageHashBytes = ethers.utils.arrayify(messageHash)

    // Recover the address from the signature
    const recoveredAddress = ethers.utils.recoverAddress(messageHashBytes, formattedSignature)

    if (!recoveredAddress) {
      console.error('Failed to recover address')
      return false
    }

    // Compare addresses in checksum format
    const normalizedRecovered = ethers.utils.getAddress(recoveredAddress)
    const normalizedSigner = ethers.utils.getAddress(formattedSignerAddress)

    return normalizedRecovered === normalizedSigner
  } catch (error) {
    console.error('Verification error:', error)
    console.log('Debug info:', {
      signerAddress,
      typedData,
      signature,
    })
    return false
  }
}
