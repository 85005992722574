import styles from './controlScroll.module.css'

function preventDefault(e: Event) {
  e.preventDefault()
}

export function disableScroll() {
  document.body.classList.add(styles.stopScrolling)
  window.addEventListener('touchmove', preventDefault) // mobile
}

export function enableScroll() {
  document.body.classList.remove(styles.stopScrolling)
  window.removeEventListener('touchmove', preventDefault) // mobile
}
