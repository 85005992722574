import axios from 'axios'
import cookie from 'js-cookie'
import { ModeManager } from '~/managers/ModeManager'
import SSOManager from '~/managers/SSOManager'
import { ConfigManager } from '~/managers/ConfigManager'
import { Notification } from '~/components/Notification'

import ENDPOINTS from './paths'

function makeRequest(
  url: string,
  method: 'get' | 'post' | 'delete' | 'put',
  additionalHeaders: object = {},
  requestBody: object = {},
  queryStringParams: object = {}
) {
  let domain
  // Cause this is internal proxy on express server we dont need to provide API_HOST to let it call to Express default
  if (ConfigManager.isAppSite()) {
    domain = '/fe/'
  } else if (ConfigManager.isBizSite() || ConfigManager.isRegionalSite()) {
    domain = ModeManager.isSandboxMode() ? '/sandbox/fe/' : '/fe/'
  }

  type RedirectHtml = string
  function extractUrlFromHtml(htmlString: RedirectHtml): string | null {
    const hrefRegex = /href="([^"]*)"/
    const hrefMatch = hrefRegex.exec(htmlString)
    return hrefMatch ? hrefMatch[1].replace(/&amp;/g, '&') : null
  }

  return axios({
    method,
    url: domain + url,
    headers: {
      ...additionalHeaders,
    },
    data: requestBody,
    params: queryStringParams,
    withCredentials: true,
    validateStatus: status => status >= 200 && status < 400, // NOTE: adding this since axios is handling 304 as error responses, ref: https://github.com/axios/axios/issues/703
  })
    .then(response => {
      if (response.status === 302 && typeof response.data === 'string') {
        const extractedUrl = extractUrlFromHtml(response.data)
        if (extractedUrl) {
          return { data: extractedUrl }
        }
      }
      return response.data
    })
    .catch(err => {
      if (err.response?.status) {
        const { status } = err.response
        switch (status) {
          case 401:
            // Note: FE development still use non-SSO way to login,
            // so only when we are deprecating all the cookies.set and
            // cookies.remove way of login, then deprecate all at the same
            // time once we completely switch to SSO-only login
            cookie.remove('SESSIONID')
            SSOManager.ssoLogout()
            break
          case 403:
            Notification.error({
              message: 'Error 403: Forbidden',
              description: 'An error occurred when we tried to process your request.',
            })
            break
          case 413:
            Notification.error({
              message: 'Error 413: Payload Too Large',
              description:
                'The file that you tried to upload is too large. Please try again with a smaller file.',
            })
            break
          case 429:
            Notification.error({
              message: 'Error 429: Too Many Requests',
              description:
                'You have sent too many requests in a given amount of time. Please wait a moment and try again.',
            })
            break
          case 500:
            Notification.error({
              message: 'Error 500: Internal Server Error',
              description:
                'The server encountered an unexpected condition which prevented it from fulfilling the request. Please contact our support team if this problem persists.',
            })
            break
        }
      }

      throw err
    })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponsePromise = Promise<any>
// eslint-disable-next-line @typescript-eslint/typedef
function mock(_: ENDPOINTS, __: string = '', mockData = {}): ResponsePromise {
  return Promise.resolve({ data: mockData })
}

function get(endpoint: string, subRoute: string = '', queryStringParams?: object): ResponsePromise {
  return makeRequest(endpoint + subRoute, 'get', undefined, undefined, queryStringParams)
}

function post(
  endpoint: string,
  requestBody: object,
  additionalHeaders: object = {},
  subRoute: string = ''
): ResponsePromise {
  return makeRequest(endpoint + subRoute, 'post', additionalHeaders, requestBody)
}

function del(
  endpoint: string,
  additionalHeaders: object = {},
  subRoute: string = '',
  requestBody?: object
): ResponsePromise {
  return makeRequest(endpoint + subRoute, 'delete', additionalHeaders, requestBody)
}

function put(
  endpoint: string,
  requestBody: object,
  additionalHeaders: object = {},
  subRoute: string = ''
): ResponsePromise {
  return makeRequest(endpoint + subRoute, 'put', additionalHeaders, requestBody)
}

export { get, post, del, put, mock }
