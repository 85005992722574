import { useMutation, useQuery } from 'react-query'
import { getNotifications, putReadNotifications } from './Notification.api'

const NotificationApiQueryKeys = {
  FetchNotifications: ['FetchNotifications'],
  ReadNotifications: ['ReadNotifications'],
}

const useGetNotifications = (selectedTag?: string) => {
  return useQuery(NotificationApiQueryKeys.FetchNotifications, () => getNotifications(selectedTag))
}

const useReadNotifications = () => {
  return useMutation(NotificationApiQueryKeys.ReadNotifications, (notification_ids: string[]) =>
    putReadNotifications(notification_ids)
  )
}

export { useGetNotifications, useReadNotifications }
