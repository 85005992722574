import { Reducer } from 'redux'
import { ActionTypes, ROUTE_ACTIONS, User, Notification } from './types'
import { PATHS } from '~/app/routes/paths'

const initialState = {
  currentRedirect: null,
  user: {
    kcId: undefined,
    fullName: '',
    email: '',
    mobileNumber: '',
    countryCode: '',
    displayname: '',
    avatarUrl: '',
    authServerUrl: '',
    bannerDismissalState: {},
    twoFa: {
      isOnCoolingDownPeriod: false,
      twoFaSetupTime: '',
      twoFaList: [],
    },
    isBitgoAddressAcknowledged: undefined,
  },
  notifications: {
    data: [],
    unreadCount: 0,
    isLoading: false,
  },
  isWelcomeModalOpened: null,
  isTypeformSurveyVisible: false,
  isTypeformSurveyRequired: false,
  isLockedFeatureOn: null,
  isUserInfoInitialised: false,
  isUserInfoFromSsoInitialised: false,
  isUserHasPassword: false,
  isAccountEnabled: true,
}

type State = {
  currentRedirect: PATHS | null
  user: User
  notifications: {
    data: Notification[]
    unreadCount: number
    isLoading: boolean
  }
  isWelcomeModalOpened: boolean | null
  isTypeformSurveyVisible: boolean
  isTypeformSurveyRequired: boolean
  isLockedFeatureOn: boolean | null
  isUserInfoInitialised: boolean
  isUserInfoFromSsoInitialised: boolean
  isUserHasPassword: boolean
  isAccountEnabled: boolean
}

export const route: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_ACTIONS.SET_REDIRECT: {
      return {
        ...state,
        currentRedirect: action.to,
      }
    }
    case ROUTE_ACTIONS.SET_USER_PROFILE: {
      return {
        ...state,
        user: {
          ...action.user,
        },
        isUserInfoFromSsoInitialised: true,
      }
    }
    case ROUTE_ACTIONS.UPDATE_USER_PROFILE: {
      return {
        ...state,
        user: {
          ...state.user,
          avatarUrl: action.avatarUrl,
        },
      }
    }
    case ROUTE_ACTIONS.SET_NOTIFICATIONS: {
      const { data, unreadCount, isLoading } = action
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data,
          unreadCount,
          isLoading,
        },
      }
    }
    case ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: action.isLoading,
        },
      }
    }
    case ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED: {
      return {
        ...state,
        isWelcomeModalOpened: action.enabled,
      }
    }
    case ROUTE_ACTIONS.SET_IS_TYPEFORM_SURVEY_VISIBLE: {
      return {
        ...state,
        isTypeformSurveyVisible: action.isTypeformSurveyVisible,
      }
    }
    case ROUTE_ACTIONS.SET_IS_TYPEFORM_SURVEY_REQUIRED: {
      return {
        ...state,
        isTypeformSurveyRequired: action.isTypeformSurveyRequired,
      }
    }
    case ROUTE_ACTIONS.SET_IS_LOCKED_FEATURE_ON: {
      return {
        ...state,
        isLockedFeatureOn: action.isLockedFeatureOn,
      }
    }
    case ROUTE_ACTIONS.SET_IS_USER_INFO_INITIALISED: {
      return {
        ...state,
        isUserInfoInitialised: action.isUserInfoInitialised,
      }
    }
    case ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD: {
      return {
        ...state,
        isUserHasPassword: action.isUserHasPassword,
      }
    }
    case ROUTE_ACTIONS.SET_IS_ACCOUNT_ENABLED: {
      return {
        ...state,
        isAccountEnabled: action.isAccountEnabled,
      }
    }
    default:
      return state
  }
}
