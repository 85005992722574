import React, { useEffect, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { cx, css } from 'emotion'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { Col, Row } from 'antd'
import { DESKTOP_BREAKPOINT, Link, Hidden, Typography, straitsXTheme } from '@xfers/design-system'

import Breadcrumb from 'antd/es/breadcrumb'
import { createGaEvent } from '~/helpers/gaHelper'

import { PATHS as APP_PATHS } from '~/app/routes/paths'
import { PATHS as BIZ_PATHS } from '~/biz/routes/paths'

import { Route } from '~/types'
import { importStraitsXRoutes } from '~/helpers/dynamicImport'

type PathsOfEnum = APP_PATHS | BIZ_PATHS

function generateMapBy(routes: Route[], key: keyof Route) {
  const routeMap = new Map()
  routes.filter(route => route[key]).forEach(route => routeMap.set(route.path, route[key]))
  return routeMap
}

const StraitsXBreadcrumbBar = ({
  match: { path },
  children,
}: RouteComponentProps & { children: JSX.Element | null }) => {
  const history = useHistory()
  const [routes, setRoutes] = useState<Route[]>([])

  const breadcrumbMap = useMemo(() => generateMapBy(routes, 'breadcrumbName'), [routes])
  const pageTitleMap = useMemo(() => generateMapBy(routes, 'pageTitle'), [routes])

  useEffect(() => {
    setUpRoutes()
    async function setUpRoutes() {
      setRoutes(await importStraitsXRoutes())
    }
  }, [])

  const handleBreadcrumbClick = (url: PathsOfEnum) => {
    history.push(url)
    createGaEvent('General', `Click Breadcrumb Link to ${url}`, `Clicked Breadcrumb Link to ${url}`)
  }

  const urlPaths = path.split('/').filter((i: string) => Boolean(i))

  const rootBreadcrumbItem = (
    <Breadcrumb.Item>
      <Link onClick={() => handleBreadcrumbClick('/' as PathsOfEnum)}>Home</Link>
    </Breadcrumb.Item>
  )

  const extraBreadcrumbItems = urlPaths.map((_path, index) => {
    const url: PathsOfEnum = `/${urlPaths.slice(0, index + 1).join('/')}` as PathsOfEnum
    const isLastUrlItem = index === urlPaths.length - 1
    const breadCrumbName = breadcrumbMap.get(url)

    if (breadCrumbName) {
      return (
        <Breadcrumb.Item key={url}>
          {isLastUrlItem ? (
            breadCrumbName
          ) : (
            <Link onClick={() => handleBreadcrumbClick(url)}>{breadCrumbName}</Link>
          )}
        </Breadcrumb.Item>
      )
    }
    return null
  })

  const pageTitle = pageTitleMap.get(path) || breadcrumbMap.get(path)

  const breadcrumbStyle = css`
    &.ant-breadcrumb a {
      color: ${straitsXTheme.color.base.primary} !important;
    }
  `
  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <Hidden only="sm">
            <Breadcrumb className={cx(breadcrumbStyle)}>
              {rootBreadcrumbItem}
              {extraBreadcrumbItems}
            </Breadcrumb>
          </Hidden>
          <Typography.Title size="md">{pageTitle}</Typography.Title>
        </Col>
        <Col>{children}</Col>
      </Row>
    </Container>
  )
}

export default withRouter(StraitsXBreadcrumbBar)

const Container = styled.div`
  & .ant-breadcrumb-separator {
    margin-left: 4px;
    margin-right: 4px;
  }

  & .ant-breadcrumb > span:last-child > .ant-breadcrumb-separator {
    display: none;
  }

  padding: 20px 16px 0;

  ${DESKTOP_BREAKPOINT} {
    padding: 20px 50px 0;
  }
`
