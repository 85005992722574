import { ENDPOINTS, get } from '~/api'
import { IkycVerificationOptions } from '~/app/store/verification/types'

type Props =  {
  successCallback?: (response: IkycVerificationOptions) => void
}

const VerificationEndpoints = {
  InternationalKycVerificationOptions: ENDPOINTS.API_V3_STABLECOIN_IKYC_VERIFICATION_OPTIONS,
}

const getCountryList = ({ successCallback }: Props) => {
  const queryStringParams = {
    options: ['countries'],
  }

  get(VerificationEndpoints.InternationalKycVerificationOptions, undefined, queryStringParams)
    .then((response: IkycVerificationOptions) => {
      if (successCallback) {
        successCallback(response)
      }
    })
}


export { getCountryList }