import { useState, useEffect } from 'react'
import { DESKTOP_BREAKPOINT } from '@xfers/design-system'

interface ResponsiveViews {
  isMobileView: boolean
}
export function useResponsive() {
  useEffect(() => {
    function handleResize() {
      setCurrentWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [currentWidth, setCurrentWidth] = useState<number | undefined>(undefined)

  const responsiveView: ResponsiveViews = {
    isMobileView: false,
  }

  const threeConsecutiveDigits = /\d{3}/
  const desktopBreakpointWidth = DESKTOP_BREAKPOINT.match(threeConsecutiveDigits)[0]

  if (!desktopBreakpointWidth || currentWidth === undefined) {
    return { ...responsiveView, isMobileView: false }
  }
  const isMobileView = currentWidth < desktopBreakpointWidth

  return { ...responsiveView, isMobileView }
}
