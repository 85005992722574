import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { css } from 'emotion'
import { DESKTOP_BREAKPOINT } from '@xfers/design-system'
import { StraitsxSandboxLogo, DarkStraitsXUserInfoBarLogo } from '@xfers/design-system/es/logos'
import HamburgerIcon from './Hamburger'
import { createGaEvent } from '~/helpers/gaHelper'
import { ELEVATIONS } from '~/common/elevation'
import MobileNavigation from './MobileNavigation'
import UserProfile from './UserProfile'
import Notifications from './Notifications'
import { RootState as AppRootState } from '~/app/store/types'
import { RootState as XIDRAppRootState } from '~/xidrApp/store/types'
import { RootState as XIDRBizRootState } from '~/xidrBiz/store/types'
import { ConfigManager, SITE_TYPE } from '~/managers/ConfigManager'
import { isMobileView } from '~/helpers/helpers'

type UserInfoBarType = {
  isSandbox: boolean
  userInfo: {
    fullName: string
    avatarUrl: string
    displayname: string
  }
  notifications?: {
    data: {
      id: number
      title: string
      message: string
      created_at: string
      is_read: number
      external_link: string
    }[]
    unreadCount: number
    isLoading: boolean
  }

  displayGetStartedTab: boolean
  displayMintTab?: boolean
  displayDevToolTab?: boolean
  displayEarnTab?: boolean
  displayAccountStatementTab?: boolean
  displayModeSwitcher?: boolean
  displayUserCardsTab?: boolean
  isBusinessAccount?: boolean
  onChangeModeCallback?: () => void
  onClickCallbacks?: { [k: string]: Function } | undefined
}

export default function({
  isSandbox,
  userInfo,
  notifications,
  displayGetStartedTab = true,
  displayMintTab = false,
  displayDevToolTab = false,
  displayEarnTab = false,
  displayAccountStatementTab = false,
  displayModeSwitcher = false,
  displayUserCardsTab = false,
  isBusinessAccount = false,
  onClickCallbacks,
  onChangeModeCallback,
}: UserInfoBarType) {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const tour = useSelector((state: AppRootState) => state.tour)
  const xidrAppTour = useSelector((state: XIDRAppRootState) => state.xidrTour)
  const xidrBizTour = useSelector((state: XIDRBizRootState) => state.xidrTour)
  const isMobile = useMemo(() => isMobileView(), [])

  useEffect(() => {
    if (!isMobile) {
      return
    }

    if (ConfigManager.isXSGDSite() && tour?.isTourRunning) {
      setIsMobileNavOpen(tour?.currentStepIndex === 3)
      return
    }

    if (ConfigManager.getSiteType() === SITE_TYPE.XIDR_APP && xidrAppTour?.isTourRunning) {
      setIsMobileNavOpen(xidrAppTour?.currentStepIndex === 3)
    }
  }, [tour, xidrAppTour, isMobile])

  useEffect(() => {
    if (
      xidrBizTour &&
      xidrBizTour.currentTarget &&
      ConfigManager.getSiteType() === SITE_TYPE.XIDR_BIZ
    ) {
      setIsMobileNavOpen(xidrBizTour.currentTarget === '.tour-target-homepage-nav-item')
    }
  }, [xidrBizTour])

  const whiteOrBlue = () => {
    return isSandbox ? 'white' : '#051513'
  }

  const whiteOrBlueStyle = css`
    & rect {
      fill: ${isSandbox ? '#051513' : 'white'};
    }
  `

  const logoHeight = isSandbox ? '50px' : '40px'

  return (
    <>
      <DesktopView>
        {notifications && <Notifications notifications={notifications} />}
        <UserProfile
          userInfo={userInfo}
          isSandbox={isSandbox}
          displayModeSwitcher={displayModeSwitcher}
          onChangeModeCallback={onChangeModeCallback}
        />
      </DesktopView>
      <MobileView style={{ background: whiteOrBlue() }}>
        <HamburgerButton
          onClick={() => {
            setIsMobileNavOpen(true)
            createGaEvent(
              'General',
              'clickMobileHamburgerLogoLink',
              'Clicked Mobile Hamburger Logo'
            )
          }}
        >
          <HamburgerIcon className={whiteOrBlueStyle} width={30} height={30} />
        </HamburgerButton>
        <MobileRightSection>
          {notifications && <Notifications notifications={notifications} />}
          <UserProfile
            userInfo={userInfo}
            isSandbox={isSandbox}
            displayModeSwitcher={displayModeSwitcher}
          />
        </MobileRightSection>
        <Link
          to="/"
          style={{
            position: 'absolute',
            top: '0px',
            left: '50%',
          }}
        >
          <MobileLogo logoHeight={logoHeight}>
            {isSandbox ? (
              <StraitsxSandboxLogo width={100} height={logoHeight} />
            ) : (
              <DarkStraitsXUserInfoBarLogo width={160} height={logoHeight} />
            )}
          </MobileLogo>
        </Link>
      </MobileView>
      <MobileNavigation
        isOpen={isMobileNavOpen}
        onClose={() => setIsMobileNavOpen(false)}
        displayGetStartedTab={displayGetStartedTab}
        displayMintTab={displayMintTab}
        displayEarnTab={displayEarnTab}
        displayAccountStatementTab={displayAccountStatementTab}
        displayDevToolTab={displayDevToolTab}
        displayUserCardsTab={displayUserCardsTab}
        isSandbox={isSandbox}
        isBusinessAccount={isBusinessAccount}
        onClickCallbacks={onClickCallbacks}
      />
    </>
  )
}

const DesktopView = styled.div`
  display: none;
  height: 73px;
  justify-content: flex-end;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1b2736;
  padding-right: 26px;

  ${DESKTOP_BREAKPOINT} {
    display: flex;
  }
`

const MobileView = styled.div`
  display: flex;
  height: 73px;
  border-bottom: 1px solid #e8e8e8;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  background: white;
  z-index: ${ELEVATIONS.FLOATING_NAVIGATION_BAR};

  ${DESKTOP_BREAKPOINT} {
    display: none;
  }
`

const MobileRightSection = styled.div`
  display: flex;
  padding-right: 6px;
`

const HamburgerButton = styled.div`
  width: 53px;
  height: 45px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const MobileLogo = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-60%);
  cursor: pointer;
  top: calc((73px - ${(props: { logoHeight: string }) => props.logoHeight}) / 2);
`
