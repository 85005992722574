// IMPORTANT: refer and update this before / after making any changes https://www.notion.so/fazzfinancialgroup/React-Error-Boundary-Implementation-19a5d9f672cf80a8a7e4cf4f4cd4ed73

import React from 'react'
import ErrorPage from '../ErrorPage'
import { datadogRum } from '@datadog/browser-rum'

interface Props {
  children: React.ReactNode
  fallback?: React.ReactNode
}

interface State {
  hasError: boolean
  error?: Error
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    console.error('Error:', error, info)

    datadogRum.addError(error, {
      message: error.message,
      info: info.componentStack,
      source: 'react-error-boundary',
    })
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return this.renderFallback()
    }

    return this.props.children
  }

  private renderFallback(): React.ReactNode {
    const { fallback } = this.props

    if (fallback) return fallback

    return <ErrorPage subtitle="Something went wrong." />
  }
}
