import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { DarkStraitsXLogo, StraitsxSandboxLogo } from '@xfers/design-system/es/logos'
import { createGaEvent } from '~/helpers/gaHelper'
import Menu from './Menu'

type props = {
  displayEarnTab?: boolean
  displayGetStartedTab: boolean
  displayMintTab?: boolean
  displayDevToolTab?: boolean
  isSandbox: boolean
  displayAccountStatementTab?: boolean
  displayUserCardsTab?: boolean
  isBusinessAccount?: boolean
  onClickCallbacks?: { [k: string]: Function } | undefined
}

export default function({
  displayEarnTab = false,
  displayGetStartedTab = false,
  displayMintTab = false,
  displayDevToolTab = false,
  displayAccountStatementTab = false,
  displayUserCardsTab = false,
  onClickCallbacks,
  isSandbox,
}: props) {
  return (
    <SideContainer style={{ backgroundColor: isSandbox ? '#FFFFFF' : '#051513' }}>
      <FixedContainer>
        <LogoPanel isSandbox={isSandbox}>
          <Link
            to="/"
            onClick={() => createGaEvent('General', 'clickXfersLogoLink', 'Clicked Xfers Logo')}
          >
            {isSandbox ? (
              <StraitsxSandboxLogo width={'160px'} height={'80px'} />
            ) : (
              <DarkStraitsXLogo />
            )}
          </Link>
        </LogoPanel>
        <Menu
          displayEarnTab={displayEarnTab}
          displayGetStartedTab={displayGetStartedTab}
          displayMintTab={displayMintTab}
          displayDevToolTab={displayDevToolTab}
          displayAccountStatementTab={displayAccountStatementTab}
          displayUserCardsTab={displayUserCardsTab}
          onClickCallbacks={onClickCallbacks}
          isSandbox={isSandbox}
        />
      </FixedContainer>
    </SideContainer>
  )
}

const SideContainer = styled.div`
  min-height: 100vh;
  width: 257px;
  flex: 1;
  flex-shrink: 0;
  display: block;

  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 257px;
`

const FixedContainer = styled.div`
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 257px;
  border-right: 1px solid #e8e8e8;
  overflow: auto;
`

const LogoPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: ${(props: { isSandbox: boolean }) => (props.isSandbox ? '70px' : '100px')};
`
